import React, { useEffect, useState } from 'react';
import { HiArrowLongRight } from "react-icons/hi2";
import { Animate } from 'react-simple-animate';
import ElegantText from '../../../atoms/ElegantText';
import { GoNorthStar } from "react-icons/go";
import { Text } from '@chakra-ui/react';

const StepZero = ({ setStep }) => {
  const [playForward, setPlayForward] = useState(true);
  const duration = 0.8;
  useEffect(() => {
    const interval = setInterval(() => {
      setPlayForward(prev => !prev);
    }, duration * 1000); // 300ms matches the duration of the animation
    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, []);


  return (
    <div className="message">
      <GoNorthStar size={"80px"} id="star" color={"#FFB700"} />
      <ElegantText>
        <Text fontWeight={700} className='message_highlight'>Let's first build your profile</Text>
      </ElegantText>
      <div
        id="next_action"
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={() => setStep(1)}
      >
        <p>next</p>
        <Animate
          play={true}
          start={{ transform: playForward ? 'translateX(0px)' : 'translateX(10px)' }}
          end={{ transform: playForward ? 'translateX(10px)' : 'translateX(0px)' }}
          duration={duration}
          easeType="ease-in-out" // Smooth transition
        >
          <HiArrowLongRight size={"25px"} />
        </Animate>
      </div>
    </div>
  )
}

export default StepZero