import React, { useEffect, useState } from 'react';
import { FormControl, Textarea, VStack, useDisclosure, useToast } from '@chakra-ui/react';
import EditModal from '../../atoms/EditModal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ErrorBox from '../../atoms/ErrorBox';
import { toastDefaultConfig } from '../../utils/constants';
import { login } from '../../redux/slices/userSlice';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase.config';
import { toggleBio } from '../../redux/slices/sectionEditSlice';

const BioCardEditable = ({ extUser }) => {
  const dispatch = useDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [IsSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const { user: currentUser } = useSelector(state => state.user);
  const [user, setUser] = useState();
  useEffect(() => {
    if (extUser) {
      setUser(extUser);
    } else {
      setUser(currentUser);
    }
  }, [extUser, currentUser])

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      bio: user?.bio,
    },
  });

  useEffect(() => {
    if (user?.bio) {
      setValue('bio', user.bio);
    }
  }, [user?.bio])


  const { bio: bioState } = useSelector((state) => state.sectionEdit);
  useEffect(() => {
    if (bioState) {
      onOpen();
    } else {
      handleClose();
    }
  }, [bioState])

  const handleClose = () => {
    onClose();
    if (bioState) {
      dispatch(toggleBio());
    }
  }

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {

      if (!user) {
        console.error("User is not authenticated.");
        return;
      }

      // Update the user's document in Firestore with the new data
      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, data);

      // Dispatch the updated user data to the store
      dispatch(login({ ...user, ...data }));
      handleClose(); // Close the modal or form after submission
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      toast({
        title: `Something went wrong! Please try again.`,
        status: 'error',
        isClosable: false,
        ...toastDefaultConfig,
      });
    }
  }

  return (
    <>
      {isOpen && <EditModal
        isOpen={isOpen} onOpen={onOpen} onClose={handleClose}
        headerText={'Bio'}
        saveFn={handleSubmit(onSubmit)}
        IsSubmitting={IsSubmitting}
      >
        <VStack className='modal_form' p={"20px"} spacing={4} overflow={'auto'} height={'auto'}>
          <FormControl isInvalid={errors.bio} display={'flex'} columnGap={10} flexDirection={'column'} alignItems={'start'}>
            <Textarea
              type="textarea"
              fontSize={'sm'}
              rows={8}
              placeholder="Add a little about all the awesome work you do... You can format your text using HTML tags. Try using:
              <b>Bold</b>
              <i>Italic</i>
              <ul><li>Lists</li></ul>
              <a href='#'>Links</a>"
              {...register('bio', { required: 'Bio is required', maxLength: { value: 400, message: '400 characters allowed' }, minLength: { value: 60, message: 'Minimum 60 characters required' } })}
            />
          </FormControl>
          {errors?.bio?.message ?
            <ErrorBox errorText={errors?.bio?.message} /> : null
          }
        </VStack>
      </EditModal>}
    </>
  )
}

export default BioCardEditable;