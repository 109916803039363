import { Box, Button, ButtonGroup, FormControl, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Textarea, VStack, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { SiReaddotcv } from 'react-icons/si';
import EditModal from '../../atoms/EditModal';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { TfiPrinter } from "react-icons/tfi";
import { model } from '../../firebase.config';
import { RiSwap2Line } from "react-icons/ri";

const QuickMenu = () => {
  const { user } = useSelector(state => state.user);
  const [PreparingDownload, setPreparingDownload] = useState(false);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [GeneratedFiles, setGeneratedFiles] = useState(null);
  const [CV1orCover2, setCV1orCover2] = useState(1);
  const { register, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      prompt: null,
    },
  });

  const generatePDF = (contentId) => {
    let filename = CV1orCover2 === 1 ? 'Resume' : 'Cover_Letter';
    const doc = new jsPDF({
      orientation: CV1orCover2 === 1 ? 'portrait' : 'landscape', // Use portrait mode
      unit: 'px',               // Pixels for consistent measurements
      format: CV1orCover2 === 1 ? 'a4' : 'a5',             // A4 size (595x842 px approx)
    });

    const margins = {
      top: 10,      // Top margin in pixels
      bottom: 10,   // Bottom margin
      left: 10,     // Left margin
      right: 10     // Right margin
    };

    const content = document.getElementById(contentId);

    // Adjust the HTML content to scale and fit within the PDF width
    doc.html(content, {
      x: margins.left,       // Horizontal margins
      y: margins.top,        // Vertical margins
      margin: [margins.top, margins.left, margins.bottom, margins.right],
      html2canvas: {
        scale: 0.35,         // Increase scale for better resolution and fit within the page
      },
      callback: function (doc) {
        doc.save(`${user.fullname}_${user.role}_${filename}.pdf`);  // Save the generated PDF
      }
    });
  };

  const onSubmit = async (data) => {
    setPreparingDownload(true);

    // Send a proper prompt to the model for JSON response
    const prompt = `
      Please generate a JSON output with two keys: "resume" and "coverLetter" that is compliant to Applicant tracking system (ATS).
      - "cv" should contain the generated resume based on the user's profile: ${JSON.stringify(user)}.
      - "coverLetter" should contain the generated cover letter based on the user's profile and the following job description: ${data.prompt}.
      
      Generate a valid JSON object while ensuring correct syntax. Make sure there are no missing commas, unmatched braces, or trailing commas.
      ENSURE exact JSON structure as below:
      {
        "cv": "Generate a professional and market-standard resume for the user's profile, ensuring it is optimized for the given role. The resume content should be well-structured, concise. Prioritize key skills, experiences, and accomplishments that are most relevant to the job, while maximizing the chances of getting shortlisted. Ensure the resume is free from any errors, bulletized, emphasized, hightlighted and emboldened where needed such as in headings must be emboldened etc. Follow best practices for ATS (Applicant Tracking System) compatibility, and presents the user's qualifications in a manner that appeals to hiring managers. The output should be clean, easy to read, and fully styled using HTML only, DO NOT ADD CSS. Do not include any pictures. Keep the structure minimalistic. For lists view with items less then 4 words, use comma separated list. For lists with items more than 5 words, use bullet points.",
        "coverLetter": "Generate a concise, well-crafted cover letter that follows best practices for the given role. The content should highlight the user's most relevant skills, experience, and motivation for applying, while directly aligning with the job requirements. Ensure the letter is brief, to the point, and of optimal length—not too long—while maintaining a professional and engaging tone. Avoid generic phrases and ensure it conveys enthusiasm for both the role and the company. The letter must be fully formatted properly in HTML."
      }
    `;

    // Await the model's response (ensure model generates a valid JSON response)
    const result = await model.generateContent(prompt).finally(() => {
      setPreparingDownload(false);
    });

    // Try parsing the result as JSON
    console.log(result.response.text());
    let parsedResult;
    parsedResult = JSON.parse(result.response.text().replaceAll('`', '').replace('json', ''));

    setGeneratedFiles(parsedResult);
    onClose();
  };

  return (
    <>
      <Box position="fixed" right="-10px" bottom={"20px"} p="10px 20px 10px 10px" rounded={"xl"} zIndex={999} shadow={"2xl"} backgroundColor={"#000000"} onClick={onOpen}>
        <SiReaddotcv size="20px" color="#fff" style={{ cursor: "pointer" }} />
      </Box>
      <EditModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={() => {
          onClose();
          setGeneratedFiles(null);
        }}
        maxWidth='calc(100vw - 40px)'
        headerText={'Generate ATS compliant resume and cover letter'}
        saveFn={handleSubmit(onSubmit)}
        IsSubmitting={PreparingDownload}
        submitText={"Generate"}
      >
        <VStack className='modal_form' p={"20px"} spacing={4} overflow={'auto'} w="100%" 
        height={"calc(100dvh - 155px)"}>
          <Text textAlign={"center"}>Enter the job description to generate an ATS-compliant resume and cover letter tailored to highlight your most relevant skills, helping you stand out to recruiters.</Text>
          <FormControl isInvalid={errors.prompt} display={'flex'} gap="5px" flexDirection={"column"} justifyContent={'center'}>
            <Textarea
              background={"#fff !important"}
              rounded={"xl"}
              resize="none"
              height={errors?.prompt?.message ? "calc(100vh - 350px)" : "calc(100vh - 380px)"}
              fontSize={'sm'}
              {...register('prompt')}
            />
          </FormControl>
        </VStack>
      </EditModal>
      <Modal
        isOpen={GeneratedFiles}
        onClose={() => setGeneratedFiles(null)}
        blockScrollOnMount={true}
      >
        <ModalOverlay />
        <ModalContent className="app-scroll" m={2} height={"calc(100dvh - 15px)"} width="100%" maxWidth={"100%"}>
          <ModalHeader textAlign={"center"} position="relative" fontSize={'sm'} fontWeight={'500'} borderBottom={"1px solid #efefef"}>
            <ButtonGroup position="absolute"
              left={"30px"}
              top="10px">
              <Button
                size="sm"
                variant="ghost"
                onClick={() => setCV1orCover2(CV1orCover2 === 1 ? 2 : 1)}
                background={"#edf2f7"}
              >
                <RiSwap2Line size="20px" />
              </Button>

              <Button
                size="sm"
                variant="ghost"
                onClick={() => generatePDF('printDiv')}  // Call the PDF generation function here
                background={"#edf2f7"}
              >
                <TfiPrinter size="20px" />
              </Button>
            </ButtonGroup>

            {`${CV1orCover2 === 1 ? "Resume" : "Cover Letter"}`}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody width="calc(100% - 10px)" height="calc(100% - 10px)" overflowY={"auto"} margin={"10px !important"}>
            {CV1orCover2 === 1 ? <Box id="printDiv">
              <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>{GeneratedFiles?.cv}</Markdown>
            </Box>
              : <Box id="printDiv">
                <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>{GeneratedFiles?.coverLetter}</Markdown>
              </Box>}
          </ModalBody>
        </ModalContent>
      </Modal >
    </>
  );
};

export default QuickMenu;
