import React, { useState, useEffect, useRef } from 'react';
import { Box, Image, Input, IconButton, Wrap, WrapItem } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { IoIosClose } from "react-icons/io";

const ImageUploader = ({ control, setValue, EditImages }) => {
  const [imageFiles, setImageFiles] = useState([]);
  const inputRef = useRef(null); // Reference for the file input

  const handleImageUpload = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      const newImageFiles = [...imageFiles, ...filesArray];
      setImageFiles(newImageFiles);
      setValue('images', newImageFiles);

      // Clear the input field to allow re-uploading the same files if needed
      e.target.value = '';
    }
  };

  useEffect(() => {
    if (EditImages) {
      setImageFiles(EditImages.images);
      setValue('images', EditImages.images);
    }
  }, [EditImages]);

  const handleRemoveImage = (index) => {
    const newImageFiles = imageFiles.filter((_, i) => i !== index);
    setImageFiles(newImageFiles);
    setValue('images', newImageFiles);

    // Reset input field if all images are removed
    if (newImageFiles.length === 0 && inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <Box>
      <Controller
        name="images"
        control={control}
        defaultValue={[]}
        render={() => (
          <>
            <Input
              ref={inputRef}
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageUpload}
              mb={4}
              p={"5px !important"}
            />
            <Wrap spacing={4}>
              {imageFiles.map((file, index) => (
                < WrapItem key={index} >
                  <Box position="relative" w="150px" h="150px" border="1px solid #ddd" borderRadius="md">
                    <Image
                      src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                      alt={`image-${index}`}
                      objectFit="cover"
                      width="100%"
                      height="100%"
                      borderRadius="md"
                    />
                    <IconButton
                      icon={<IoIosClose color="#fff" />}
                      size="xs"
                      position="absolute"
                      top="2px"
                      background={"#000"}
                      _hover={{ background: "#8693a6" }}
                      right="2px"
                      onClick={() => handleRemoveImage(index)}
                      aria-label="Remove image"
                    />
                  </Box>
                </WrapItem>
              ))}
            </Wrap>
          </>
        )}
      />
    </Box>
  );
};

export default ImageUploader;
