import React, { useEffect, useState, useCallback } from 'react';
import {
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toastDefaultConfig } from '../../../utils/constants';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase.config';
import { login } from '../../../redux/slices/userSlice';
import { toggleSkills } from '../../../redux/slices/sectionEditSlice';

const useEditCoreSkills = ({ extUser }) => {
  const { user: currentUser } = useSelector(state => state.user);
  const [user, setUser] = useState();
  useEffect(() => {
    if (extUser) {
      setUser(extUser);
    } else {
      setUser(currentUser);
    }
  }, [extUser, currentUser])
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [skills, setSkills] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();
  useEffect(() => {
    if (user?.core_skills) {
      setSkills([...user?.core_skills, '']);
    }
  }, [user])

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      core_skills: user?.core_skills,
    },
  });

  const { core_skills } = useSelector((state) => state.sectionEdit);

  useEffect(() => {
    if (core_skills) {
      onOpen();
    } else {
      handleClose();
    }
  }, [core_skills])

  const handleClose = () => {
    onClose();
    if (core_skills) {
      dispatch(toggleSkills());
    }
  }

  useEffect(() => {
    let numOfEmptySkills = skills?.filter((item) => item === '')?.length;
    if (numOfEmptySkills > 1) {
      let firstIndexOf = skills?.findIndex((item) => item === '');
      setSkills([...skills?.slice(0, firstIndexOf), ...skills?.slice(firstIndexOf + 1)]);
    }
  }, [skills])

  const handleSkillChange = useCallback((index, value) => {
    setSkills(prevSkills => {
      const updatedSkills = [...prevSkills];
      if (updatedSkills[index] !== value) { // Only update if value actually changed
        updatedSkills[index] = value;
        if (value && index === prevSkills?.length - 1) {
          updatedSkills?.push('');
        }
      }
      return updatedSkills;
    });
  }, []);

  useEffect(() => {
    setSkills(prevSkills => {
      const filteredSkills = prevSkills?.filter((skill, index) => skill !== '' || index === prevSkills?.length - 1);
      return filteredSkills?.length === 0 ? [''] : filteredSkills;
    });
  }, []);

  const onDragEnd = useCallback((result) => {
    if (!result.destination) return;

    setSkills(prevSkills => {
      const reorderedSkills = Array.from(prevSkills);
      const [movedSkill] = reorderedSkills?.splice(result.source.index, 1);
      reorderedSkills?.splice(result.destination.index, 0, movedSkill);

      return reorderedSkills;
    });
  }, []);

  const handleInputChange = useCallback((index) => (e) => {
    handleSkillChange(index, e.target.value);
  }, [handleSkillChange]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    try {
      if (!user) {
        console.error("User is not authenticated.");
        return;
      }

      const newData = {
        ...data,
        core_skills: skills?.slice(0, skills?.length - 1), // Exclude the last empty skill
      };

      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, newData);
      dispatch(login({ ...user, ...newData }));
      handleClose();
    } catch (error) {
      console.error(error);
      toast({
        title: `Something went wrong! Please try again.`,
        status: 'error',
        isClosable: false,
        ...toastDefaultConfig,
      });
    } finally {
      setIsSubmitting(false);
    }
  };


  return {
    user,
    isOpen,
    onOpen,
    onClose,
    isSubmitting,
    skills,
    onDragEnd,
    handleInputChange,
    onSubmit,
    handleClose,
    register,
    errors,
    handleSubmit,
  }
};

export default useEditCoreSkills;
