import React, { useState, useEffect } from 'react'
import ElegantText from '../../../atoms/ElegantText'
import { Box, Input, Text, useToast, VStack } from '@chakra-ui/react';
import "./step.scss";
import { Animate } from 'react-simple-animate';
import { HiArrowLongRight } from "react-icons/hi2";
import { useForm } from 'react-hook-form';
import { capitalizeAndTrim } from '../../../helper/random.helper';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase.config';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../redux/slices/userSlice';
import { toastDefaultConfig } from '../../../utils/constants';
import Line from '../../../assets/Line';


const StepOne = ({ setStep }) => {
  const { user } = useSelector(state => state.user);
  const toast = useToast();
  const { formState: { errors }, control, register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const [playForward, setPlayForward] = useState(true);
  const duration = 0.8;
  useEffect(() => {
    const interval = setInterval(() => {
      setPlayForward(prev => !prev);
    }, duration * 1000); // 300ms matches the duration of the animation
    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, []);

  const handleSubmitData = async (data) => {
    const fullName = capitalizeAndTrim(data.fullname);
    const jobRole = capitalizeAndTrim(data.job_role);

    try {
      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, {
        fullname: fullName?.toLowerCase(),
        role: jobRole,
        search_role: jobRole?.toLowerCase()
      });

      dispatch(login({
        ...user,
        fullname: fullName?.toLowerCase(),
        role: jobRole,
        search_role: jobRole?.toLowerCase()
      }))

      setStep(prevStep => prevStep + 1);
    } catch (error) {
      toast({
        title: `Something went wrong! Please try again.`,
        status: 'error',
        isClosable: false,
        ...toastDefaultConfig
      })
    }
  };


  return (
    <div className="message">
      <ElegantText>
        {errors?.fullname || errors?.job_role ?
          <p className='error'>{errors.fullname?.message || errors.job_role?.message}</p> :
          <></>}
        <div className="line">
          <p className='message_highlight'>I am</p>
          <div>
            <Input className="input_dot" type="text" placeholder='full name'
              {...register('fullname', {
                required: 'Name is required',
              })} />
          </div>
        </div>
        <div className="line">
          <p className='message_highlight'>I work as a</p>
          <div>
            <VStack spacing={0} position="relative">
              <Input className="input_dot" type="text" placeholder='job role'
                {...register('job_role', {
                  required: 'Role is required',
                })} />
              <Text position="absolute" bottom={{ base: "-20px", md: "-25px" }} left="0px" fontSize={{ base: "xs", md: "sm" }}>(Add your desired role if you're a student)</Text>
            </VStack>

          </div>
        </div>

      </ElegantText>
      <div
        id="next_action"
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={handleSubmit((data) => handleSubmitData(data))}
      >
        <p>next</p>
        <Animate
          play={true}
          start={{ transform: playForward ? 'translateX(0px)' : 'translateX(10px)' }}
          end={{ transform: playForward ? 'translateX(10px)' : 'translateX(0px)' }}
          duration={duration}
          easeType="ease-in-out" // Smooth transition
        >
          <HiArrowLongRight size={"25px"} />
        </Animate>
      </div>
    </div>
  )
}

export default StepOne;