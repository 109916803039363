import React, { useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { PiPencilCircleDuotone } from 'react-icons/pi';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { toggleBio } from '../../../../redux/slices/sectionEditSlice';
import BioCardEditable from '../../../EditSections/BioEditable';

const BioCard = ({ extUser }) => {
  const dispatch = useDispatch();

  const { user: currentUser } = useSelector(state => state.user);
  const [user, setUser] = useState();

  useEffect(() => {
    if (extUser) {
      setUser(extUser);
    } else {
      setUser(currentUser);
    }
  }, [extUser, currentUser])

  if (!user?.bio) {
    return null;
  }

  return (
    <>
      <Box position={"relative"}
        display={"flex"} flexDirection={"column"} justifyContent={"center"} gap={"10px"}>
        {!extUser && <Box cursor={'pointer'} position={'absolute'} top={"0px"} left={"10px"} onClick={() => dispatch(toggleBio())}>
          <PiPencilCircleDuotone size="18px" />
        </Box>}
        <Box p={'20px'} display={'flex'} flexDirection={{ base: 'column', md: 'row' }} alignItems={"flex-start"} gap={{ base: '30px', md: '30px' }} >
          {/* <Text lineHeight={0.8} className="font_cursive" fontSize={"4xl"} fontWeight={'500'}>About.</Text> */}
          <Text className='shadow_text' fontSize={{ base: 'lg', sm: 'lg', md: '2xl', }} >
            <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
              {user?.bio}
            </Markdown>
          </Text>
        </Box>
      </Box >
      <BioCardEditable extUser={extUser} />
    </>
  )
}

export default BioCard