import React, { useEffect, useState } from 'react';
import './styles.scss';
import LandingForm from './LandingForm';
import Footer from '../../components/Footer/Footer';
import { useSearchParams } from 'react-router-dom';
import { Box, Image } from '@chakra-ui/react';
import { PiScribbleLoopBold } from 'react-icons/pi';
import { TbScribble } from 'react-icons/tb';
import { CgShapeZigzag } from 'react-icons/cg';


const Landing = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const [searchParams] = useSearchParams();
  const signUp = searchParams.get('sign_up');
  const [FormState, toggleFormState] = useState(!signUp);
  const [isSlidingOut, setIsSlidingOut] = useState(false);


  const handleUnmount = () => {
    setIsSlidingOut(true);
    setTimeout(() => {
      setIsSlidingOut(false);
      toggleFormState((prev) => !prev)
    }, 500); // Match the duration of the slideOut animation
  };

  useEffect(() => {
    const signupEl = document.getElementById('Sign up');
    const loginEl = document.getElementById('Login');
    if (FormState) { // true for login
      signupEl.style.display = "none";
      loginEl.style.display = "flex";
    } else { // false for singup
      signupEl.style.display = "flex";
      loginEl.style.display = "none";
    }
  }, [FormState])


  return (
    <div className='Landing_container'>
      <Box position={"absolute"} top={{ base: 5 }} right={{ base: 5, sm: 20, md: 30, lg: 50, xl: 80 }} zIndex={0}>
        <TbScribble size={"200px"} color={"#FFA700"} />
      </Box>
      <Box position={"absolute"} bottom={{ base: -5 }} left={{ base: 5, sm: 20, md: 30, lg: 50, xl: 80 }} zIndex={0}>
        <CgShapeZigzag size={"200px"} color={"#3DC074"} />
      </Box>
      <div className='landing_wrapper'>
        <LandingForm
          handleUnmount={handleUnmount}
          formType="Login"
          isSlidingOut={isSlidingOut}
        />
        <LandingForm
          handleUnmount={handleUnmount}
          formType="Sign up"
          isSlidingOut={isSlidingOut}
        />
        <div id="footer-absolute">
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Landing;