import { Avatar, Text, IconButton, Flex, useDisclosure, Badge, HStack, Image, Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PiPencilCircleDuotone } from "react-icons/pi";
import UserCardEditable from '../../../EditSections/UserCardEditable';
import { titleCase } from '../../../../helper/random.helper';
import { userstatus } from '../../../../utils/constants';
import BioCard from '../Bio/BioCard';
import UserInfo from './UserInfo';

const UserCard = ({ extUser }) => {
  const { user: currentUser } = useSelector(state => state.user);
  const [user, setUser] = useState();
  useEffect(() => {
    if (extUser) {
      setUser(extUser);
    } else {
      setUser(currentUser);
    }
  }, [extUser, currentUser]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    user?.username && (
      <>
        <Flex direction="column" gap={3}>
          <HStack
            position={"relative"}
            borderBottom="2px solid"
            borderColor={user?.theme?.[1]}
            spacing={3} justifyContent="center">
            <Text className="font_cursive" lineHeight={1} fontSize={{ base: "5xl", sm: "6xl", md: "7xl", lg: '8xl' }} position={"absolute"} bottom={"10px"} textAlign={"center"} fontWeight="500" color={user?.theme?.[1]} wordBreak={"break-word"} zIndex={99}>
              {titleCase(user?.fullname)}.
            </Text>
            <Flex
              minWidth={"150px"}
              p={3}
              mt={user?.DPUrl ? "20px" : "100px"}
              py={"20px"}
              height={"min-content"}
              position="relative" direction="column" alignItems="center" gap={3}>
              {!extUser && (
                <IconButton
                  icon={<PiPencilCircleDuotone size={"18px"} />}
                  position="absolute"
                  right={user?.DPUrl ? 0 : -5}
                  top={user?.DPUrl ? "-10px" : "-100px"}
                  zIndex={99}
                  onClick={onOpen}
                  aria-label="Edit User"
                  size="sm"
                  variant="ghost"
                />
              )}
              {user?.DPUrl ?
                <Image
                  name={titleCase(user?.fullname)}
                  src={user?.DPUrl}
                  background={'transparent'}
                  height="200px"
                  objectFit={"cover"}
                  width={"200px"}
                  mb={8}
                  objectPosition={"center top"}
                /> : null}

              <Badge
                px={3}
                py={1}
                fontSize="xs"
                color={user?.theme[1]}
                position="absolute"
                background={"transparent"}
                top={user?.DPUrl ? "-10px" : "-100px"}
              >
                {user?.availability}
              </Badge>
            </Flex>
          </HStack>
          <Flex direction={{ base: 'column', md: 'row' }} justifyContent="center" gap={3}>
            <BioCard extUser={extUser} />
          </Flex>
          <UserCardEditable isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
          <UserInfo user={user} display={"flex"} />
        </Flex >
      </>
    )
  );
};

export default UserCard;
