import React from 'react'
import { GoDotFill } from "react-icons/go";
import { HStack, VStack, Text, Box } from '@chakra-ui/react';


const Timeline = ({ date, color = 'brand.black' }) => {

  return (
    date?.split('-')?.length > 1 ?
      <HStack mb="4" mt="0" width={"100%"} position={"relative"} gap={0}>
        <VStack>
          <GoDotFill size={16} color={color}/>
          <Text top={4} color={color} fontSize={"xs"} left={0} position="absolute">{date?.split('-')[0]}</Text>
        </VStack>
        <hr
          style={{
            border: "1px solid",
            borderColor: color,
          }}
          width={"100%"} />
        <VStack>
          <GoDotFill size={16} color={color} />
          <Text top={4} color={color} fontSize={"xs"} right={0} position="absolute">{date?.split('-')[1]}</Text>
        </VStack>
      </HStack>
      :
      <HStack mb="0" mt="0" width={"100%"} gap={0}>
        <Box>
          <GoDotFill size={16} color={color}/>
        </Box>
        <hr
          style={{
            border: "1px solid",
            borderColor: color,
          }}
          width={"100%"} />
        <Text color={color} fontSize={"xs"} ml={1}>{date?.split('-')[0]}</Text>
      </HStack>
  )
}

export default Timeline