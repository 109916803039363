import React, { useEffect, useState, useCallback, memo } from 'react';
import {
  Box,
  Tag,
  TagLabel,
  Wrap,
  WrapItem,
  Text,
  useDisclosure,
  useToast,
  FormControl,
  VStack,
  Input,
  IconButton,
  HStack,
  TagLeftIcon
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { PiPencilCircleDuotone, PiPlusCircleDuotone } from 'react-icons/pi';
import { useForm } from 'react-hook-form';
import EditModal from '../../../../atoms/EditModal';
import { toastDefaultConfig } from '../../../../utils/constants';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase.config';
import { login } from '../../../../redux/slices/userSlice';
import ErrorBox from '../../../../atoms/ErrorBox';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MdDragIndicator } from 'react-icons/md';
import { toggleTools } from '../../../../redux/slices/sectionEditSlice';
import SoftSkillsEdit from '../../../EditSections/editSoftSkills/SoftSkillsEdit';
import useEditSoftSkills from '../../../EditSections/editSoftSkills/useEditSoftSkills';

const Tools = ({ extUser }) => {
  const editSoftSkills = useEditSoftSkills({ extUser });

  const {
    user,
    onOpen,
  } = editSoftSkills;

  return (
    <>
      {user?.soft_skills?.length ? <Box
        width={{ base: '100%', md: '300px' }}
        p={'0px'}
        pl={{ base: 0, md: 3 }}
        position={'relative'}
        height={'fit-content'}
      >
        <HStack justify={"space-between"}>
          <Text fontSize={"xl"} fontWeight={'500'}>Soft Skills</Text>
          {!extUser && <Box cursor={'pointer'} onClick={onOpen}>
            <PiPlusCircleDuotone size="18px" />
          </Box>}
        </HStack>
        <Wrap spacing={2} mt={'10px'}>
          {user?.soft_skills?.map((item, index) => (
            <WrapItem key={index}>
              <Tag
                size="md"
                borderRadius="full"
                px={3}
                py={1}
                fontWeight={400}
                backgroundColor="transparent"
                border="2px solid"
                borderColor="brand.black"
              >
                <TagLabel fontSize="sm" fontWeight={500}>{item}</TagLabel>
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
      </Box> : null}
      <SoftSkillsEdit {...editSoftSkills} />
    </>
  );
};

export default memo(Tools); // Memoize the SkillTags component to prevent unnecessary re-renders
