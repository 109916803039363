import { Box } from '@chakra-ui/react';
import React from 'react';
import Header from '../components/Header/Header';
import './styles.scss'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const { user } = useSelector(state => state.user);

  if (!user.email) {
    return <Navigate to="/" />
  }
  return (
    <Box className="private_wrapper">
      <Header type="private" />
      <Box className='private_child'>
        {children}
      </Box>
    </Box>
  )
}

export default PrivateRoute;