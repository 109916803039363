import React from 'react';
import { Box, Heading, Text, Container } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import FooterBig from '../../components/Footer/FooterBig';


export const Information = () => {

  // scroll to top on page load
  window.scrollTo(0, 0);
  const { infotype } = useParams();

  if (infotype === 'terms') {
    return (
      <>
        <Header type="public" />
        <Container maxW="container.md" mt={20} py={10}>
          <Box mb={6}>
            <Heading as="h1" size="lg" mb={4}>Terms and Conditions for urlme.site</Heading>
            <Text fontWeight="bold" mb={2}>1. Introduction</Text>
            <Text mb={4}>Welcome to urlme.site ("we", "our", "us"). By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.</Text>
            <Text fontWeight="bold" mb={2}>2. Use of Service</Text>
            <Text mb={4}>urlme.site provides a platform where users can create a portfolio website by uploading their resume or adding data manually. Users can also select from different portfolio templates. This service is for personal use only, and by using this service, you agree not to misuse the site or any part of it.</Text>
            <Text fontWeight="bold" mb={2}>3. User Responsibilities</Text>
            <Text mb={4}>- You are responsible for maintaining the confidentiality of your account and password.<br />- You agree not to use the service for any illegal or unauthorized purpose.<br />- The information you provide, including your resume, should be accurate, lawful, and should not infringe on any third-party rights.</Text>
            <Text fontWeight="bold" mb={2}>4. Intellectual Property</Text>
            <Text mb={4}>All templates, content, and assets provided by urlme.site are the property of urlme.site and are protected by copyright and other intellectual property laws. You may not reproduce, modify, or distribute any of these assets without express permission.</Text>
            <Text fontWeight="bold" mb={2}>5. Termination</Text>
            <Text mb={4}>We reserve the right to suspend or terminate your account at our sole discretion if we determine that you have violated these terms or engaged in inappropriate conduct.</Text>
            <Text fontWeight="bold" mb={2}>6. Limitation of Liability</Text>
            <Text mb={4}>urlme.site is not liable for any direct, indirect, or consequential damages arising from your use of the site. You use the service at your own risk.</Text>
            <Text fontWeight="bold" mb={2}>7. Changes to Terms</Text>
            <Text mb={4}>We may update these terms from time to time. It is your responsibility to review the terms regularly to stay informed of any changes.</Text>
            <Text fontWeight="bold" mb={2}>8. Contact</Text>
            <Text>For any questions about these Terms and Conditions, please contact us at support@urlme.site.</Text>
          </Box>
        </Container>
        <FooterBig />

      </>
    );
  }

  if (infotype === 'privacy') {
    return (
      <>
        <Header type="public" />
        <Container maxW="container.md" mt={20} py={10}>
          <Box mb={6}>
            <Heading as="h1" size="lg" mb={4}>Privacy Policy for urlme.site</Heading>
            <Text fontWeight="bold" mb={2}>1. Information We Collect</Text>
            <Text mb={4}>When you sign up for urlme.site, we collect personal information such as your name, email address, and any information you add to your portfolio, such as resumes, job history, or links to other profiles.</Text>
            <Text fontWeight="bold" mb={2}>2. How We Use Your Information</Text>
            <Text mb={4}>The information collected is used to provide our services to you, personalize your experience, and improve our platform. We may also use your email to send updates or service-related announcements.</Text>
            <Text fontWeight="bold" mb={2}>3. Sharing Your Information</Text>
            <Text mb={4}>We do not share your personal information with third parties except for the purposes of service provision, compliance with the law, or protection of our rights. Any third parties we engage are contractually obligated to protect your data.</Text>
            <Text fontWeight="bold" mb={2}>4. Security</Text>
            <Text mb={4}>We take reasonable precautions to protect your information from loss, misuse, or unauthorized access. However, no data transmission over the Internet is 100% secure, so we cannot guarantee absolute security.</Text>
            <Text fontWeight="bold" mb={2}>5. Cookies</Text>
            <Text mb={4}>Our site uses cookies to enhance your experience. Cookies help us understand user preferences and improve our services. By using urlme.site, you consent to our use of cookies.</Text>
            <Text fontWeight="bold" mb={2}>6. Your Rights</Text>
            <Text mb={4}>You have the right to access, correct, or delete your personal information at any time. If you wish to make changes or have your data removed, please contact us at support@urlme.site.</Text>
            <Text fontWeight="bold" mb={2}>7. Changes to This Privacy Policy</Text>
            <Text mb={4}>We may update this Privacy Policy periodically. Please check this page regularly to stay informed of any changes.</Text>
            <Text fontWeight="bold" mb={2}>8. Contact</Text>
            <Text>For questions about this Privacy Policy, please contact us at support@urlme.site.</Text>
          </Box>
        </Container>
        <FooterBig />

      </>
    );
  }
};

