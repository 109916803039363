import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input, InputGroup, InputRightElement, Link, useToast } from '@chakra-ui/react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Logo from '../../components/Logo/Logo';
import { createUser } from '../../apis/createUser';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/slices/userSlice';
import { loginUser } from '../../apis/loginUser';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth, db } from '../../firebase.config';
import { doc, getDoc } from 'firebase/firestore';
import { IoReturnDownBackOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { toastDefaultConfig } from '../../utils/constants';
import { toggleLogin } from '../../redux/slices/StatusSlice';

const LandingForm = ({ formType, handleUnmount, isSlidingOut }) => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [ResetPassword, setResetPassword] = useState(false);
  const navigate = useNavigate();
  const [IsSubmitting, setIsSubmitting] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const sendResetEmail = async (data) => {
    setIsSubmitting(true);
    sendPasswordResetEmail(auth, data.email)
      .then(() => {
        toast({
          title: 'Success',
          description: `We've emailed you a lnik to reset your password.`,
          status: 'success',
          ...toastDefaultConfig
        })
      })
      .catch((error) => {
        toast({
          title: 'Error',
          description: `Something went wrong! Please try again.`,
          status: 'error',
          ...toastDefaultConfig
        })
      }).finally(() => setIsSubmitting(false));
  }
  const handleResponse = async (response) => {
    if (response?.isLogged) {
      try {
        // Fetch user data from Firestore
        const userDocRef = doc(db, "users", response.user?.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Dispatch login action with user data
          dispatch(toggleLogin(true));
          dispatch(login({
            ...userData, // Store the fetched user data in Redux
            uid: response.user?.uid
          }));
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
      } 
    } else {
      if (response?.message?.toLowerCase().includes('email')) {
        setError('email', {
          message: response?.message
        })
      } else {
        setError('password', {
          message: response?.message
        })
      }
    }

    setIsSubmitting(false);
  }

  
  const handleSubmitData = async (data) => {
    setIsSubmitting(true);
    if (ResetPassword) {
      sendResetEmail(data);
      return;
    }
    if (formType === 'Sign up') {
      const response = await createUser(data);
      handleResponse(response);
    } else if (formType === 'Login') {
      const response = await loginUser(data);
      handleResponse(response);
    }
  }

  const getSubtleMessage = () => {
    if (formType === 'Login' && ResetPassword === false) {
      return 'Welcome back, please enter your details to login.';
    } else if (formType === 'Login' && ResetPassword === true) {
      return 'Enter your email and check your inbox for a password reset link.';
    } else if (formType === "Sign up") {
      return 'Please enter your details to create an account.';
    }

  }

  return (
    <div className={`form_container ${isSlidingOut ? 'slide-out' : 'slide-in'}`} id={formType}>
      <form className='form' onSubmit={handleSubmit((data) => handleSubmitData(data))}>
        <div id="go_back" onClick={() => (navigate('/'))}>
          <IoReturnDownBackOutline size={"25px"} />
          <p>Back to home</p>
        </div>
        <Logo />
        {/* <h4 className='tagline'><b>Share your work</b> with others</h4> */}
        <div className='subtle_message'>
          {getSubtleMessage()}
        </div>
        <div>
          <label>Email</label>
          <Input
            type="email"
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Please enter a valid email address'
              }
            })}
          />
          {errors.email && <p className='error'>{errors.email.message}</p>}
        </div>
        {!ResetPassword &&
          <div>
            <label>Password</label>
            <InputGroup>
              <Input
                type={showPassword ? 'text' : 'password'}
                {...register('password', {
                  required: "Password is required",
                  minLength: {
                    value: formType === 'Sign up' ? 8 : 1,
                    message: formType === 'Sign up' ? 'Password must be at least 8 characters long' : 'Password is required'
                  }
                })} />
              <InputRightElement width="3rem">
                <Button colorScheme='brand' variant="ghost" h="1.5rem" size="sm" onClick={togglePasswordVisibility}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </Button>
              </InputRightElement>
            </InputGroup>
            {errors.password && <p className='error'>{errors.password.message}</p>}
          </div>
        }
        {formType === 'Login' && !ResetPassword ?
          <div id="forgot_pass"><Link onClick={() => setResetPassword(true)}>Don't remember your password?</Link></div> :
          <div id="forgot_pass"></div>}
        <Button colorScheme='brand' size='md' mt={2} type="submit" isLoading={IsSubmitting}>
          {formType === 'Login' && ResetPassword ? 'Send Link' : formType}
        </Button>
        {formType === 'Login' && !ResetPassword ?
          <div className='landing_form_bottom_text'>
            Don't have an account?&nbsp;
            <Link onClick={handleUnmount}>Sign up</Link>
          </div> :
          formType === "Sign up" ?
            <div className='landing_form_bottom_text'>
              Already have an account?&nbsp;
              <Link onClick={handleUnmount}>Login</Link>
            </div> :
            formType === 'Login' && ResetPassword &&
            <div className='landing_form_bottom_text'>
              <Link onClick={() => setResetPassword(false)}>Go back</Link>
            </div>
        }
      </form>
    </div >
  );
};

export default LandingForm;