import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import "./styles.scss";
import OnBoardingPage from '../../components/OnBoardingPage/OnBoardingPage';
import { useToast } from '@chakra-ui/react';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase.config';
import { useDispatch, useSelector } from 'react-redux';
import { toastDefaultConfig } from '../../utils/constants';
import { login } from '../../redux/slices/userSlice';
import Profile from '../../components/Profile/Profile';
import { useParams } from 'react-router-dom';
import { toggleLogin } from '../../redux/slices/StatusSlice';

const Home = () => {
  const { user } = useSelector(state => state.user);

  const dispatch = useDispatch();

  const toast = useToast();

  useEffect(() => {
    // Check if user exists and has an email
    if (user && user?.uid) {
      const userDocRef = doc(db, "users", user?.uid);

      // Set up the Firestore onSnapshot listener
      const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const updatedUserData = docSnapshot.data();

          // Check if there are actual changes before dispatching
          if (JSON.stringify(user) !== JSON.stringify(updatedUserData)) {
            dispatch(login({ ...updatedUserData}));
            dispatch(toggleLogin(true));
          }
        }
      });

      // Cleanup listener on component unmount
      return () => unsubscribe();
    }
  }, []);

  const checkOnBoardingStatus = async () => {
    let status = false;
    try {
      const userDocRef = doc(db, "users", user?.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const data = userDocSnap.data();
        status = data.onBoardingCompleted
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch onboarding status. Please try again.",
        status: "error",
        ...toastDefaultConfig
      });
    }
    dispatch(login({
      ...user,
      onBoardingCompleted: status
    }))
  };


  useEffect(() => {
    if (!user?.onBoardingCompleted) {
      checkOnBoardingStatus();
    }
  }, [])

  return (
    <div className='home_wrapper'>
      {!user?.onBoardingCompleted ? <OnBoardingPage /> :
        <Profile />}
    </div>
  )
}

export default Home