import { useDisclosure, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { insertOrReplaceObject } from '../../../helper/random.helper';
import { toastDefaultConfig } from '../../../utils/constants';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase.config';
import { login } from '../../../redux/slices/userSlice';
import { toggleAchievements } from '../../../redux/slices/sectionEditSlice';


const useEditAchievements = ({ extUser }) => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector(state => state.user);
  const [user, setUser] = useState();
  useEffect(() => {
    if (extUser) {
      setUser(extUser);
    } else {
      setUser(currentUser);
    }
  }, [extUser, currentUser])
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [EditingAchievementObjIndex, setEditingAchievementObjIndex] = useState(null);
  const InitialFormValue = {
    title: '',
    year: '',
  }
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: InitialFormValue,
  });

  const { achievements } = useSelector((state) => state.sectionEdit);

  useEffect(() => {
    if (achievements) {
      onOpen();
    } else {
      handleClose();
    }
  }, [achievements])

  const handleClose = () => {
    reset({
      title: '',
      year: '',
    });
    setEditingAchievementObjIndex(null);
    onClose();
    if (achievements) {
      dispatch(toggleAchievements());
    }
  }

  const resetFormWithAchievementIdData = (achievementItem) => {
    reset({
      title: achievementItem.title,
      year: achievementItem.year,
    })
  }

  const onSubmit = (data) => {
    const payload = insertOrReplaceObject(user?.achievements, {
      year: data.year,
      title: data.title
    }, EditingAchievementObjIndex)
    dbWriteAchievementFn(payload);
  }

  const actionFn = () => {
    const firstHalf = user?.achievements.slice(0, EditingAchievementObjIndex);
    const secondHalf = user?.achievements.slice(EditingAchievementObjIndex + 1);
    let payload = [...firstHalf, ...secondHalf]
    dbWriteAchievementFn(payload);
  }

  const dbWriteAchievementFn = async (payload) => {
    setIsSubmitting(true);

    try {
      if (!user) {
        console.error("User is not authenticated.");
        return;
      }

      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, { achievements: payload });
      dispatch(login({ ...user, achievements: payload }));
      handleClose();
    } catch (error) {
      toast({
        title: `Something went wrong! Please try again.`,
        status: 'error',
        isClosable: false,
        ...toastDefaultConfig,
      });
    } finally {
      setIsSubmitting(false);
      setEditingAchievementObjIndex(null);
    }
  }

  return {
    user,
    register,
    errors,
    handleSubmit,
    reset,
    isSubmitting,
    onSubmit,
    actionFn,
    resetFormWithAchievementIdData,
    EditingAchievementObjIndex,
    setEditingAchievementObjIndex,
    isOpen,
    onOpen,
    onClose,
    handleClose,
  };
};

export default useEditAchievements;
