import React from 'react';
import {
  FormControl,
  VStack,
  Input,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import EditModal from '../../../atoms/EditModal';
import ErrorBox from '../../../atoms/ErrorBox';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MdDragIndicator } from 'react-icons/md';

const SoftSkillsEdit = (editSoftSkills) => {
  const {
    isOpen,
    onOpen,
    tools,
    isSubmitting,
    handleInputChange,
    onDragEnd,
    onSubmit,
    errors,
    handleSubmit,
    handleClose,
  } = editSoftSkills;
  return (
    <EditModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      headerText={'Tools'}
      saveFn={handleSubmit(onSubmit)}
      IsSubmitting={isSubmitting}
    >
      <VStack className='modal_form' p={"20px"} spacing={4} overflow={'auto'} height={'calc(100dvh - 135px)'}>
        {errors?.soft_skills && <ErrorBox errorText={errors?.soft_skills?.message} />}
        <FormControl>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="tools">
              {(provided) => (
                <VStack {...provided.droppableProps} ref={provided.innerRef} spacing={2}>
                  {tools?.map((tool, index) => (
                    <Draggable key={index} draggableId={`tool-${index}`} index={index}>
                      {(provided) => (
                        <HStack
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          spacing={3}
                          width="100%"
                        >
                          <IconButton
                            icon={<MdDragIndicator />}
                            variant="ghost"
                            {...provided.dragHandleProps} // Handle for dragging
                            aria-label="Drag tool"
                          />
                          <Input
                            background="#fff"
                            type="text"
                            fontSize={'sm'}
                            placeholder={`Cool stuff`}
                            value={tool}
                            onChange={handleInputChange(index)} // Now using memoized handler
                          />
                        </HStack>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </VStack>
              )}
            </Droppable>
          </DragDropContext>
        </FormControl>
      </VStack>
    </EditModal>
  );
};

export default SoftSkillsEdit;
