import { Box, Text, useDisclosure, VStack, Flex, HStack, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, DrawerCloseButton, Image } from '@chakra-ui/react';
import React from 'react';
import { PiPencilCircleDuotone, PiPlusCircleDuotone, PiTextColumnsDuotone } from 'react-icons/pi';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { getTextColor, titleCase } from '../../../../helper/random.helper';
import Timeline from '../Timeline';
import useEditExperience from '../../../EditSections/editExperience/useEditExperience';
import ExperienceEdit from '../../../EditSections/editExperience/ExperienceEdit';


const Experience = ({ extUser }) => {
  const { onOpen: onOpenDescription, isOpen: isOpenDescription, onClose: onCloseDescription } = useDisclosure();
  const editExp = useEditExperience({ extUser });
  const {
    user,
    reset,
    SelectedWorkObj,
    setSelectedWorkObj,
    EditingWorkObjIndex,
    setEditingWorkObjIndex,
    resetFormWithWorkIdData,
    handleClose,
    InitialFormValue,
    isOpen,
    onOpen,
  } = editExp;

  return (
    <>
      {user?.work?.length ? <Flex
        position={'relative'}
        width={"100%"}
        minWidth={{ base: '100%' }}
        direction={"column"}
        gap={3}
        pr={{ base: 0, md: 3 }}
        borderRight={{ base: "none", md: "2px solid" }}
        borderColor={{ md: user?.theme[1] }}
        p={"0px"}
      >
        {!extUser && <Box
          cursor={"pointer"}
          position={"absolute"}
          top={"10px"}
          right={"10px"}
          onClick={() => {
            onOpen();
            reset(InitialFormValue);
          }}
        >
          <PiPlusCircleDuotone size="18px" />
        </Box>}
        <Text color={user?.theme[1]} className={"font_cursive"} fontSize={"4xl"} fontWeight={"500"}>Working<br />Experience.</Text>
        {user?.work.map((item, index) => {
          return (
            <VStack
              w="100%"
              gap={3}
              alignItems={"flex-start"}
              position={'relative'}
              py={4}
              key={index}>
              <Text fontWeight={"500"}
                fontSize={"sm"} background={user?.theme[1]}
                color={getTextColor(user?.theme[1]) ? 'brand.white' : 'brand.black'}
                p={"5px 10px"}
                borderRadius={0} >{item.company}</Text>
              <Text className="font_cursive2" fontStyle={"italic"}
                width={'fit-content'}
                color={user?.theme[1]}
                mt={"-3px"}
              >
                {item.title}
              </Text>
              <Text color={user?.theme[1]}>
                <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                  {item.summary}
                </Markdown>
              </Text>
              <HStack wrap={"wrap"} gap={2}>
                {item?.images.length > 0 && item.images.map((item, index) => {
                  return (
                    <Image src={item} key={index} maxHeight={"150px"} objectFit={"contain"} />
                  )
                })}
              </HStack>
              <HStack gap={2} w="100%" alignItems={"start"}>
                <Timeline date={item.years} color={user?.theme[1]} />
                <Box
                  cursor={"pointer"}
                  onClick={() => {
                    onOpenDescription();
                    setSelectedWorkObj(item);
                  }}
                >
                  <PiTextColumnsDuotone size="16px" color={user?.theme[1]} />
                </Box>
                {!extUser &&
                  <Box
                    cursor={"pointer"}
                    onClick={() => {
                      setEditingWorkObjIndex(index);
                      onOpen();
                      setSelectedWorkObj(item);
                      resetFormWithWorkIdData(item)
                    }}
                  >
                    <PiPencilCircleDuotone size="18px" />
                  </Box>
                }
              </HStack>
            </VStack>
          );
        })}

      </Flex > : null
      }
      <ExperienceEdit {...editExp} />
      <Drawer
        blockScrollOnMount={false}
        isOpen={isOpenDescription}
        onOpen={onOpenDescription}
        onClose={() => {
          onCloseDescription();
          setSelectedWorkObj(null);
        }}
      >
        <DrawerOverlay />
        <DrawerContent
          border={"2px solid"}
          borderColor={user?.theme[1]}
          borderRadius={"0"} background="#f4f4f2" zIndex={9999} maxWidth={{ base: "90%", md: "50%" }}>
          <DrawerCloseButton color={user?.theme[1]} />
          <DrawerHeader
            color={user?.theme[1]} textAlign={"center"} fontSize={'sm'} fontWeight={'500'} borderBottom={"2px solid"} borderColor={user?.theme[1]}>
            {SelectedWorkObj?.company}
          </DrawerHeader>
          <DrawerBody className="app-scroll" overflow={'auto'} p={"20px 40px"} width={"100%"}>
            {SelectedWorkObj?.images.length > 0 && <HStack className="app-scroll" mt={2} mb={4} overflowX={"auto"} gap={2}>
              {SelectedWorkObj.images.map((item, index) => {
                return (
                  <Image src={item} key={index} maxHeight={"350px"} objectFit={"contain"} />
                )
              })}
            </HStack>
            }
            <Text fontSize={'sm'} color={user?.theme[1]} fontStyle={'italic'} className='font_cursive2' mb={3} >{titleCase(SelectedWorkObj?.title)}</Text>
            <Text color={user?.theme[1]}>
              <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                {SelectedWorkObj?.description}
              </Markdown>
            </Text>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Experience;
