import { Box, Text, VStack, Flex, HStack, Image } from '@chakra-ui/react';
import React, { useState } from 'react';
import { PiPencilCircleDuotone, PiPlusCircleDuotone } from 'react-icons/pi';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { getTextColor } from '../../../../helper/random.helper';
import { MdArrowOutward } from 'react-icons/md';
import ProjectEdit from '../../../EditSections/editProjects/ProjectEdit';
import useEditProject from '../../../EditSections/editProjects/useEditProject';


const VisibleOnHover = ({ user, item }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Box onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
      <MdArrowOutward
        size="50"
        cursor="pointer"
        onClick={() => setIsVisible(!isVisible)}
        color={user?.theme[1]}
      />

      <Box
        position="absolute"
        background={user?.theme[1]}
        p={10}
        zIndex={9999}
        width={{ base: '100%', md: '80%' }}
        transition="opacity 0.5s ease, visibility 0.5s ease" // Smooth transition
        opacity={isVisible ? 1 : 0} // Control fade with opacity
        visibility={isVisible ? 'visible' : 'hidden'} // Prevent interaction when hidden
        pointerEvents={isVisible ? 'auto' : 'none'} // Disable click interaction when hidden
      >
        <Text color={getTextColor(user?.theme[1]) ? 'brand.white' : 'brand.black'} fontSize={{ base: 'lg', md: '2xl' }}>
          <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>{item.description}</Markdown>
        </Text>
      </Box>
    </Box>
  )
}

const Projects = ({ extUser }) => {
  const editProject = useEditProject({ extUser });
  const {
    setEditingProjectObjIndex,
    resetFormWithProjectIdData,
    onOpen,
    user,
    setSelectedProjectObj,
    SelectedProjectObj,
  } = editProject;
  return (
    <>
      {user?.other_projects?.length ? <Flex
        position={'relative'}
        width={"100%"}
        minWidth={{ base: '100%' }}
        alignItems={"flex-start"}
        direction="column"
        pr={{ base: 0, md: 3 }}
        p={"0px"}
        gap={3}
      >
        <HStack w={"100%"} justify={"space-between"}>
          <Text className={"font_cursive"} fontSize={"4xl"} fontWeight={"500"}>Projects.</Text>
          {!extUser && <Box cursor={'pointer'} onClick={onOpen}>
            <PiPlusCircleDuotone size="18px" />
          </Box>}
        </HStack>
        {user?.other_projects.map((item, index) => {
          return (
            <VStack
              w="100%"
              gap={3}
              alignItems={"flex-start"}
              position={'relative'}
              py={4}
              key={index}>
              <HStack spacing={0} wrap={"wrap"}>
                <Text fontWeight={"500"}
                  fontSize={{ base: 'lg', md: '2xl', }}
                  borderRadius={0} >{item.company}</Text>
                <Box width={"40px"} borderTop="2px solid" borderColor={"brand.black"} />
                <Text className="font_cursive2" fontStyle={"italic"}
                  width={'fit-content'}
                  fontSize={{ base: 'lg', md: '2xl', }}
                >
                  {item.title}
                </Text>
              </HStack>
              <Text fontSize={{ base: 'lg', md: '2xl', }}>
                <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                  {item.summary}
                </Markdown>
              </Text>
              <VisibleOnHover user={user} item={item} />
              {item?.images.length > 0 && <HStack
                alignItems={"flex-start"} height={"calc(100dvh - 200px)"} className="invisible-scroll" overflow={"auto"} gap={4}>
                {item.images.map((item, index) => {
                  return (
                    <Image mt={index % 2 !== 0 ? '100px' : '0'} src={item} key={index} height={"calc(100dvh - 300px)"}
                      draggable={false} objectFit={"contain"} />
                  )
                })}
              </HStack>}
              <HStack spacing={0} position="absolute" className='font_playfair' fontSize={{ base: "md", md: "2xl" }} right={"0px"} bottom={"0px"}>
                {/* <CircleTextAnimation text={'Scroll right • Scroll right •'} /> */}
                <Text >{item.year}</Text>
                <Box width={"40px"} borderTop="2px solid" borderColor={"brand.black"} />
              </HStack>
              <HStack gap={2} w="100%" alignItems={"start"}>
                {!extUser &&
                  <Box
                    cursor={"pointer"}
                    onClick={() => {
                      setEditingProjectObjIndex(index);
                      onOpen();
                      setSelectedProjectObj(item);
                      resetFormWithProjectIdData(item)
                    }}
                  >
                    <PiPencilCircleDuotone size="18px" />
                  </Box>
                }
              </HStack>
            </VStack>
          );
        })}
      </Flex> : null}
      <ProjectEdit {...editProject} />
    </>
  );
};

export default Projects;
