import React from 'react';
import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { CgShapeZigzag } from 'react-icons/cg';
import Logo from '../Logo/Logo';
import moment from 'moment';


const FooterBig = () => {
  const navigate = useNavigate();
  return (
    <VStack spacing={2} background={"#FDB8B1"} px={{ base: "25px" }} w={"full"} position={"relative"} justifyContent={"center"} pt="50px">
      <HStack h="full" alignItems={"center"} justifyContent={"center"} spacing={0} fontSize={{ base: "20px", sm: "35px", md: "45px", lg: "65px" }}>
        <Box display={{ base: "block", md: 'none' }}>
          <Logo height={"50px"} fontSize={""} />
        </Box>
        <Box display={{ base: "none", md: 'block' }}>
          <Logo height={"80px"} fontSize={""} />
        </Box>
        <Text>/username</Text>
      </HStack>
      <VStack cursor="pointer" onClick={() => navigate("/get-started?sign_up=true")} >
        <Text fontSize={{ base: "2xl", md: "2xl" }} fontWeight={700}>Get your url now</Text>
        <HStack justifyContent="center" mt={-7} spacing={-2} mr="-10px" alignItems={"center"} color={"#fff"}
          _hover={{ color: "#F70A8D" }}>
          <CgShapeZigzag size={"50px"} style={{ marginLeft: "-10px" }} />
          <CgShapeZigzag size={"50px"} style={{ marginLeft: "-10px" }} />
          <CgShapeZigzag size={"50px"} style={{ marginLeft: "-10px" }} />
          <CgShapeZigzag size={"50px"} style={{ marginLeft: "-10px" }} />
          <CgShapeZigzag size={"50px"} style={{ marginLeft: "-10px" }} />
          <CgShapeZigzag size={"50px"} style={{ marginLeft: "-10px" }} />
        </HStack>
      </VStack>


      <Flex px={{ base: "25px" }} borderRadius={"50px 50px 0px 0px"} mt={10} w={"99%"} position={"relative"} pt="50px" pb="30px" background={"#FE5345"} color="#fff" justifyContent={"flex-start"} alignItems={"flex-start"} flexDir={{ base: "column", md: "row" }} gap={4}>
        <Text fontSize={"sm"} cursor={"pointer"} onClick={() => navigate("/learn/terms")}>Terms and conditions</Text>
        <Text fontSize={"sm"} cursor={"pointer"} onClick={() => navigate("/learn/privacy")}>Privacy policy</Text>
      </Flex>
    </VStack>
  )
}

export default FooterBig