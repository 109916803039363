import { Box, Text, VStack, Flex, HStack } from '@chakra-ui/react';
import React from 'react';
import { PiPencilCircleDuotone, PiPlusCircleDuotone } from 'react-icons/pi';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { getTextColor } from '../../../../helper/random.helper';
import useEditEducation from '../../../EditSections/editEducation/useEditEducation';
import EducationEdit from '../../../EditSections/editEducation/EducationEdit';
import Timeline from '../Timeline';

const Education = ({ extUser }) => {
  const editEducation = useEditEducation({ extUser });

  const {
    user,
    reset,
    resetFormWithEducationIdData,
    onOpen,
    setEditingEducationObjIndex,
    InitialFormValue
  } = editEducation;
  return (
    <>
      {user?.education?.length ? <Flex
        position={'relative'}
        width={{ base: '100%', md: '300px' }}
        direction={"column"}
        gap={3}
        maxWidth={{ base: "100%" }}
        pl={{ base: 0, md: 3 }}
        p={"0px"}
      >
        {!extUser && <Box
          cursor={"pointer"}
          position={"absolute"}
          top={"10px"}
          right={"10px"}
          onClick={() => {
            onOpen();
            reset(InitialFormValue);
          }}
        >
          <PiPlusCircleDuotone size="18px" />
        </Box>}
        <Text className='font_cursive' color={user?.theme[1]} fontSize={"4xl"} fontWeight={"500"}>Education.</Text>
        {user?.education.map((item, index) => {
          return (
            <VStack
              gap={3}
              alignItems={"flex-start"}
              position={'relative'}
              pb={4}
              key={index}>
              <Text fontWeight={"500"}
                fontSize={"sm"} background={user?.theme[1]}
                color={getTextColor(user?.theme[1]) ? 'brand.white' : 'brand.black'}
                p={"5px 10px"}
                borderRadius={"0"} >{item.name}</Text>
              <Text className="font_cursive2" fontStyle={"italic"}
                width={'fit-content'}
                mt={"-3px"}
                color={user?.theme[1]}
              >
                {item.course}
              </Text>
              <Text color={user?.theme[1]}>
                <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                  {item.description}
                </Markdown>
              </Text>
              <HStack gap={2} w="100%" alignItems={"start"}>
                <Timeline date={String(item.year)} color={user?.theme[1]} />
                {!extUser &&
                  <Box
                    cursor={"pointer"}
                    onClick={() => {
                      setEditingEducationObjIndex(index);
                      onOpen();
                      resetFormWithEducationIdData(item)
                    }}
                  >
                    <PiPencilCircleDuotone size="18px" />
                  </Box>
                }
              </HStack>
            </VStack>
          );
        })}

      </Flex> : null}
      <EducationEdit {...editEducation} />
    </>
  );
};

export default Education;
