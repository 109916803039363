import { useDisclosure, VStack, FormControl, FormLabel, Input, Textarea, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditModal from '../../../atoms/EditModal';
import ErrorBox from '../../../atoms/ErrorBox';
import { useForm } from 'react-hook-form';
import { insertOrReplaceObject } from '../../../helper/random.helper';
import { toastDefaultConfig } from '../../../utils/constants';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase.config';
import { login } from '../../../redux/slices/userSlice';
import { toggleEducation } from '../../../redux/slices/sectionEditSlice';


const EducationEdit = (editEducation) => {
  const {
    register,
    errors,
    handleSubmit,
    isSubmitting,
    onSubmit,
    actionFn,
    handleClose,
    isOpen,
    onOpen,
    EditingEducationObjIndex,
  } = editEducation;

  return (
    <EditModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      headerText={'Education Education'}
      saveFn={handleSubmit(onSubmit)}
      IsSubmitting={isSubmitting}
    >
      <VStack className='modal_form' p={"20px"} spacing={4} overflow={'auto'} height={'calc(100dvh - 140px)'}>
        {errors?.name || errors?.course || errors?.start_date || errors?.end_date || errors?.description ?
          <ErrorBox errorText={errors?.name?.message || errors?.course?.message || errors?.start_date?.message || errors?.end_date?.message || errors?.description?.message} /> : null}
        <FormControl isInvalid={errors.name} display={'flex'} columnGap={10} flexDirection={'row'} alignItems={'center'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Name</FormLabel>
          <Input
            fontSize={'sm'}
            placeholder="MIT"
            {...register('name', { required: 'Name name is required' })}
          />
        </FormControl>
        <FormControl isInvalid={errors.course} display={'flex'} columnGap={10} flexDirection={'row'} alignItems={'center'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Course</FormLabel>
          <Input
            fontSize={'sm'}
            placeholder="B.Tech in Computer Science"
            {...register('course', { required: 'Course is required' })}
          />
        </FormControl>
        <FormControl isInvalid={errors.start_date} display={'flex'} columnGap={10} flexDirection={'row'} alignItems={'center'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Start date</FormLabel>
          <Input
            fontSize={'sm'}
            placeholder="January 2023"
            {...register('start_date', {
              required: 'Start date is required',
              pattern: {
                value: /^(January|February|March|April|May|June|July|August|September|October|November|December) \d{4}$/,
                message: 'Start date must be in "Month Year" format (e.g., January 2023)'
              }
            })}
          />
        </FormControl>
        <FormControl isInvalid={errors.end_date} display={'flex'} columnGap={10} flexDirection={'row'} alignItems={'center'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>End date</FormLabel>
          <Input
            fontSize={'sm'}
            placeholder="September 2024 or Present"
            {...register('end_date', {
              required: 'End date is required',
              pattern: {
                value: /^(January|February|March|April|May|June|July|August|September|October|November|December) \d{4}$|^Present$/,
                message: 'End date must be in "Month Year" format (e.g., September 2024) or "Present"'
              }
            })}
          />
        </FormControl>
        {/* summary */}
        <FormControl isInvalid={errors.summary} display={'flex'} rowGap={"10px"} flexDirection={'column'} alignItems={'self-start'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Description</FormLabel>
          <Textarea
            type="textarea"
            fontSize={'sm'}
            rows={4}
            borderRadius={'10px'}
            className="app-scroll"
            placeholder="Tell us more about this position... You can format your text using HTML tags. Try using:
              <b>Bold</b>
              <i>Italic</i>
              <ul><li>Lists</li></ul>
              <a href='#'>Links</a>"
            {...register('description', { required: 'Description is required', maxLength: { value: 300, message: '300 characters allowed' }, minLength: { value: 60, message: 'Minimum 60 characters required' } })}
          />
        </FormControl>
        {EditingEducationObjIndex !== null && <ErrorBox errorText={"Do you want to delete this education from the list?"}
          actionBtn={() => actionFn()} actionBtnText={'Delete'} />}
      </VStack>
    </EditModal>
  );
};

export default EducationEdit;
