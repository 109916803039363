import { useState, useRef } from 'react';

const useCopyToClipboard = (text) => {
  const [tooltip, setTooltip] = useState('');
  const timeoutRef = useRef(null);

  const handleCopy = () => {
    try {
      // Create a temporary textarea to copy the text
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.body.removeChild(textarea);

      // Show the tooltip with "Copied!" message
      setTooltip('Copied!');
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => setTooltip(''), 2000); // Hide tooltip after 2 seconds
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  return { handleCopy, tooltip };
};

export default useCopyToClipboard;
