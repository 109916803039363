import React, { useEffect, useState } from 'react';

import "./styles.scss";
import StepZero from './OnBoardingSteps/StepZero';
import StepOne from './OnBoardingSteps/StepOne';
import { useSelector } from 'react-redux';
import StepTwo from './OnBoardingSteps/StepTwo';
import { Box } from '@chakra-ui/react';
import Line from '../../assets/Line';


const OnBoardingPage = () => {
  const { user } = useSelector(state => state.user);
  const [Step, setStep] = useState(0);


  useEffect(() => {
    if (user?.fullname && user?.role) {
      setStep(2);
    }
  }, [user])


  return (
    <div className='portfolio_container'>
      <div className='message_container'>
        <Box top={20} left={-5} position={"fixed"}>
          <Line color={"#89B5FE"} />
        </Box>
        {Step === 0 && <StepZero setStep={setStep} />}
        {Step === 1 && <StepOne setStep={setStep} />}
        {Step === 2 && <StepTwo setStep={setStep} />}
      </div>
    </div>
  )
}

export default OnBoardingPage