import { useDisclosure, useToast, } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getFileNameFromUrl, insertOrReplaceObject } from '../../../helper/random.helper';
import { toastDefaultConfig } from '../../../utils/constants';
import { doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../../firebase.config';
import { login } from '../../../redux/slices/userSlice';
import { toggleProjects } from '../../../redux/slices/sectionEditSlice';
import { resizeImage } from '../../../helper/resizeImage';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';


const useEditProject = ({ extUser }) => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector(state => state.user);
  const [user, setUser] = useState();
  useEffect(() => {
    if (extUser) {
      setUser(extUser);
    } else {
      setUser(currentUser);
    }
  }, [extUser, currentUser])
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [SelectedProjectObj, setSelectedProjectObj] = useState(null);
  const [EditingProjectObjIndex, setEditingProjectObjIndex] = useState(null);
  const [CopyImageArr, setCopyImageArr] = useState([]);

  const InitialFormValue = {
    title: '',
    year: '',
    summary: '',
    description: ''
  }
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control
  } = useForm({
    defaultValues: InitialFormValue,
  });

  const { projects } = useSelector((state) => state.sectionEdit);

  useEffect(() => {
    if (projects) {
      onOpen();
    } else {
      handleClose();
    }
  }, [projects])

  const handleClose = () => {
    reset({
      title: '',
      year: '',
      summary: '',
      description: ''
    });
    setEditingProjectObjIndex(null);
    onClose();
    if (projects) {
      dispatch(toggleProjects());
    }
  }

  const resetFormWithProjectIdData = (projectItem) => {
    setCopyImageArr(projectItem.images);
    reset({
      title: projectItem.title,
      year: projectItem.year,
      summary: projectItem.summary,
      description: projectItem.description
    })
  }

  const onSubmit = (data) => {
    const payload = insertOrReplaceObject(user?.other_projects, {
      year: data.year,
      summary: data.summary,
      description: data.description,
      title: data.title,
      images: data.images
    }, EditingProjectObjIndex)
    dbWriteProjectFn(payload);
  }

  const actionFn = async () => {
    const firstHalf = user?.other_projects.slice(0, EditingProjectObjIndex);
    const secondHalf = user?.other_projects.slice(EditingProjectObjIndex + 1);
    let payload = [...firstHalf, ...secondHalf]
    try {
      // delete images from firebase storage
      for (const image of user?.other_projects[EditingProjectObjIndex].images) {
        const storageRef = ref(storage, `users/${user.uid}/${getFileNameFromUrl(image)}`);
        await deleteObject(storageRef);
      }
      toast({
        title: "Project deleted",
        description: "Your project has been deleted successfully.",
        status: "success",
        isClosable: false,
        ...toastDefaultConfig,
      });
    } catch (error) {
      console.error("Error deleting Jobs: ", error);
    }
    dbWriteProjectFn(payload);
  }

  const dbWriteProjectFn = async (payload) => {
    setIsSubmitting(true);

    try {
      if (!user) {
        console.error("User is not authenticated.");
        return;
      }


      const uploadImagesAndGetUrls = async (images) => {
        const imageUrls = [];

        let ChangesInOldImageArr = images.filter(image => typeof image === 'string');
        let ImagesToDelete = CopyImageArr.filter(image => !ChangesInOldImageArr.includes(image));

        for (const image of images) {
          try {
            // Resize the image before uploading
            const resizedImage = await resizeImage(image);
            const storageRef = ref(storage, `users/${user.uid}/${resizedImage.name}`);
            await uploadBytes(storageRef, resizedImage); // Upload the resized image
            const url = await getDownloadURL(storageRef); // Get the download URL
            imageUrls.push(url); // Store the URL
          } catch (error) {
            console.error("Error resizing or uploading image: ", error);
          }
        }

        for (const image of ImagesToDelete) {
          try {
            // get image name from url
            const imageName = getFileNameFromUrl(image);
            const storageRef = ref(storage, `users/${user.uid}/${imageName}`);
            await deleteObject(storageRef);
          } catch (error) {
            console.error("Error deleting images: ", error);
          }
        }
        return imageUrls;
      };

      // Upload images to Firebase Storage and get the URLs
      const imageUrls = payload.length > 0 ? await uploadImagesAndGetUrls(payload?.[EditingProjectObjIndex].images) : [];

      const modPayload = payload.length > 0 ? payload.map((item, index) => {
        if (index === EditingProjectObjIndex) {
          return {
            ...item,
            images: [...item.images.filter((file) => typeof file === 'string'), ...imageUrls]
          }
        } else
          return item;
      }) : [];

      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, { other_projects: modPayload });
      dispatch(login({ ...user, other_projects: modPayload }));
      handleClose();
    } catch (error) {
      toast({
        title: `Something went wrong! Please try again.`,
        status: 'error',
        isClosable: false,
        ...toastDefaultConfig,
      });
    } finally {
      setIsSubmitting(false);
      setEditingProjectObjIndex(null);
    }
  }

  return {
    EditingProjectObjIndex,
    setEditingProjectObjIndex,
    InitialFormValue,
    register,
    errors,
    handleSubmit,
    reset,
    setValue,
    control,
    onSubmit,
    actionFn,
    isSubmitting,
    handleClose,
    resetFormWithProjectIdData,
    onOpen,
    isOpen,
    onClose,
    user,
    setSelectedProjectObj,
    SelectedProjectObj,
  };
};

export default useEditProject;
