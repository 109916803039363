export function mergeProfiles(profileOBJ, resumeOBJ, overwrite) {
  // Create a new object to hold the merged results
  let mergedOBJ = {};

  // Get all unique keys from both objects
  const allKeys = new Set([...Object.keys(profileOBJ), ...Object.keys(resumeOBJ)]);

  // Iterate over all unique keys
  allKeys.forEach((key) => {
    if (profileOBJ.hasOwnProperty(key) && resumeOBJ.hasOwnProperty(key)) {
      // If both objects have the key, decide based on the overwrite flag
      mergedOBJ[key] = overwrite ? profileOBJ[key] : resumeOBJ[key];
    } else if (profileOBJ.hasOwnProperty(key)) {
      // If only profileOBJ has the key
      mergedOBJ[key] = profileOBJ[key];
    } else {
      // If only resumeOBJ has the key
      mergedOBJ[key] = resumeOBJ[key];
    }
  });

  return mergedOBJ;
}
