import { VStack, FormControl, FormLabel, Input, Textarea } from '@chakra-ui/react';
import React from 'react';
import EditModal from '../../../atoms/EditModal';
import ErrorBox from '../../../atoms/ErrorBox';
import ImageUploader from '../../../atoms/ImageUploader';

const ExperienceEdit = ({
  register,
  errors,
  handleSubmit,
  setValue,
  control,
  isSubmitting,
  onSubmit,
  actionFn,
  SelectedWorkObj,
  EditingWorkObjIndex,
  isOpen,
  handleClose,
  onOpen }) => {

  return (
    <EditModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      headerText={'Work'}
      saveFn={handleSubmit(onSubmit)}
      IsSubmitting={isSubmitting}
      maxWidth={"700px"}
    >
      <VStack className='modal_form app-scroll' p={"20px"} spacing={4} overflow={'auto'} height={'calc(100dvh - 140px)'}>
        {errors?.company || errors?.title || errors?.start_date || errors?.end_date || errors?.description || errors?.summary ?
          <ErrorBox errorText={errors?.company?.message || errors?.title?.message || errors?.start_date?.message || errors?.end_date?.message || errors?.description?.message || errors?.summary?.message} /> : null}
        <FormControl isInvalid={errors.company} display={'flex'} columnGap={10} flexDirection={'row'} alignItems={'center'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Company</FormLabel>
          <Input
            fontSize={'sm'}
            placeholder="Monster Inc"
            {...register('company', { required: 'Company name is required' })}
          />
        </FormControl>
        <FormControl isInvalid={errors.title} display={'flex'} columnGap={10} flexDirection={'row'} alignItems={'center'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Position</FormLabel>
          <Input
            fontSize={'sm'}
            placeholder="Software Engineer"
            {...register('title', { required: 'Position is required' })}
          />
        </FormControl>
        <FormControl isInvalid={errors.start_date} display={'flex'} columnGap={10} flexDirection={'row'} alignItems={'center'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Start date</FormLabel>
          <Input
            fontSize={'sm'}
            placeholder="January 2023"
            {...register('start_date', {
              required: 'Start date is required',
              pattern: {
                value: /^(January|February|March|April|May|June|July|August|September|October|November|December) \d{4}$/,
                message: 'Start date must be in "Month Year" format (e.g., January 2023)'
              }
            })}
          />
        </FormControl>
        <FormControl isInvalid={errors.end_date} display={'flex'} columnGap={10} flexDirection={'row'} alignItems={'center'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>End date</FormLabel>
          <Input
            fontSize={'sm'}
            placeholder="September 2024 or Present"
            {...register('end_date', {
              required: 'End date is required',
              pattern: {
                value: /^(January|February|March|April|May|June|July|August|September|October|November|December) \d{4}$|^Present$/,
                message: 'End date must be in "Month Year" format (e.g., September 2024) or "Present"'
              }
            })}
          />
        </FormControl>
        {/* summary */}
        <FormControl isInvalid={errors.summary} display={'flex'} rowGap={"10px"} flexDirection={'column'} alignItems={'self-start'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Summary</FormLabel>
          <Textarea
            type="textarea"
            fontSize={'sm'}
            rows={4}
            borderRadius={'10px'}
            className="app-scroll"
            placeholder="Tell us more about this position... You can format your text using HTML tags. Try using:
              <b>Bold</b>
              <i>Italic</i>
              <ul><li>Lists</li></ul>
              <a href='#'>Links</a>"
            {...register('summary', { required: 'Summary is required', maxLength: { value: 300, message: '300 characters allowed' }, minLength: { value: 60, message: 'Minimum 60 characters required' } })}
          />
        </FormControl>
        <FormControl isInvalid={errors.description} display={'flex'} rowGap={"10px"} flexDirection={'column'} alignItems={'self-start'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Description</FormLabel>
          <Textarea
            className="app-scroll"
            type="textarea"
            fontSize={'sm'}
            borderRadius={'10px'}
            rows={8}
            placeholder="Tell us more about this position... You can format your text using HTML tags. Try using:
              <b>Bold</b>
              <i>Italic</i>
              <ul><li>Lists</li></ul>
              <a href='#'>Links</a>"
            {...register('description', { required: 'Description is required' })}
          />
        </FormControl>
        <FormControl id="images" gap={{ base: "10px", sm: "20px" }} display={"flex"} flexDir={{ base: 'column', sm: 'row' }} alignItems={{ sm: "center" }}>
          <FormLabel fontWeight={400} fontSize={'sm'} minWidth="120px" m={0}>
            Images
          </FormLabel>
          <ImageUploader EditImages={SelectedWorkObj} control={control} setValue={setValue} />
        </FormControl>
        {EditingWorkObjIndex !== null && <ErrorBox errorText={"Do you want to delete this experience from the list?"}
          actionBtn={() => actionFn()} actionBtnText={'Delete'} />}
      </VStack>
    </EditModal>
  );
};

export default ExperienceEdit;
