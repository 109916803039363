import React, { useEffect, useState } from 'react';
import { HiArrowLongRight } from "react-icons/hi2";
import { Animate } from 'react-simple-animate';
import ElegantText from '../../../atoms/ElegantText';
import { GoNorthStar } from "react-icons/go";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Link, FormControl, Text, VStack, useDisclosure, useToast, CircularProgress, HStack } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { HiOutlineDocumentText } from "react-icons/hi2";
import { toastDefaultConfig, userDetailsPrompt } from "../../../utils/constants";
import { doc, updateDoc } from 'firebase/firestore';
import { db, model } from '../../../firebase.config';
import { login } from '../../../redux/slices/userSlice';
import EditModal from '../../../atoms/EditModal';
import { mergeProfiles } from '../../../helper/mergeProfile';
import { titleCase } from '../../../helper/random.helper';

const StepTwo = ({ setStep }) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: { "application/pdf": [] },
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024, //10MB
    onDrop: (acceptedFile) => {
      setAcceptedFile(acceptedFile[0]);
    },
  });
  const [AcceptedFile, setAcceptedFile] = useState();
  const toast = useToast();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const [IsSubmitting, setIsSubmitting] = useState(false);
  const [playForward, setPlayForward] = useState(true);
  const duration = 0.8;
  const onSubmit = () => {
    if (AcceptedFile) {
      PDF2JSON();
    }
  }

  async function fileToGenerativePart(file) {
    const base64EncodedDataPromise = new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.readAsDataURL(file);
    });
    return {
      inlineData: { data: await base64EncodedDataPromise, mimeType: file.type },
    };
  }

  const PDF2JSON = async () => {
    setIsSubmitting(true);
    // Provide a text prompt to include with the image

    let result = null;
    if (AcceptedFile) {
      const PDFParts = await fileToGenerativePart(AcceptedFile);
      result = await model.generateContent([userDetailsPrompt, PDFParts]);
    } else {
      result = await model.generateContent([userDetailsPrompt + `Create an imagined thorough mock data for the user ${user?.fullname} based on their role - ${user?.role}`]);
    }

    const response = result.response;
    const text = response.text().replaceAll('`', '').replace('json', '');
    const data = mergeProfiles(user, JSON.parse(text), true);

    const payload = {
      ...data, resumeUpload: true, onBoardingCompleted: true
    }

    try {
      if (!user) {
        console.error("User is not authenticated.");
        return;
      }
      // Update the user's document in Firestore with the new data
      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, payload);

      // Dispatch the updated user data to the store
      dispatch(login({ ...payload }));
      onClose(); // Close the modal or form after submission
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      toast({
        title: `Something went wrong! Please try again.`,
        status: 'error',
        isClosable: false,
        ...toastDefaultConfig,
      });
    }
    setAcceptedFile(null);
    onClose();
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setPlayForward(prev => !prev);
    }, duration * 1000); // 300ms matches the duration of the animation
    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, []);

  const onBoardingCompleted = async () => {
    try {
      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, {
        onBoardingCompleted: true,
        private_email: false
      });
      dispatch(login({
        ...user,
        onBoardingCompleted: true,
        private_email: false
      }))
    } catch (error) {
      toast({
        title: 'Error',
        description: `Something went wrong! Please try again.`,
        status: 'error',
        ...toastDefaultConfig
      })
    }
  }

  return (
    <div className="message">
      <GoNorthStar size={"80px"} color={"#FFB700"} id="star" />
      <ElegantText>
        <Text fontWeight={700} className='message_highlight'>Hello, {titleCase(user?.fullname?.split(' ')[0])}!</Text>
      </ElegantText>
      <ElegantText>
        <p className='message_para'>Help us get your profile up to speed by&nbsp;
          <Link className='link_block' onClick={onOpen}>
            uploading your resume
          </Link>
        </p>
      </ElegantText>

      <div
        id="next_action"
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={() => IsSubmitting ? {} : PDF2JSON()}
      >
        {IsSubmitting ?
          <CircularProgress size={"20px"} isIndeterminate color="gray" /> :
          <>
            <p>continue using mock data</p>
            <Animate
              play={true}
              start={{ transform: playForward ? 'translateX(0px)' : 'translateX(10px)' }}
              end={{ transform: playForward ? 'translateX(10px)' : 'translateX(0px)' }}
              duration={duration}
              easeType="ease-in-out" // Smooth transition
            >
              <HiArrowLongRight size={"25px"} />
            </Animate>
          </>
        }
      </div>

      <EditModal
        isOpen={isOpen} onOpen={onOpen} onClose={onClose}
        headerText={'Upload Resume'}
        saveFn={onSubmit}
        IsSubmitting={IsSubmitting}
      >
        <VStack className='modal_form' p={"20px"} spacing={4} overflow={'auto'} height={'auto'}>
          <FormControl display={'flex'} columnGap={10} flexDirection={'column'} alignItems={'start'}>
            <Box {...getRootProps({ className: 'dropzone' })} background="#fff" cursor={"pointer"} width={"100%"} border={'2px dashed #BDBDBB'} px={"10px"} rounded={"md"} py={"20px"} textAlign={'center'}
              display={'flex'} flexDirection="column" alignItems={"center"}>
              <input {...getInputProps()} />
              <HiOutlineDocumentText size="30px" style={{ marginBottom: '10px' }} />
              {!AcceptedFile ?
                <>
                  <Text color="#55575E">Drag n' drop, or click to select</Text>
                  <Text color="#A4A6A9">PDF format</Text>
                </>
                : <Text color="#55575E">{AcceptedFile.name}</Text>}
            </Box>
          </FormControl>
        </VStack>
      </EditModal>
    </div>
  )
}

export default StepTwo;