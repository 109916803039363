import { VStack, FormControl, FormLabel, Input, Textarea } from '@chakra-ui/react';
import React from 'react';
import EditModal from '../../../atoms/EditModal';
import ErrorBox from '../../../atoms/ErrorBox';


const AchievementsEdit = (editAchievement) => {
  const {
    register,
    errors,
    handleSubmit,
    isSubmitting,
    onSubmit,
    actionFn,
    EditingAchievementObjIndex,
    isOpen,
    handleClose,
    onOpen,
  } = editAchievement;

  return (
    <EditModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      headerText={'Achievement'}
      saveFn={handleSubmit(onSubmit)}
      IsSubmitting={isSubmitting}
    >
      <VStack className='modal_form' p={"20px"} spacing={4} overflow={'auto'} height={'100%'}>
        {errors?.title || errors?.year ?
          <ErrorBox errorText={errors?.title?.message || errors?.year?.message} /> : null}
        <FormControl isInvalid={errors.year} display={'flex'} columnGap={10} flexDirection={'row'} alignItems={'center'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Year</FormLabel>
          <Input
            fontSize={'sm'}
            placeholder="2023"
            {...register('year', {
              required: 'Year is required',
              pattern: {
                value: /^\d{4}$/,
                message: 'Year must be in YYYY format'
              }
            })}
          />
        </FormControl>
        <FormControl isInvalid={errors.summary} display={'flex'} rowGap={"10px"} flexDirection={'column'} alignItems={'self-start'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Achievement</FormLabel>
          <Textarea
            type="textarea"
            fontSize={'sm'}
            rows={4}
            borderRadius={'10px'}
            className="app-scroll"
            placeholder="Tell us more about this position... You can format your text using HTML tags. Try using:
              <b>Bold</b>
              <i>Italic</i>
              <ul><li>Lists</li></ul>
              <a href='#'>Links</a>"
            {...register('title', { required: 'Title is required', maxLength: { value: 300, message: '300 characters allowed' }, minLength: { value: 60, message: 'Minimum 60 characters required' } })}
          />
        </FormControl>
        {EditingAchievementObjIndex !== null && <ErrorBox errorText={"Do you want to delete this Achievement from the list?"}
          actionBtn={() => actionFn()} actionBtnText={'Delete'} />}
      </VStack>
    </EditModal>
  );
}

export default AchievementsEdit;
