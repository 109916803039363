import { Box, Text, Button, useDisclosure, VStack, FormControl, FormLabel, Input, Textarea, useToast, Flex, HStack, Image } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { PiPencilCircleDuotone, PiPlusCircleDuotone } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import EditModal from '../../../../atoms/EditModal';
import ErrorBox from '../../../../atoms/ErrorBox';
import { useForm } from 'react-hook-form';
import { getFileNameFromUrl, getTextColor, insertOrReplaceObject, titleCase } from '../../../../helper/random.helper';
import { toastDefaultConfig } from '../../../../utils/constants';
import { doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../../../firebase.config';
import { login } from '../../../../redux/slices/userSlice';
import { toggleWork } from '../../../../redux/slices/sectionEditSlice';
import ImageUploader from '../../../../atoms/ImageUploader';
import { resizeImage } from '../../../../helper/resizeImage';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { MdArrowOutward } from 'react-icons/md';
import CircleTextAnimation from '../../../CircleTextAnimation/CircleTextAnimation';
import useEditExperience from '../../../EditSections/editExperience/useEditExperience';
import ExperienceEdit from '../../../EditSections/editExperience/ExperienceEdit';


const VisibleOnHover = ({ user, item }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Box onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
      <MdArrowOutward
        size="50"
        cursor="pointer"
        onClick={() => setIsVisible(!isVisible)}
        color={user?.theme[1]}
      />

      <Box
        position="absolute"
        background={user?.theme[1]}
        p={10}
        zIndex={9999}
        width={{ base: '100%', md: '80%' }}
        transition="opacity 0.5s ease, visibility 0.5s ease" // Smooth transition
        opacity={isVisible ? 1 : 0} // Control fade with opacity
        visibility={isVisible ? 'visible' : 'hidden'} // Prevent interaction when hidden
        pointerEvents={isVisible ? 'auto' : 'none'} // Disable click interaction when hidden
      >
        <Text color={getTextColor(user?.theme[1]) ? 'brand.white' : 'brand.black'} fontSize={{ base: 'lg', md: '2xl' }}>
          <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>{String(item.description)}</Markdown>
        </Text>
      </Box>
    </Box>
  )
}


const Experience = ({ extUser }) => {
  const editExp = useEditExperience({ extUser });
  const {
    user,
    reset,
    SelectedWorkObj,
    setSelectedWorkObj,
    EditingWorkObjIndex,
    setEditingWorkObjIndex,
    resetFormWithWorkIdData,
    handleClose,
    InitialFormValue,
    isOpen,
    onOpen,
  } = editExp;
  return (
    <>
      {user?.work?.length ? <Flex
        position={'relative'}
        width={"100%"}
        minWidth={{ base: '100%' }}
        direction={"column"}
        gap={3}
        pr={{ base: 0, md: 3 }}
        p={"0px"}
      >
        {!extUser && <Box
          cursor={"pointer"}
          position={"absolute"}
          top={"10px"}
          right={"10px"}
          onClick={() => {
            onOpen();
            reset(InitialFormValue);
          }}
        >
          <PiPlusCircleDuotone size="18px" />
        </Box>}
        <Text className={"font_cursive"} fontSize={"4xl"} fontWeight={"500"}>Work.</Text>
        {user?.work.map((item, index) => {
          return (
            <VStack
              w="100%"
              gap={3}
              alignItems={"flex-start"}
              position={'relative'}
              py={4}
              key={index}>
              <HStack spacing={0} wrap={"wrap"}>
                <Text fontWeight={"500"}
                  fontSize={{ base: 'lg', md: '2xl', }}
                  borderRadius={0} >{item.company}</Text>
                <Box width={"40px"} borderTop="2px solid" borderColor={"brand.black"} />
                <Text className="font_cursive2" fontStyle={"italic"}
                  width={'fit-content'}
                  fontSize={{ base: 'lg', md: '2xl', }}
                >
                  {item.title}
                </Text>
              </HStack>
              <Text fontSize={{ base: 'lg', md: '2xl', }} >
                <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                  {item.summary}
                </Markdown>
              </Text>
              <VisibleOnHover user={user} item={item} />
              {item?.images.length > 0 && <HStack
                alignItems={"flex-start"} height={"calc(100dvh - 200px)"} className="invisible-scroll" overflow={"auto"} gap={4}>
                {item.images.map((item, index) => {
                  return (
                    <Image mt={index % 2 !== 0 ? '100px' : '0'} src={item} key={index} height={"calc(100dvh - 300px)"}
                      draggable={false} objectFit={"contain"} />
                  )
                })}
              </HStack>}
              <HStack spacing={0} position="absolute" className='font_playfair' fontSize={{ base: "md", md: "2xl" }} right={"0px"} bottom={"0px"}>
                <Text >{item.years?.split('-')[0]}</Text>
                <Box width={"40px"} borderTop="2px solid" borderColor={"brand.black"} />
                <Text >{item.years?.split('-')[1]}</Text>
              </HStack>
              <HStack gap={2} w="100%" alignItems={"start"}>
                {!extUser &&
                  <Box
                    cursor={"pointer"}
                    onClick={() => {
                      setEditingWorkObjIndex(index);
                      onOpen();
                      setSelectedWorkObj(item);
                      resetFormWithWorkIdData(item)
                    }}
                  >
                    <PiPencilCircleDuotone size="18px" />
                  </Box>
                }
              </HStack>
            </VStack>
          );
        })}

      </Flex > : null
      }
      <ExperienceEdit {...editExp} />
    </>
  );
};

export default Experience;
