import React from 'react';
import { Flex, Tag, TagLeftIcon, IconButton } from '@chakra-ui/react';
import { LuShare2, LuMail } from 'react-icons/lu';
import { TbTools } from 'react-icons/tb';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { BsHourglassSplit } from 'react-icons/bs';
import { getLinkDetails, getTextColor, openLink } from '../../../../helper/random.helper';
import ShareableDiv from '../../../../atoms/SharableDiv';

const UserInfo = ({ user, display }) => {
  return (
    <Flex
      position={"absolute"} bottom={{ base: "-40px", md: "80px" }} left={{ base: 0, md: 20 }}
      display={display} gap={1} direction={'column'} alignItems="flex-start" wrap="wrap" justifyContent="flex-start">
      {!user?.private_email ? <Tag
        size="md"
        onClick={() => window.location.href = `mailto:${user?.email}`}
        borderRadius={0}
        backgroundColor="transparent"
        fontSize={{ base: 'lg', md: '2xl', }}
        className='font_playfair shadow_text'
        wordBreak={"break-word"}
        width={"fit-content"}
      >
        {user?.email}
      </Tag> : null}

      {user?.location ? (
        <Tag
          size="md"
          backgroundColor="transparent"
          fontSize={{ base: 'lg', md: '2xl', }}
          className='font_playfair shadow_text'
          width={"fit-content"}
          wordBreak={"break-word"}
        >
          {user?.location}
        </Tag>
      ) : null}
    </Flex>
  )
}

export default UserInfo