import { Box, Text, useDisclosure, VStack, Flex, HStack, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Image } from '@chakra-ui/react';
import React from 'react';
import { PiPencilCircleDuotone, PiPlusCircleDuotone, PiTextColumnsDuotone } from 'react-icons/pi';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { getTextColor } from '../../../../helper/random.helper';
import Timeline from '../Timeline';
import ProjectEdit from '../../../EditSections/editProjects/ProjectEdit';
import useEditProject from '../../../EditSections/editProjects/useEditProject';


const Projects = ({ extUser }) => {
  const { onOpen: onOpenDescription, isOpen: isOpenDescription, onClose: onCloseDescription } = useDisclosure();
  const editProject = useEditProject({ extUser });
  const {
    setEditingProjectObjIndex,
    resetFormWithProjectIdData,
    onOpen,
    user,
    setSelectedProjectObj,
    SelectedProjectObj,
  } = editProject;

  return (
    <>
      {user?.other_projects?.length ? <Flex
        position={'relative'}
        width={"100%"}
        minWidth={{ base: '100%' }}
        alignItems={"flex-start"}
        direction="column"
        pr={{ base: 0, md: 3 }}
        borderRight={{ base: 'none', md: '2px solid' }}
        borderColor={'brand.black'}
        p={"0px"}
        gap={3}
      >
        <HStack w={"100%"} justify={"space-between"}>
          <Text fontSize={"xl"} fontWeight={"500"}>Projects</Text>
          {!extUser && <Box cursor={'pointer'} onClick={onOpen}>
            <PiPlusCircleDuotone size="18px" />
          </Box>}
        </HStack>
        {user?.other_projects.map((item, index) => {
          return (
            <VStack
              w={"100%"}
              gap={3}
              alignItems={"flex-start"}
              border={'2px solid'}
              borderColor={'brand.black'}
              rounded={'25px'}
              position={'relative'}
              p={4}
              key={index}>
              <Text fontWeight={"500"}
                fontSize={"sm"} background={user?.theme[1]}
                color={getTextColor(user?.theme[1]) ? 'brand.white' : 'brand.black'}
                p={"5px 10px"}
                borderRadius={"25px"}
                width={'fit-content'}
              >
                {item.title}
              </Text>

              <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                  {item.summary}
                </Markdown>
              </Box>
              <HStack wrap={"wrap"} gap={2}>
                {item?.images.length > 0 && item.images.map((item, index) => {
                  return (
                    <Image src={item} key={index} maxHeight={"100px"} objectFit={"contain"} />
                  )
                })}
              </HStack>
              <HStack gap={2} w="100%">
                <Timeline date={String(item.year)} />
                <Box
                  cursor={"pointer"}
                  onClick={() => {
                    onOpenDescription();
                    setSelectedProjectObj(item);
                  }}
                >
                  <PiTextColumnsDuotone size="16px" />
                </Box>
                {!extUser &&
                  <Box
                    cursor={"pointer"}
                    onClick={() => {
                      setEditingProjectObjIndex(index);
                      onOpen();
                      setSelectedProjectObj(item);
                      resetFormWithProjectIdData(item)
                    }}
                  >
                    <PiPencilCircleDuotone size="18px" />
                  </Box>
                }
              </HStack>
            </VStack>
          );
        })}
      </Flex> : null}
      <ProjectEdit {...editProject} />
      <Drawer
        blockScrollOnMount={false}
        isOpen={isOpenDescription}
        onOpen={onOpenDescription}
        onClose={() => {
          onCloseDescription();
          setSelectedProjectObj(null);
        }}
      >
        <DrawerOverlay />
        <DrawerContent
          border={"2px solid"}
          borderColor={"brand.black"}
          borderRadius={"15px 0px 0px 15px"} background="#f4f4f2" zIndex={9999} maxWidth={{ base: "90%", md: "50%" }}>
          <DrawerCloseButton />
          <DrawerHeader textAlign={"center"} fontSize={'sm'} fontWeight={'500'} borderBottom={"2px solid"} borderColor={"brand.black"}>
            {SelectedProjectObj?.title}
          </DrawerHeader>
          <DrawerBody className="app-scroll" overflow={'auto'} p={"20px 40px"} width={"100%"}>
            {SelectedProjectObj?.images.length > 0 && <HStack className="app-scroll" mt={2} mb={4} overflowX={"auto"} gap={2}>
              {SelectedProjectObj.images.map((item, index) => {
                return (
                  <Image src={item} key={index} maxHeight={"350px"} objectFit={"contain"} />
                )
              })}
            </HStack>
            }
            <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
              {SelectedProjectObj?.description}
            </Markdown>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Projects;
