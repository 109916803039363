import React from 'react';
import { LiaStarOfLifeSolid } from 'react-icons/lia'; // Make sure to import the icon from the correct library
import './circleanimation.scss';

const CircleTextAnimation = ({ text, textColor, size }) => {
  return (
    <div className="circle-text-container" style={{ width: size, height: size }}>
      <svg viewBox="0 0 100 100" className="circle-svg">
        <defs>
          <path
            id="circlePath"
            d="M 50, 50 m -37, 0 a 37,37 0 1,1 74,0 a 37,37 0 1,1 -74,0"
          />
        </defs>
        <text className="circle-text" style={{ fill: textColor }}>
          <textPath href="#circlePath">
            {text}
          </textPath>
        </text>
      </svg>
      <div className="center-icon">
        <LiaStarOfLifeSolid color={textColor} size={20}/>
      </div>
    </div>
  );
};

export default CircleTextAnimation;
