import React, { useEffect, useState } from 'react';
import './profiles.scss';
import { Box, HStack, Image, Text, useToast } from '@chakra-ui/react';
import ManageSections from '../ManageSections/ManageSections';
import QuickMenu from '../QuickMenu/QuickMenu';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { db } from '../../firebase.config';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { toastDefaultConfig } from '../../utils/constants';
import Footer from '../Footer/Footer';
import BasicTemplate from '../TemplatesFolder/BasicTemplate/BasicTemplate';
import AddMore from '../AddMore/AddMore';
import MonochromeVintage from '../TemplatesFolder/MonochromeVintage/MonochromeVintage';
import Gallery from '../TemplatesFolder/Gallery/Gallery';
import SolidBackground from '../TemplatesFolder/SolidBackground/SolidBackground';
import { getTextColor } from '../../helper/random.helper';
import OverlayPersona from '../TemplatesFolder/OverlayPersona/OverlayPersona';
import Logo from '../Logo/Logo';
import { FiClipboard } from 'react-icons/fi';
import ShareableDiv from '../../atoms/SharableDiv';
import { PiInfoDuotone } from 'react-icons/pi';
import { Tooltip } from 'react-tooltip';
import Bold from '../TemplatesFolder/Bold/Bold';

const templates = {
  "Basic": BasicTemplate,
  "Monochrome Vintage": MonochromeVintage,
  "Solid Background": SolidBackground,
  "Gallery": Gallery,
  "Overlay Persona": OverlayPersona,
  "Bold": Bold,
};

const RenderTemplate = ({ theme, UserData }) => {
  const TemplateComponent = templates[theme];
  if (TemplateComponent) {
    return <TemplateComponent UserData={UserData} />;
  }
  return null;
};


const Profile = () => {
  const { user } = useSelector(state => state.user);
  const { username } = useParams();
  const [UserData, setUserData] = useState();
  const { isLoggedIn, enableBackgroundColor } = useSelector(state => state.status);
  const [LoadingOtherUser, setLoadingOtherUser] = useState(false)
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.username !== username && username) {
      setLoadingOtherUser(true);
      const fetchUserData = async () => {
        if (!username) return;

        try {
          // Reference the users collection
          const usersCollection = collection(db, "users");

          // Create a query to find the user with the matching username
          const userQuery = query(usersCollection, where("username", "==", username));

          // Execute the query
          const querySnapshot = await getDocs(userQuery);

          // Process the query results
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              setUserData({ id: doc.id, ...doc.data() });
            });
          } else {
            toast({
              title: `Page not found.`,
              status: 'error',
              isClosable: false,
              ...toastDefaultConfig,
            });
            navigate("/");
          }
        } catch (error) {
          toast({
            title: `Error fetching data, please try again!`,
            status: 'error',
            isClosable: false,
            ...toastDefaultConfig,
          });
          navigate("/");
        } finally {
          setLoadingOtherUser(false);
        }
      };

      fetchUserData();
    } else if (user.username === username) {
      navigate("/");
    }

  }, [user, username])

  const theme = UserData?.theme?.[0] || user?.theme?.[0];


  if (LoadingOtherUser && isLoggedIn) {
    return <Box height="calc(100dvh - 100px)" display={"flex"} justifyContent={"center"} alignItems={"center"}>
    </Box>
  }

  if (!UserData?.theme?.[0] && !user?.theme?.[0]) {
    return (
      <Box height="calc(100dvh - 100px)" display={"flex"} justifyContent={"center"} alignItems={"center"}>
      </Box>
    );
  }


  return (
    <Box background={user?.theme?.[2] ? user?.theme?.[1] : UserData?.theme?.[2] ? UserData?.theme?.[1] : 'none'}>
      {user.username === username || !username && user.username && <QuickMenu />}
      <ManageSections />
      <Box maxWidth={"1000px"} margin={"auto"}>
        {["Basic", "Monochrome Vintage", "Solid Background"].includes(theme) && (
          <RenderTemplate theme={theme} UserData={UserData} />
        )}
      </Box>
      <Box maxWidth={"100%"} margin={"auto"}>
        {["Gallery", "Overlay Persona", "Bold"].includes(theme) && (
          <RenderTemplate theme={theme} UserData={UserData} />
        )}
      </Box>

      <AddMore />
      {user.username === username || !username &&
        <HStack position={"absolute"} bottom={0} justifyContent={"center"} spacing={1} w="full" px={1} py={2}>
          {/* <Text fontSize={{ base: "14px" }} display={"inline"}>Your sharable url</Text> */}
          <Tooltip
            style={{ textWrap: "wrap", maxWidth: "90vw", fontSize: "12px" }}
            anchorSelect=".info-username"
            content="You can change your username to reflect change in your link."
          />
          <HStack spacing={1} background={"#EFF2F9"} rounded={"4px"} p={"2px 5px"}>
            <PiInfoDuotone size={14} className="info-username" />
            <Text display={"inline"} fontWeight={700} fontSize={{ base: "14px" }}>
              <Text display={"inline"} fontWeight={500}>urlme.</Text>
              site<Text fontWeight={500} display={"inline"}>/{user?.username}</Text>
            </Text>
          </HStack>

        </HStack>}
      {
        user.username !== username && username &&
        <Text textAlign="center" fontSize="sm" pt={"20px"} fontWeight={"400"}
          color={(user?.theme?.[2] || UserData?.theme?.[2]) && getTextColor((user?.theme?.[1] || UserData?.theme?.[1])) ? "#fff" : "#000"}>
          Don't have an account? &nbsp;
          <Link to='/get-started?sign_up=true'>Sign up</Link>
        </Text>
      }
      {
        user.username !== username && username &&
        <Footer color={(user?.theme?.[2] || UserData?.theme?.[2]) && getTextColor(user?.theme?.[1] || UserData?.theme?.[1]) ? "#fff" : "#000"} />
      }
    </Box >
  );
}

export default Profile;
