import { Box, HStack, Image, Text } from '@chakra-ui/react'
import React from 'react';
import './styles.scss';
import { LiaStarOfLifeSolid } from 'react-icons/lia';

const Logo = ({ height = "45", fontSize = "34", hideText = false, color = "#000" }) => {
  return (
    <HStack position="relative" alignItems={"center"} spacing={0} w="min-content">
      <LiaStarOfLifeSolid size={height} color={color === "#fff" ? color : "#3C8065"} />
      {!hideText &&
        <Text color={color} display={"inline"} fontWeight={700} fontSize={{ base: fontSize - 3, sm: fontSize }}>
          <Text display={"inline"} fontWeight={500}>urlme.</Text>
          site</Text>
      }
    </HStack >
  )
}

export default Logo