import { FaFacebookF, FaInstagram, FaLinkedinIn, FaPinterestP, FaBehance, FaDribbble, FaRedditAlien, FaSnapchatGhost, FaTumblr, FaGithub, FaYoutube, FaLink, FaSpotify, FaVimeo, FaFlickr } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa";

// Function to get the icon and text based on the URL
export const getLinkDetails = (url, size) => {
  if (url.includes('twitter.com') || url.includes('x.com')) {
    return { icon: <FaXTwitter size={size} />, text: 'Twitter' };
  }
  if (url.includes('facebook.com')) {
    return { icon: <FaFacebookF size={size} />, text: 'Facebook' };
  }
  if (url.includes('linkedin.com')) {
    return { icon: <FaLinkedinIn size={size} />, text: 'LinkedIn' };
  }
  if (url.includes('pinterest.com')) {
    return { icon: <FaPinterestP size={size} />, text: 'Pinterest' };
  }
  if (url.includes('behance.net')) {
    return { icon: <FaBehance size={size} />, text: 'Behance' };
  }
  if (url.includes('dribbble.com')) {
    return { icon: <FaDribbble size={size} />, text: 'Dribbble' };
  }
  if (url.includes('instagram.com')) {
    return { icon: <FaInstagram size={size} />, text: 'Instagram' };
  }
  if (url.includes('tiktok.com')) {
    return { icon: <FaTiktok size={size} />, text: 'TikTok' };
  }
  if (url.includes('youtube.com')) {
    return { icon: <FaYoutube size={size} />, text: 'YouTube' };
  }
  if (url.includes('reddit.com')) {
    return { icon: <FaRedditAlien size={size} />, text: 'Reddit' };
  }
  if (url.includes('snapchat.com')) {
    return { icon: <FaSnapchatGhost size={size} />, text: 'Snapchat' };
  }
  if (url.includes('tumblr.com')) {
    return { icon: <FaTumblr size={size} />, text: 'Tumblr' };
  }
  if (url.includes('github.com')) {
    return { icon: <FaGithub size={size} />, text: 'GitHub' };
  }
  if (url.includes('spotify.com')) {
    return { icon: <FaSpotify size={size} />, text: 'Spotify' };
  }
  if (url.includes('vimeo.com')) {
    return { icon: <FaVimeo size={size} />, text: 'Vimeo' };
  }
  if (url.includes('flickr.com')) {
    return { icon: <FaFlickr size={size} />, text: 'Flickr' };
  }
  return { icon: <FaLink size={size - 2} />, text: 'Website' };
};



export const capitalizeAndTrim = (str) => {
  return str
    .trim()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)?.toLowerCase())
    .join(" ");
};

export const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

export const openLink = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};


// insert object for work experience in work array based on year
export function insertOrReplaceObject(
  arr = [],
  obj,
  index = null
) {
  // Helper function to parse 'years' range.
  const parseYears = (years) => {
    const [start, end] = years.split('-').map((date) => new Date(`${date} 1`));
    return { start, end };
  };

  // Helper function to parse 'year' (YYYY format).
  const parseYear = (year) => new Date(`${year} 1`);

  // Determine if the object has a 'years' or 'year' key.
  const isYearsFormat = obj.hasOwnProperty('years');

  // Get the new start date based on the object type.
  const newStart = isYearsFormat
    ? parseYears(obj.years).start
    : parseYear(obj.year);

  // Replace the object at the given index if provided.
  if (index !== null && index >= 0 && index < arr.length) {
    const newArr = [...arr];
    newArr[index] = obj;
    return newArr;
  }

  // If no index is provided, find the correct position for insertion.
  const newArr = [...arr];
  let inserted = false;

  for (let i = 0; i < newArr.length; i++) {
    const existingStart = newArr[i].hasOwnProperty('years')
      ? parseYears(newArr[i].years).start
      : parseYear(newArr[i].year);

    // Insert the new object if its start date is more recent or equal.
    if (newStart >= existingStart) {
      newArr.splice(i, 0, obj);
      inserted = true;
      break;
    }
  }

  // If not inserted in the loop, add the object to the end.
  if (!inserted) {
    newArr.push(obj);
  }

  return newArr;
}

export function extractDomain(link) {
  // Remove protocols (http://, https://) and 'www.' if present
  const cleanLink = link?.replace(/^(https?:\/\/)?(www\.)?/, '');

  // Extract only the domain part (before any path, query, or fragment)
  return cleanLink?.split('/')[0].split('?')[0].split('#')[0];
}

export function getFileNameFromUrl(url) {
  // Extract the part after 'o/' which contains the file path
  const startIndex = url.indexOf('o/') + 2; // 2 because we want the part after 'o/'
  const endIndex = url.indexOf('?'); // Find the start of query parameters
  const filePath = url.substring(startIndex, endIndex);

  // Decode the encoded characters (like %2F for '/')
  const decodedFilePath = decodeURIComponent(filePath);

  // Split the path by '/' and return the last part (the file name)
  const parts = decodedFilePath.split('/');
  const fileName = parts[parts.length - 1];

  return fileName;
}


// function to make string in to title case
export function titleCase(str) {
  if (!str) return '';
  return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1)?.toLowerCase(); });
}

export function getTextColor(hex) {
  const { r, g, b } = hexToRgb(hex);
  const lum = luminance(r, g, b);
  return lum > 0.4 ? 0 : 1; // Adjusted threshold
}

// Helper function to convert hex to RGB
function hexToRgb(hex) {
  const bigint = parseInt(hex?.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}

// Helper function to calculate luminance
function luminance(r, g, b) {
  const a = [r, g, b].map(v => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2]; // Perceived luminance
}