import React, { useState, useEffect } from 'react'
import Header from '../../components/Header/Header';
import "./styles.scss";
import {
  Box,
  Button, Card, CardBody, Flex, Grid, HStack, Image, SimpleGrid, Text, VStack, useToast
} from '@chakra-ui/react';
import { MdArrowOutward, MdOutlineDesignServices } from "react-icons/md";
import Footer from '../../components/Footer/Footer';
import { useForm } from 'react-hook-form';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase.config';
import { Link, useNavigate } from 'react-router-dom';
import { RiLayout2Fill, RiSketching } from "react-icons/ri";
import { PiArrowBendRightUpBold, PiCursorClickFill, PiScribbleLoopBold, PiSmileyBold, PiStarFourFill } from 'react-icons/pi';
import { BiSolidCustomize } from 'react-icons/bi';
import Person from '../../assets/person.jpeg';
import Person2 from '../../assets/person2.jpeg';
import Person3 from '../../assets/person3.jpeg';
import Person4 from '../../assets/person4.jpeg';
import { FiArrowDownLeft, FiArrowRight } from 'react-icons/fi';
import { BsHeartFill, BsPostcardHeart } from 'react-icons/bs';
import { color, motion, useCycle } from 'framer-motion';
import { FaBehance, FaDribbble, FaGithubAlt, FaInstagram, FaLaptop, FaPinterest, FaSoundcloud, FaSpotify, FaTiktok } from 'react-icons/fa';
import { CgShapeZigzag } from 'react-icons/cg';
import { TbScribble } from "react-icons/tb";
import { FaLinkedin, FaMedium } from 'react-icons/fa6';
import Logo from '../../components/Logo/Logo';
import FooterBig from '../../components/Footer/FooterBig';
import BasicImage from '../../assets/templates/Basic.png'
import MonochromVintageImage from '../../assets/templates/Monochrome Vintage.png'
import GalleryImage from '../../assets/templates/Gallery.png'
import SolidBackgroudImage from '../../assets/templates/Solid Background.png'
import { HexColorPicker } from 'react-colorful';
import { getTextColor } from '../../helper/random.helper';

const FlipCard = ({ Elem1, Elem2, duration }) => {
  const [flipped, cycleFlipped] = useCycle(false, true);

  useEffect(() => {
    const interval = setInterval(() => {
      cycleFlipped();
    }, duration);

    return () => clearInterval(interval);
  }, [cycleFlipped]);

  return (
    <Box
      as={motion.div}
      className="flip-card"
      animate={{ rotateY: flipped ? 180 : 0 }}
      transition={{ duration: 0.8, ease: 'easeInOut' }}
      w={{ base: "160px", sm: "200px", md: "150px", lg: "220px" }}
      h={{ base: "160px", sm: "200px", md: "150px", lg: "220px" }}
      style={{
        perspective: '1000px',
        cursor: 'pointer',
      }}
    >
      <Box
        className="flip-card-inner"
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          textAlign: 'center',
          transformStyle: 'preserve-3d',
        }}
      >
        <Box
          as={motion.div}
          className="flip-card-front"
          initial={{ rotateY: 0 }}
          animate={{ rotateY: flipped ? 180 : 0 }}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backfaceVisibility: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
          }}
        >
          {Elem1}
        </Box>
        <Box
          as={motion.div}
          className="flip-card-back"
          initial={{ rotateY: 180 }}
          animate={{ rotateY: flipped ? 0 : -180 }}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backfaceVisibility: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            transform: 'rotateY(180deg)',
          }}
        >
          {Elem2}
        </Box>
      </Box>
    </Box>
  );
};

const MainLanding = () => {
  const [Color, setColor] = useState("#D5FC49");
  const [FormSubmitMessage, setFormSubmitMessage] = useState('');
  const {
    register,
    values,
    setValues,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (value) => {
    try {
      // Add the email to the "subscribe" collection
      const emailDocRef = doc(db, 'subscribe', value.email);
      await setDoc(emailDocRef, { email: value.email });

      // Clear the form and show success message
      reset();
      setFormSubmitMessage('We have added you to our list!')
    } catch (error) {
      setFormSubmitMessage('Something went wrong! Please try again.')
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <VStack backgroundColor="#F6F8ED" minWidth={"360px"} spacing={0} className="main_wrapper">
      <VStack position={"relative"} p={"50px 0"} width="100%">
        <Header type="public" />
        <HStack zIndex={1} spacing={10} height={{ base: "auto", md: "calc(100vh - 100px)" }} pt="40px" wrap={{ base: 'wrap', md: 'nowrap' }} mt={{ base: "50px", md: 0 }} maxWidth={"1000px"} justifyContent={"space-around"} mx={{ base: "10px", md: "30px" }} >
          <VStack maxWidth={{ base: '100%', md: "50%" }} alignItems={"left"} spacing={5}>
            <Text zIndex={1} className="font_poppins" textAlign="left" lineHeight={1.2} fontWeight={900} fontSize={{ base: "3xl", sm: '4xl', md: '4xl', lg: "5xl" }}>
              Create your online portfolio with just a simple click</Text>
            <Text zIndex={1} textAlign="left" fontSize={{ base: "lg", sm: "lg" }}>
              Convert your traditional resume into an engaging, interactive
              portfolio that you can share with others across multiple platforms.</Text>
            <HStack zIndex={1} wrap={"wrap"} mt="20px" spacing={"20px"} justifyContent={"left"} alignItems={"center"}>
              <div className='btn_with_arrow'>
                <Button colorScheme='brand' size='lg' onClick={() => navigate('/get-started')}>
                  Get Started&nbsp;<MdArrowOutward size={"25px"} id="arrow" />
                </Button>
              </div>
              <a href="https://www.producthunt.com/posts/urlme-site?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-urlme&#0045;site" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=500878&theme=light" alt="urlme&#0046;site - Create&#0032;your&#0032;portfolio&#0032;site | Product Hunt" styles={{ width: "250px", height: "54px" }} width="250" height="54" /></a>
            </HStack>
          </VStack>
          <SimpleGrid gap="5px" gridTemplateColumns={"auto auto"} minWidth="300px">
            <FlipCard
              duration={2500}
              Elem1={<RiLayout2Fill size={"100%"} color={"#9B70DC"} />}
              Elem2={<Image objectFit={"cover"} borderRadius={"5px 100px 5px 5px"} src={Person3} alt="person" />} />
            <FlipCard
              duration={5000}
              Elem1={<PiCursorClickFill size={"80%"} color={"#0596C2"} />}
              Elem2={<Image objectFit={"cover"} borderRadius={"100px 5px 5px 5px"} src={Person4} alt="person" />} />
            <FlipCard
              duration={7500}
              Elem1={<PiStarFourFill size={"100%"} color={"#FBE601"} />}
              Elem2={<Image objectFit={"cover"} borderRadius={"5px 5px 100px 5px"} src={Person} alt="person" />} />
            <FlipCard
              duration={10000}
              Elem1={<Image objectFit={"cover"} borderRadius={"5px 5px 100px 5px"} src={Person2} alt="person" />}
              Elem2={<BsHeartFill size={"80%"} color={"#F14E3D"} />} />
          </SimpleGrid>
        </HStack>
      </VStack>

      <VStack minHeight={{ base: "100vh", md: "calc(100vh)" }} p={"50px 10px"} position="relative" justifyContent={"center"} w="100%" background={"#3c8065"}>
        <Box position={"absolute"} bottom={0} right={0} height={{ base: "200px", md: "30vh" }} zIndex={0}>
          <PiSmileyBold size={"100%"} color={"#fff"} />
        </Box>
        <Text zIndex={2} lineHeight={1} fontSize={{ base: "5xl", sm: "7xl" }} fontWeight={900} color="#F95B04" className={"font_gloria"} position={"absolute"} transform={"rotate(-15deg)"}>
          let's make<br />it stand out
        </Text>
        <Text zIndex={1} w={{ base: "100%", md: "80%", lg: "65%" }} lineHeight={0.8} color="#1A1818" fontSize={{ base: "90px", sm: "120px", md: "140px" }} fontWeight={"900"} pb="10px" textAlign={"center"}>
          YOUR<br />SKILLS<br />YOUR<br />BRAND
        </Text>
      </VStack>

      <VStack justifyContent={"center"} minHeight={{ base: "100vh", md: "calc(100vh)" }} alignItems={"flex-start"} w="100%" p={"50px 0"} background={"#000000"}>
        <HStack>
          <Text py={2} px={"20px"} textAlign={"center"} fontSize={{ base: "4xl", md: "6xl" }} fontWeight={"700"} rounded={"full"} background={"#FBE601"}>
            #Easy Steps
          </Text>
          <VStack justifyContent={"center"} alignItems={"center"} fontSize={{ base: "4xl", md: "6xl" }} fontWeight={"700"} rounded={"full"} py={2} px={"10px"} background={"#FEFEFE"}>
            <FiArrowDownLeft color={"#000"} />
          </VStack>
        </HStack>
        <HStack wrap="wrap">
          <Text py={2} px={"20px"} textAlign={"center"} fontSize={{ base: "4xl", md: "6xl" }} fontWeight={"700"} rounded={"full"} background={"#0596C2"}>
            Upload Resume
          </Text>
          <VStack justifyContent={"center"} alignItems={"center"} fontSize={{ base: "4xl", md: "6xl" }} fontWeight={"700"} rounded={"full"} py={3} px={3} background={"#F14E3D"}>
            <BsPostcardHeart color={"#fff"} />
          </VStack>
          <Text py={2} px={"20px"} textAlign={"center"} fontSize={{ base: "4xl", md: "6xl" }} fontWeight={"700"} rounded={"full"} background={"#E57B01"}>
            Choose Template
          </Text>
          <VStack justifyContent={"center"} alignItems={"center"} fontSize={{ base: "4xl", md: "6xl" }} fontWeight={"700"} rounded={"full"} py={2} px={2} background={"#FEFEFE"}>
            <FiArrowRight color={"#000"} />
          </VStack>
          <Text py={2} px={"20px"} textAlign={"center"} fontSize={{ base: "4xl", md: "6xl" }} fontWeight={"700"} rounded={"full"} background={"#9B70DC"}>
            Share with others
          </Text>
          <Text py={2} px={4} textAlign={"center"} fontSize={{ base: "4xl", md: "6xl" }} fontWeight={"700"} rounded={"full"} background={"#fff"}>
            🎉
          </Text>
        </HStack>
      </VStack>

      <VStack mx={"10px"} minH="100vh" justifyContent={"center"} spacing={10} py="50px">
        <Text fontSize={{ base: "3xl", md: "5xl" }} fontWeight={900}>One Page Infinite Limits</Text>
        <HStack maxW={"1000px"} wrap={{ base: "wrap" }} justifyContent={"center"} alignItems={"stretch"} spacing={5}>
          <Card rounded={"2xl"} shadow={"none"} minW={"300px"} maxW={{ base: "100%", md: "320px" }} >
            <CardBody background={"#FBE601"} rounded={"2xl"}>
              <Text lineHeight={1.2} mb={"20px"} fontSize={{ base: "3xl", md: "3xl", lg: "5xl" }} fontWeight={900}>Everything you do</Text>
              <Text fontSize={"lg"}>Showcase your skills, build a portfolio, and attract opportunities—all in one place </Text>
              <MdOutlineDesignServices size={"80px"} />
            </CardBody>
          </Card>
          <Card background={"#0596C2"} rounded={"2xl"} shadow={"none"} minW={"300px"} maxW={{ base: "100%", md: "320px" }} >
            <CardBody rounded={"2xl"}>
              <Text lineHeight={1.2} mb={"20px"} fontSize={{ base: "3xl", md: "3xl", lg: "5xl" }} fontWeight={900}>Add links</Text>
              <Text fontSize={"lg"}>All your important links in one place for easy sharing and connecting with others.</Text>
              <HStack mt={"10px"} spacing={3} wrap={"wrap"}>
                <Box p="10px" w="40px" h="40px" rounded={"full"} background={"#fff"}>
                  <FaGithubAlt size={"100%"} />
                </Box>
                <Box p="10px" w="40px" h="40px" rounded={"full"} background={"#fff"}>
                  <FaBehance size={"100%"} />
                </Box>
                <Box p="10px" w="40px" h="40px" rounded={"full"} background={"#fff"}>
                  <FaDribbble size={"100%"} />
                </Box>
                <Box p="10px" w="40px" h="40px" rounded={"full"} background={"#fff"}>
                  <FaMedium size={"100%"} />
                </Box>
                <Box p="10px" w="40px" h="40px" rounded={"full"} background={"#fff"}>
                  <FaLinkedin size={"100%"} />
                </Box>
                <Box p="10px" w="40px" h="40px" rounded={"full"} background={"#fff"}>
                  <FaInstagram size={"100%"} />
                </Box>
                <Box p="10px" w="40px" h="40px" rounded={"full"} background={"#fff"}>
                  <FaSpotify size={"100%"} />
                </Box>
                <Box p="10px" w="40px" h="40px" rounded={"full"} background={"#fff"}>
                  <FaTiktok size={"100%"} />
                </Box>
                <Box p="10px" w="40px" h="40px" rounded={"full"} background={"#fff"}>
                  <FaSoundcloud size={"100%"} />
                </Box>
                <Box p="10px" w="40px" h="40px" rounded={"full"} background={"#fff"}>
                  <FaPinterest size={"100%"} />
                </Box>
              </HStack>
            </CardBody>
          </Card>
          <Card background={"#E57B01"} rounded={"2xl"} shadow={"none"} minW={"300px"} maxW={{ base: "100%", md: "320px" }} >
            <CardBody rounded={"2xl"}>
              <Text lineHeight={1.2} mb={"20px"} fontSize={{ base: "3xl", md: "3xl", lg: "5xl" }} fontWeight={900}>Customize</Text>
              <Text fontSize={"lg"}>Customize your portfolio to reflect your personality and make a lasting impression.</Text>
              <BiSolidCustomize size={"100px"} style={{ marginLeft: "-10px" }} color="#fff" />
            </CardBody>
          </Card>
        </HStack >
      </VStack >
      <Flex
        maxW={{ base: '100%', lg: '1000px' }}
        direction={{ base: 'column', lg: 'row' }}
        minH="100vh"
        justifyContent="center"
        alignItems="center"
        py="50px"
        mx={{ base: "10px", md: "30px" }}
      >
        <VStack
          alignItems="flex-start"
          w={{ base: '100%', lg: '50%' }}
          mb={{ base: 8, lg: 0 }}
          h={"fit-content"}
          zIndex={100}
        >
          <Text fontSize={{ base: "3xl", md: "5xl" }} fontWeight={900} mb={4}>
            Find Your Perfect Style
          </Text>
          <Text fontSize={{ base: "md", md: "lg" }}>
            Make your profile stand out effortlessly with our beautifully designed templates. Whether you're a creative, tech enthusiast, or professional, you'll find a style that highlights your unique skills. Choose a template that fits your goals and make a lasting impression in just a few clicks.
          </Text>
        </VStack>
        <Box h={{ base: "400px", md: "500px" }} w={{ base: "fit-content", lg: '50%' }} position={"relative"}>
          <Image src={"./templates.png"} h="full" objectFit={"contain"} />
        </Box>
      </Flex>
      <VStack w="full" background={Color} minHeight={{ base: "100vh", md: "calc(100vh)" }} p={"50px 10px 20px 10px"} justifyContent={"flex-end"} spacing={10} alignItems={"space-between"}>
        <Text fontSize={{ base: "7xl", md: "8xl", lg: "9xl" }} lineHeight={1} textAlign="center" fontWeight={900}
          color={getTextColor(Color) ? "#fff" : "#000"}>Not Just Color Pick Your Vibe</Text>
        <HexColorPicker color={Color} onChange={setColor} />
      </VStack>
      <VStack mx={{ base: "10px", md: "30px" }} minW={{ base: "auto", md: "740px", lg: "900px" }} position={"relative"}
        minH="100vh" justifyContent={"center"}>
        <VStack maxW="700px" background={"#003334"} borderRadius={{ base: "50px 50px 50px 50px", md: "100px 100px 100px 100px" }} color="#fff" justifyContent={"center"} alignItems={"center"} p={{ base: "50px 30px", md: "50px" }} spacing={4}>
          <Text zIndex={1} w="full" textAlign={"left"} fontSize={{ base: "2xl", md: "4xl" }} fontWeight={900}>Who is urlme.site for?</Text>
          <Text zIndex={1} fontSize={{ base: "lg", md: "xl" }} fontWeight={500}>urlme.site is for anyone seeking a simple way to build a web presence. Easily convert your resume into a personalized portfolio website by selecting from customizable templates. Showcase your work online in a way that truly reflects you, ready to share with anyone, anywhere.</Text>
          <Box position={"absolute"} mt="-350px" ml={{ base: "-200px", md: "-400px" }} zIndex={0}>
            <PiScribbleLoopBold size={"200px"} color={"#EC5E2B"} />
          </Box>
          <Box position={"absolute"} mb="-350px" mr={{ base: "-150px", md: "-400px" }} zIndex={0}>
            <TbScribble size={"200px"} color={"#FFA700"} />
          </Box>
        </VStack>
      </VStack>
      <FooterBig />
    </VStack >
  )
}

export default MainLanding;