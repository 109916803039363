import { Avatar, Text, IconButton, Flex, useDisclosure, Badge } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PiPencilCircleDuotone } from "react-icons/pi";
import UserCardEditable from '../../../EditSections/UserCardEditable';
import { titleCase } from '../../../../helper/random.helper';
import { userstatus } from '../../../../utils/constants';
import BioCard from '../Bio/BioCard';
import UserInfo from './UserInfo';

const UserCard = ({ extUser }) => {
  const { user: currentUser } = useSelector(state => state.user);
  const [user, setUser] = useState();
  useEffect(() => {
    if (extUser) {
      setUser(extUser);
    } else {
      setUser(currentUser);
    }
  }, [extUser, currentUser]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    user?.username && (
      <>
        <Flex direction="column" justifyContent="center" gap={3}>
          <Flex direction={{ base: 'column', md: 'row' }} justifyContent="center" alignItems={"stretch"} gap={3}>
            <Flex
              minWidth={["100%", "200px"]}
              p={3}
              height={"min-content"}
              border="2px solid"
              borderColor="brand.black"
              borderRadius="25px"
              position="relative" direction="column" alignItems="center" gap={3}>
              <Avatar
                size="2xl"
                name={titleCase(user?.fullname)}
                src={user?.DPUrl}
                background={'transparent'}
                border="2px solid"
                borderColor="gray.200"
                objectPosition={"center top"}
              />
              <Badge
                backgroundColor={userstatus[user?.availability]?.backgroundColor}
                borderRadius="full"
                px={3}
                py={1}
                fontSize="xs"
                color={userstatus[user?.availability]?.textColor}
                position="absolute"
                top={"115px"}
                border="2px solid"
                borderColor="brand.black"
              >
                {user?.availability}
              </Badge>
              <Text fontSize="lg" fontWeight="500" textAlign="center">
                {titleCase(user?.fullname)}
              </Text>
              <UserInfo user={user} display={{ base: 'flex', md: 'none' }} />
              {!extUser ? (
                <IconButton
                  icon={<PiPencilCircleDuotone size={"18px"} />}
                  position="absolute"
                  left={0}
                  top={0}
                  onClick={onOpen}
                  aria-label="Edit User"
                  size="sm"
                  variant="ghost"
                />
              ) : null}
            </Flex>
            <BioCard extUser={extUser} />
          </Flex>
          <UserCardEditable isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
          <UserInfo user={user} display={{ base: 'none', md: 'flex' }} />
        </Flex >
      </>
    )
  );
};

export default UserCard;
