import React, { memo } from 'react';
import {
  Box,
  Tag,
  TagLabel,
  Wrap,
  WrapItem,
  Text,
  HStack,
} from '@chakra-ui/react';
import { PiPencilCircleDuotone, PiPlusCircleDuotone } from 'react-icons/pi';
import CoreSkillsEdit from '../../../EditSections/editCoreSkills/CoreSkillsEdit';
import useEditCoreSkills from '../../../EditSections/editCoreSkills/useEditCoreSkills';

const SkillTags = ({ extUser }) => {
  const editCoreSkills = useEditCoreSkills({ extUser });

  const {
    user,
    onOpen,
  } = editCoreSkills;

  return (
    <>
      {user?.core_skills?.length ? <Box
        width={{ base: '100%', md: '300px' }}
        p={'0px'}
        pl={{ base: 0, md: 3 }}
        position={'relative'}
        height={'fit-content'}
      >
        <HStack justify={"space-between"}>
          <Text className='font_cursive' fontSize={"4xl"} color={user?.theme[1]} fontWeight={'500'}>Core<br />Skills.</Text>
          {!extUser && <Box cursor={'pointer'} onClick={onOpen}>
            <PiPlusCircleDuotone size="18px" />
          </Box>}
        </HStack>
        <Wrap spacing={2} mt={'10px'}>
          {user?.core_skills?.map((item, index) => (
            <WrapItem key={index}>
              <Tag
                size="md"
                borderRadius="0"
                px={3}
                py={1}
                fontWeight={400}
                backgroundColor="transparent"
                border="2px solid"
                borderColor={user?.theme[1]}
              >
                <TagLabel color={user?.theme[1]} fontSize="sm" fontWeight={500}>{item}</TagLabel>
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
      </Box> : null}
      <CoreSkillsEdit {...editCoreSkills} />
    </>
  );
};

export default memo(SkillTags); // Memoize the SkillTags component to prevent unnecessary re-renders
