import React, { useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { PiPencilCircleDuotone } from 'react-icons/pi';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { toggleBio } from '../../../../redux/slices/sectionEditSlice';
import BioCardEditable from '../../../EditSections/BioEditable';

const BioCard = ({ extUser }) => {
  const dispatch = useDispatch();

  const { user: currentUser } = useSelector(state => state.user);
  const [user, setUser] = useState();

  useEffect(() => {
    if (extUser) {
      setUser(extUser);
    } else {
      setUser(currentUser);
    }
  }, [extUser, currentUser])

  if (!user?.bio) {
    return null;
  }

  return (
    <>
      <Box border="2px solid" borderColor="brand.black" borderRadius="25px" position={"relative"}
        display={"flex"} flexDirection={"column"} justifyContent={"center"} gap={"10px"} w="100%">
        {!extUser && <Box cursor={'pointer'} position={'absolute'} top={"10px"} right={"10px"} onClick={() => dispatch(toggleBio())}>
          <PiPencilCircleDuotone size="18px" />
        </Box>}
        <Box p={'20px'} display={'flex'} flexDirection={'column'} gap={'10px'}>
          <Text fontSize={"xl"} fontWeight={'500'}>Introduction</Text>
          <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
            {user?.bio}
          </Markdown>
        </Box>
      </Box>
      <BioCardEditable extUser={extUser} />
    </>
  )
}

export default BioCard