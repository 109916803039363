import React from 'react';
import { Flex, Tag, TagLeftIcon, IconButton } from '@chakra-ui/react';
import { LuShare2, LuMail } from 'react-icons/lu';
import { TbTools } from 'react-icons/tb';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { BsHourglassSplit } from 'react-icons/bs';
import { getLinkDetails, getTextColor, openLink } from '../../../../helper/random.helper';
import ShareableDiv from '../../../../atoms/SharableDiv';

const UserInfo = ({ user, display }) => {
  return (
    <Flex
      borderBottom={"2px solid"}
      paddingBottom={3}
      borderColor={user?.theme[1]}
      display={display} gap={3} direction={'row'} alignItems="center" wrap="wrap" justifyContent="center">

      {!user?.private_email ?
        <Tag
          size="md"
          onClick={() => {
            window.location.href = `mailto:${user?.email}`;
          }}
          borderRadius={0}
          border="2px solid"
          borderColor={user?.theme[1]}
          px={3}
          py={1}
          cursor={"pointer"}
          backgroundColor="transparent"
          color={user?.theme[1]}
        >
          <TagLeftIcon as={LuMail} color={user?.theme[1]} />
          {user?.email}
        </Tag> : null}
      <Tag
        size="md"
        borderRadius={0}
        px={3}
        py={1}
        background={user?.theme[1]}
        color={getTextColor(user?.theme[1]) ? 'brand.white' : 'brand.black'}
      >
        <TagLeftIcon as={TbTools} />
        {user?.role}
      </Tag>

      {user?.location ? (
        <Tag
          size="md"
          borderRadius={0}
          border="2px solid"
          borderColor={user?.theme[1]}
          px={3}
          py={1}
          backgroundColor="transparent"
          color={user?.theme[1]}
        >
          <TagLeftIcon as={HiOutlineLocationMarker} color={user?.theme[1]} />
          {user?.location}
        </Tag>
      ) : null}
      {user?.experience ? (
        <Tag
          size="md"
          borderRadius={0}
          border="2px solid"
          borderColor={user?.theme[1]}
          px={3}
          py={1}
          backgroundColor="transparent"
          color={user?.theme[1]}
        >
          <TagLeftIcon as={BsHourglassSplit} color={user?.theme[1]} />
          {user?.experience} years
        </Tag>
      ) : null}
      {user?.links?.map((item, index) => {
        const { icon } = getLinkDetails(item, 14);
        return (
          <IconButton
            key={index}
            icon={icon}
            onClick={() => openLink(item)}
            aria-label={`link-${index}`}
            size="sm"
            p={0}
            borderRadius={0}
            color={user?.theme[1]}
            border="2px solid"
            borderColor={user?.theme[1]}
            variant="outline"
          />
        );
      })}
    </Flex>
  )
}

export default UserInfo