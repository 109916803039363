import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import MainLanding from "./pages/MainLanding/MainLanding";
import Landing from "./pages/Landing/Landing";
import Home from "./pages/Home/Home";
import { useEffect, useState } from "react";
import Profile from "./components/Profile/Profile";
import { Information } from "./pages/SiteMisc/Information";


function App() {

  const { user } = useSelector(state => state.user);
  const [routesArr, setroutesArr] = useState([]);
  const { isLoggedIn } = useSelector(state => state.status);

  useEffect(() => {
    setroutesArr([
      { path: "/", element: isLoggedIn ? <Home /> : <MainLanding />, isAuthRequired: isLoggedIn },
      { path: "/get-started", element: <Landing />, isAuthRequired: false },
      { path: `/:username`, element: <Profile />, isAuthRequired: isLoggedIn },
      { path: `/learn/:infotype`, element: <Information />, isAuthRequired: isLoggedIn },
      { path: "*", element: <Navigate to="/" /> }
    ])
  }, [isLoggedIn])


  return (
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        {routesArr.map((item) => {
          if (item.isAuthRequired) {
            return (<Route
              path={item.path}
              key={item.path}
              element={
                <PrivateRoute>
                  {item.element}
                </PrivateRoute>
              }
            />)
          } else {
            return (
              <Route path={item.path}
                key={item.path}
                element={
                  <PublicRoute>
                    {item.element}
                  </PublicRoute>}
              />)
          }
        })}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
