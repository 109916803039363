import { Avatar, Text, IconButton, Flex, useDisclosure, Badge, HStack, Image, Box, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PiPencilCircleDuotone } from "react-icons/pi";
import UserCardEditable from '../../../EditSections/UserCardEditable';
import { titleCase } from '../../../../helper/random.helper';
import { userstatus } from '../../../../utils/constants';
import BioCard from '../Bio/BioCard';
import UserInfo from './UserInfo';

const UserCard = ({ extUser }) => {
  const { user: currentUser } = useSelector(state => state.user);
  const [user, setUser] = useState();
  useEffect(() => {
    if (extUser) {
      setUser(extUser);
    } else {
      setUser(currentUser);
    }
  }, [extUser, currentUser]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    user?.username && (
      <>
        <Flex direction="column" gap={3}>
          <VStack
            width="fit-content"
            position={"relative"}
            justifyContent="flex-start"
            height={{ base: "calc(100vh - 80px)", md: "calc(100vh-80)" }}
            spacing={3} >
            <Text zIndex={9} mt={{ base: "20px", md: "40px", xl: "80px" }} className="font_cormorant" lineHeight={1} fontSize={{ base: "40px", sm: "60px", md: "100px", lg: '120px' }} textAlign={"center"} fontWeight="500" color={'brand.black'} wordBreak={"break-word"} >
              {titleCase(user?.fullname)}.
            </Text>
            <Flex direction={{ base: 'column', md: 'row' }} justifyContent="center" gap={3} zIndex={1}
              w={{ base: "100%", md: "90%", lg: "80%", xl: "70%" }}>
              <BioCard extUser={extUser} />
            </Flex>
            <Flex
              position={"absolute"}
              bottom={50}
              direction="column"
              justifyContent={"flex-start"}
              right={{ base: 0, md: 20 }}
              zIndex={0}
              minWidth={"150px"} alignItems="center" gap={3}>
              {!extUser && (
                <IconButton
                  icon={<PiPencilCircleDuotone size={"18px"} />}
                  position="absolute"
                  left={"-70px"}
                  bottom={"-30px"}
                  rounded="full"
                  onClick={onOpen}
                  aria-label="Edit User"
                  cursor={"pointer"}
                  size={'xs'}
                  variant="ghost"
                />
              )}
              {user?.DPUrl ?
                <Image
                  name={titleCase(user?.fullname)}
                  src={user?.DPUrl}
                  background={'transparent'}
                  objectFit={"cover"}
                  width={"300px"}
                  maxHeight={"60%"}
                  objectPosition={"center top"}
                /> : null}

              <Badge
                px={3}
                py={1}
                color={'brand.black'}
                position="absolute"
                background={"transparent"}
                bottom={{ base: "-30px", md: "-40px" }}
                className='font_playfair'
                fontSize={{ base: 'lg', md: '2xl', }}
              >
                {user?.availability}
              </Badge>
            </Flex>
            <UserInfo user={user} display={"flex"} />
          </VStack >
          <UserCardEditable isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
        </Flex >
      </>
    )
  );
};

export default UserCard;
