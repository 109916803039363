import React, { useEffect, useState } from 'react';
import './addmore.scss';
import { Box, Card, CardBody, FormControl, FormLabel, Input, Switch, Text, Textarea, VStack, useDisclosure, useToast } from '@chakra-ui/react';
import { FiArrowRightCircle } from "react-icons/fi";
import EditModal from '../../atoms/EditModal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ErrorBox from '../../atoms/ErrorBox';
import { toastDefaultConfig, userDetailsPrompt } from '../../utils/constants';
import { login } from '../../redux/slices/userSlice';
import { doc, updateDoc } from 'firebase/firestore';
import { db, model } from '../../firebase.config';
import { FaRegCheckCircle } from "react-icons/fa";
import { useDropzone } from 'react-dropzone';
import { HiOutlineDocumentText } from "react-icons/hi2";
import { mergeProfiles } from '../../helper/mergeProfile';
import { toggleResume } from '../../redux/slices/sectionEditSlice';


const AddMore = () => {
  const [AcceptedFile, setAcceptedFile] = useState();
  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: { "application/pdf": [] },
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024, //10MB
    onDrop: (acceptedFile) => {
      setAcceptedFile(acceptedFile[0]);
    },
  });
  const [Overwrite, setOverwrite] = useState(true);
  const { user } = useSelector(state => state.user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [Bio1Resume2, setBio1Resume2] = useState(0);
  const [IsSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();
  const tinyCard = [
    { type: 'resume', description: 'Upload resume' },
    { type: 'bio', description: 'Share your bio' }
  ];
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: {
      bio: user?.bio,
    },
  });

  const { upload_resume } = useSelector((state) => state.sectionEdit);
  useEffect(() => {
    if (upload_resume) {
      onOpen();
      setBio1Resume2(2)
    } else {
      handleClose();
    }
  }, [upload_resume])

  const handleClose = () => {
    onClose();
    if (upload_resume) {
      dispatch(toggleResume());
    }
  }

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    if (Bio1Resume2 === 1) {
      try {

        if (!user) {
          console.error("User is not authenticated.");
          return;
        }

        // Update the user's document in Firestore with the new data
        const userDocRef = doc(db, "users", user?.uid);
        await updateDoc(userDocRef, data);

        // Dispatch the updated user data to the store
        dispatch(login({ ...user, ...data }));
        handleClose(); // Close the modal or form after submission
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
        toast({
          title: `Something went wrong! Please try again.`,
          status: 'error',
          isClosable: false,
          ...toastDefaultConfig,
        });
      }

    } else {
      PDF2JSON();
    }
  }
  const tinyCardClick = (type) => {
    switch (type) {
      case 'bio': setBio1Resume2(1);
        break;
      case 'resume': setBio1Resume2(2);
        break;
    }
    onOpen()
  }


  async function fileToGenerativePart(file) {
    const base64EncodedDataPromise = new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.readAsDataURL(file);
    });
    return {
      inlineData: { data: await base64EncodedDataPromise, mimeType: file.type },
    };
  }

  const PDF2JSON = async () => {


    // Prepare images for input
    const PDFParts = await fileToGenerativePart(AcceptedFile);

    // To generate text output, call generateContent with the text and images
    const result = await model.generateContent([userDetailsPrompt, PDFParts]).then(async (res) => {
      return await model.generateContent(`Using the JSON provided below, create a new JSON with the following keys: achievements, bio, contact, education, experience, links, other_project, soft_skills, core_skills, and work.

      1. **Improve grammar**: Ensure each field is grammatically correct and written in coherent English.
      2. **Fill missing data**: For any fields that are missing or incomplete, provide data that would commonly be expected for someone in the role of ${user?.role} with given years of experience.
      3. **Align content to role**: Ensure that the technical skills, work experience, and other fields align with the user's role, while maintaining accuracy and increasing employability.
      4. **Avoid duplicate data**: Do not introduce redundancy in the content.
      5. **Maintain key details**: Do not change existing data related to company names, project titles, or educational institutions. Only generate new data where necessary.
      6. **Ensure quality**: Generated data should look professional, avoid spammy content, and present the user in the best light possible for job applications.
      7. **experience key**: must contain only integer or decimal value, denoting total years of experience the user has.
      8. **Ensure listed description**: Ensure that all descriptions are listed and formatted in HTML.
      
      Here is the JSON to refine and extend: ${res.response.text()}.
      Generate a valid JSON object while ensuring correct syntax. Make sure there are no missing commas, unmatched braces, or trailing commas.`);
    });

    const response = result.response;
    const text = response.text().replaceAll('`', '').replace('json', '');
    const data = mergeProfiles(user, JSON.parse(text), !Overwrite);
    try {
      if (!user) {
        console.error("User is not authenticated.");
        return;
      }
      // Update the user's document in Firestore with the new data
      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, { ...data, resumeUpload: true });

      // Dispatch the updated user data to the store
      dispatch(login({ ...user, ...data, resumeUpload: true }));
      handleClose(); // Close the modal or form after submission
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      toast({
        title: `Something went wrong! Please try again.`,
        status: 'error',
        isClosable: false,
        ...toastDefaultConfig,
      });
    } finally {
      dispatch(toggleResume());
    }
    setOverwrite(false);
    setAcceptedFile(null);
    handleClose();
  }

  if (user?.resumeUpload && !upload_resume) {
    return null;
  }

  return (
    <>
      {/* {!user?.resumeUpload &&
        <Box rounded={"xl"} shadow={"xs"} width={'100%'} className="addmore" background={'#fff'} p={'0px'}>
          <Box p={'10px'} overflowY={'auto'} display={'flex'} flexDirection={'row'} gap={'10px'}>
            {tinyCard.map((item, index) => {
              return (
                <Card onClick={() => tinyCardClick(item.type)} key={index} rounded={"xl"} shadow={'none'} width={'100%'}>
                  <CardBody p={"15px"} key={index} gap={"10px"} alignItems={'center'} justifyContent={'space-between'} display={'flex'} flexDirection={'row'} background={"#E0FFC7"} rounded={"xl"} cursor={'pointer'} shadow={"sm"} _hover={{ shadow: 'sm' }}>
                    <Text color={"#143737"} fontSize="base" fontWeight={'500'}>{item.description}</Text>
                    {item.type === 'bio' && user?.bio ? <FaRegCheckCircle size={'20px'} color={"#143737"} /> : <FiArrowRightCircle size={'20px'} color={"#143737"} />}
                  </CardBody>
                </Card>
              )
            })}
          </Box>
        </Box>
      } */}
      {isOpen && <EditModal
        isOpen={isOpen} onOpen={onOpen} onClose={handleClose}
        headerText={Bio1Resume2 === 1 ? 'Bio' : 'Upload Resume'}
        saveFn={handleSubmit(onSubmit)}
        IsSubmitting={IsSubmitting}
      >
        <VStack className='modal_form' p={"20px"} spacing={4} overflow={'auto'} height={'auto'}>
          {Bio1Resume2 === 1 ? <>
            <FormControl isInvalid={errors.bio} display={'flex'} columnGap={10} flexDirection={'column'} alignItems={'start'}>
              <Textarea
                type="textarea"
                fontSize={'sm'}
                rows={8}
                placeholder="Add a little about all the awesome work you do..."
                {...register('bio', { required: 'Bio is required', maxLength: { value: 350, message: '350 characters allowed' } })}
              />
            </FormControl>
            {errors?.bio?.message ?
              <ErrorBox errorText={errors?.location?.bio} /> : null
            }
          </> :
            <><FormControl display={'flex'} columnGap={10} flexDirection={'column'} alignItems={'start'}>
              <Box {...getRootProps({ className: 'dropzone' })} cursor={"pointer"} width={"100%"} border={'1px dashed #DEDEE0'} px={"10px"} rounded={"md"} py={"20px"} background={"#EFEFF1"} textAlign={'center'}
                display={'flex'} flexDirection="column" alignItems={"center"}>
                <input {...getInputProps()} />
                <HiOutlineDocumentText size="30px" style={{ marginBottom: '10px' }} />
                {!AcceptedFile ?
                  <>
                    <Text color="#55575E">Drag n' drop, or click to select</Text>
                    <Text color="#A4A6A9">PDF format</Text>
                    <Text textAlign={"center"} color="#A4A6A9">This will overwrite all previous data</Text>

                  </>
                  : <Text color="#55575E">{AcceptedFile.name}</Text>}
              </Box>
            </FormControl>

              {/* <FormControl display='flex' alignItems='center' justifyContent={'space-between'}>
                <FormLabel fontSize={"sm"} htmlFor='overwrite' mb='0' fontWeight={400}>
                  <Text color="#000000">This will overwrite all previous data</Text>
                </FormLabel>
                <Switch colorScheme='brand' id='overwrite' isChecked={Overwrite} onChange={() => setOverwrite((prev) => !prev)} />
              </FormControl> */}
            </>
          }
        </VStack>
      </EditModal>}
    </>
  )
}

export default AddMore