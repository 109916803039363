import React, { useEffect, useState, useCallback } from 'react';
import {
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toastDefaultConfig } from '../../../utils/constants';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase.config';
import { login } from '../../../redux/slices/userSlice';
import { toggleTools } from '../../../redux/slices/sectionEditSlice';

const useEditSoftSkills = ({ extUser }) => {
  const { user: currentUser } = useSelector(state => state.user);
  const [user, setUser] = useState();
  useEffect(() => {
    if (extUser) {
      setUser(extUser);
    } else {
      setUser(currentUser);
    }
  }, [extUser, currentUser])
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tools, setTools] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();
  useEffect(() => {
    if (user?.soft_skills) {
      setTools([...user?.soft_skills, '']);
    }
  }, [user])

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      soft_skills: user?.soft_skills,
    },
  });

  const { soft_skills } = useSelector((state) => state.sectionEdit);

  useEffect(() => {
    if (soft_skills) {
      onOpen();
    } else {
      handleClose();
    }
  }, [soft_skills])

  const handleClose = () => {
    onClose();
    if (soft_skills) {
      dispatch(toggleTools());
    }
  }

  useEffect(() => {
    let numOfEmptyTools = tools?.filter((item) => item === '')?.length;
    if (numOfEmptyTools > 1) {
      let firstIndexOf = tools?.findIndex((item) => item === '');
      setTools([...tools?.slice(0, firstIndexOf), ...tools?.slice(firstIndexOf + 1)]);
    }
  }, [tools])

  const handleSkillChange = useCallback((index, value) => {
    setTools(prevTools => {
      const updatedTools = [...prevTools];
      if (updatedTools[index] !== value) { // Only update if value actually changed
        updatedTools[index] = value;
        if (value && index === prevTools?.length - 1) {
          updatedTools?.push('');
        }
      }
      return updatedTools;
    });
  }, []);

  useEffect(() => {
    setTools(prevTools => {
      const filteredTools = prevTools?.filter((tool, index) => tool !== '' || index === prevTools?.length - 1);
      return filteredTools?.length === 0 ? [''] : filteredTools;
    });
  }, []);

  const onDragEnd = useCallback((result) => {
    if (!result.destination) return;

    setTools(prevTools => {
      const reorderedTools = Array.from(prevTools);
      const [movedSkill] = reorderedTools?.splice(result.source.index, 1);
      reorderedTools?.splice(result.destination.index, 0, movedSkill);

      return reorderedTools;
    });
  }, []);

  const handleInputChange = useCallback((index) => (e) => {
    handleSkillChange(index, e.target.value);
  }, [handleSkillChange]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    try {
      if (!user) {
        console.error("User is not authenticated.");
        return;
      }

      const newData = {
        ...data,
        soft_skills: tools?.slice(0, tools?.length - 1), // Exclude the last empty tool
      };

      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, newData);
      dispatch(login({ ...user, ...newData }));
      handleClose();
    } catch (error) {
      console.error(error);
      toast({
        title: `Something went wrong! Please try again.`,
        status: 'error',
        isClosable: false,
        ...toastDefaultConfig,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    user,
    isOpen,
    onOpen,
    onClose,
    tools,
    isSubmitting,
    handleInputChange,
    onDragEnd,
    onSubmit,
    register,
    errors,
    handleSubmit,
    handleClose,
  }
};

export default useEditSoftSkills;
