import React from 'react';
import './styles.scss';

// Utility function to lighten or darken a hex color
const adjustColor = (color, amount) => {
  if (!color) return '#FF4D00'; // Return a default color if color is undefined

  let colorValue = color.startsWith("#") ? color.slice(1) : color;

  // Convert hex to RGB
  const num = parseInt(colorValue, 16);
  let r = (num >> 16) + amount;
  let g = ((num >> 8) & 0x00ff) + amount;
  let b = (num & 0x0000ff) + amount;

  // Make sure RGB stays in the valid range
  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  // Convert back to hex
  return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
};

const Blob = ({ color }) => {
  // If color prop is not provided, default to '#FF4D00'
  const defaultColor = color || '#fbfbfb';

  return (
    <div className="container">
      <div className="blobs">
        <div className="blob a" style={{ backgroundColor: adjustColor(defaultColor, 50) }}>
          blob 1
        </div>
        <div className="blob b" style={{ backgroundColor: defaultColor }}>
          blob 2
        </div>
        <div className="blob c" style={{ backgroundColor: adjustColor(defaultColor, -50) }}>
          blob 3
        </div>
      </div>
    </div>
  );
};

export default Blob;
