import { Box, Button, Card, CardBody, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react'
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toggleAchievements, toggleBio, toggleEducation, toggleManageSection, toggleProjects, toggleResume, toggleSkills, toggleTools, toggleWork } from '../../redux/slices/sectionEditSlice';
import { GrDocumentUpload } from "react-icons/gr";
import { GrTextAlignFull } from "react-icons/gr";
import { LiaAwardSolid } from "react-icons/lia";
import { GiLaptop } from "react-icons/gi";
import { TbTools } from "react-icons/tb";
import { LiaBusinessTimeSolid } from "react-icons/lia";
import { SiProbot } from "react-icons/si";
import { GrCertificate } from "react-icons/gr";

const ManageSections = () => {
  const { onClose, onOpen, isOpen } = useDisclosure();
  const dispatch = useDispatch();
  const { manage_section_toggle } = useSelector((state) => state.sectionEdit);

  useEffect(() => {
    if (manage_section_toggle) {
      onOpen();
    } else {
      onClose();
    }
  }, [manage_section_toggle])

  const tinyCard = [
    { action: () => dispatch(toggleResume()), icon: <GrDocumentUpload color="#143737" size={20} />, type: 'resume', description: 'Resume' },
    { action: () => dispatch(toggleBio()), icon: <GrTextAlignFull color="#143737" size={20} />, type: 'bio', description: 'Bio' },
    { action: () => dispatch(toggleAchievements()), icon: <LiaAwardSolid color="#143737" size={25} />, type: 'achievement', description: 'Achievements' },
    { action: () => dispatch(toggleWork()), icon: <LiaBusinessTimeSolid color="#143737" size={20} />, type: 'work', description: 'Experience' },
    { action: () => dispatch(toggleSkills()), icon: <GiLaptop color="#143737" size={22} />, type: 'skills', description: 'Core Skills' },
    { action: () => dispatch(toggleTools()), icon: <TbTools color="#143737" size={20} />, type: 'tools', description: 'Soft Skills' },
    { action: () => dispatch(toggleProjects()), icon: <SiProbot color="#143737" size={20} />, type: 'projects', description: 'Projects' },
    { action: () => dispatch(toggleEducation()), icon: <GrCertificate color="#143737" size={20} />, type: 'education', description: 'Education' }
  ];


  return (
    <Modal autoFocus={false} isOpen={isOpen} onClose={() => {
      dispatch(toggleManageSection())
      onClose();
    }}>
      <ModalOverlay />
      <ModalContent m={2} zIndex={9999}>
        <ModalHeader textAlign={"center"} fontSize={'sm'} fontWeight={'500'} borderBottom={"2px solid"} borderColor={"#E8E8E8"}>Manage Sections</ModalHeader>
        <ModalBody p="0px" height={"auto"} background={"brand.white"} maxHeight={"calc(100dvh - 135px)"} overflow={"auto"}>
          <Box p={'10px'} overflowY={'auto'} display={'flex'} flexDirection={'row'} gap={'10px'} justifyContent={"center"} flexWrap={"wrap"}>
            {tinyCard.map((item, index) => {
              return (
                <Card onClick={item.action} key={index} borderRadius={"5px"} border={"2px solid"} borderColor={"#E8E8E8"} maxWidth={{ base: "155px", sm: '200px' }} shadow={"sm"} _hover={{ shadow: 'md' }} width={'100%'}  >
                  <CardBody p={"15px"} key={index} gap={"10px"} alignItems={'center'} justifyContent={'space-between'} display={'flex'} flexDirection={'row'} rounded={"xl"} cursor={'pointer'}>
                    <Text fontSize="base" color={"#143737"} fontWeight={'500'}>{item.description}</Text>
                    {item.icon}
                  </CardBody>
                </Card>
              )
            })}
          </Box>
        </ModalBody>
        <ModalFooter borderTop={"2px solid"} borderColor={"#E8E8E8"}>
          <Button variant='ghost' size={'sm'} mr={3} onClick={() => {
            dispatch(toggleManageSection());
            onClose();
          }}>close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ManageSections