import { useDisclosure, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getFileNameFromUrl, insertOrReplaceObject } from '../../../helper/random.helper';
import { toastDefaultConfig } from '../../../utils/constants';
import { doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../../firebase.config';
import { login } from '../../../redux/slices/userSlice';
import { toggleWork } from '../../../redux/slices/sectionEditSlice';
import { resizeImage } from '../../../helper/resizeImage';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';


const useEditExperience = ({ extUser }) => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector(state => state.user);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [user, setUser] = useState();
  const [EditingWorkObjIndex, setEditingWorkObjIndex] = useState(null);
  const [SelectedWorkObj, setSelectedWorkObj] = useState();
  useEffect(() => {
    if (extUser) {
      setUser(extUser);
    } else {
      setUser(currentUser);
    }
  }, [extUser, currentUser])

  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const [CopyImageArr, setCopyImageArr] = useState([]);


  const InitialFormValue = {
    company: '',
    title: '',
    start_date: '',
    end_date: '',
    description: '',
    summary: ''
  }
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control
  } = useForm({
    defaultValues: InitialFormValue,
  });

  const { work: workState } = useSelector((state) => state.sectionEdit);

  useEffect(() => {
    if (workState) {
      onOpen();
    } else {
      handleClose();
    }
  }, [workState])

  const handleClose = () => {
    onClose();
    setEditingWorkObjIndex(null);
    if (workState) {
      dispatch(toggleWork());
    }
  }

  const resetFormWithWorkIdData = (workItem) => {
    let startDate = workItem.years.split('-')[0].trim();
    let endDate = workItem.years.split('-')[1].trim();
    setCopyImageArr(workItem.images);
    reset({
      company: workItem.company,
      title: workItem.title?.toLowerCase(),
      start_date: startDate,
      end_date: endDate,
      description: workItem.description,
      summary: workItem.summary
    })
  }

  const onSubmit = (data) => {
    const payload = insertOrReplaceObject(user?.work, {
      company: data.company,
      years: `${data.start_date} - ${data.end_date}`,
      description: data.description,
      summary: data.summary,
      title: data.title?.toLowerCase(),
      images: data.images
    }, EditingWorkObjIndex);
    dbWriteWorkFn(payload);
  }

  const dbWriteWorkFn = async (payload) => {
    setIsSubmitting(true);

    try {
      if (!user) {
        console.error("User is not authenticated.");
        return;
      }

      const uploadImagesAndGetUrls = async (images) => {
        const imageUrls = [];

        let ChangesInOldImageArr = images.filter(image => typeof image === 'string');
        let ImagesToDelete = CopyImageArr.filter(image => !ChangesInOldImageArr.includes(image));

        for (const image of images) {
          try {
            // Resize the image before uploading
            const resizedImage = await resizeImage(image);
            const storageRef = ref(storage, `users/${user.uid}/${resizedImage.name}`);
            await uploadBytes(storageRef, resizedImage); // Upload the resized image
            const url = await getDownloadURL(storageRef); // Get the download URL
            imageUrls.push(url); // Store the URL
          } catch (error) {
            console.error("Error resizing or uploading image: ", error);
          }
        }

        for (const image of ImagesToDelete) {
          try {
            // get image name from url
            const imageName = getFileNameFromUrl(image);
            const storageRef = ref(storage, `users/${user.uid}/${imageName}`);
            await deleteObject(storageRef);
          } catch (error) {
            console.error("Error deleting images: ", error);
          }
        }
        return imageUrls;
      };

      // Upload images to Firebase Storage and get the URLs
      const imageUrls = payload.length > 0 ? await uploadImagesAndGetUrls(payload?.[EditingWorkObjIndex].images) : [];

      const modPayload = payload.length > 0 ? payload.map((item, index) => {
        if (index === EditingWorkObjIndex) {
          return {
            ...item,
            images: [...item.images.filter((file) => typeof file === 'string'), ...imageUrls]
          }
        } else
          return item;
      })
        : [];

      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, { work: modPayload });
      dispatch(login({ ...user, work: modPayload }));
      handleClose();
    } catch (error) {
      toast({
        title: `Something went wrong! Please try again.`,
        status: 'error',
        isClosable: false,
        ...toastDefaultConfig,
      });
    } finally {
      setIsSubmitting(false);
      setEditingWorkObjIndex(null);
    }
  }

  const actionFn = async () => {
    setIsSubmitting(true);
    const firstHalf = user?.work.slice(0, EditingWorkObjIndex);
    const secondHalf = user?.work.slice(EditingWorkObjIndex + 1);
    let payload = [...firstHalf, ...secondHalf]
    try {
      // delete images from firebase storage
      for (const image of user?.work[EditingWorkObjIndex].images) {
        const storageRef = ref(storage, `users/${user.uid}/${getFileNameFromUrl(image)}`);
        await deleteObject(storageRef);
      }
      toast({
        title: "Experience deleted",
        description: "Your experience has been deleted successfully.",
        status: "success",
        isClosable: false,
        ...toastDefaultConfig,
      });
    } catch (error) {
      console.error("Error deleting Jobs: ", error);
    }
    dbWriteWorkFn(payload);
  }


  return {
    user,
    register,
    errors,
    handleSubmit,
    reset,
    setValue,
    control,
    isSubmitting,
    onSubmit,
    actionFn,
    SelectedWorkObj,
    setSelectedWorkObj,
    EditingWorkObjIndex,
    setEditingWorkObjIndex,
    isOpen,
    onClose,
    resetFormWithWorkIdData,
    handleClose,
    onOpen,
    InitialFormValue
  }
};

export default useEditExperience;
