import React, { useEffect, useState } from 'react';
import { Flex, VStack } from '@chakra-ui/react';
import TechSkillsCard from './TechSkillsCard/TechSkillsCard';
import Experience from './Experience/Experience';
import Projects from './Projects/Projects';
import OtherTools from './OtherTools/OtherTools';
import AchievementsCard from './Achievements/Achievements';
import Education from './Education/Education';
import UserCard from './UserCard/UserCard';
import { useSelector } from 'react-redux';


const MonochromeVintage = ({ UserData: extUser }) => {
  const { user: currentUser } = useSelector(state => state.user);
  const { isLoggedIn } = useSelector(state => state.status);
  const [user, setUser] = useState();
  useEffect(() => {
    if (extUser) {
      setUser(extUser);
    } else {
      setUser(currentUser);
    }
  }, [extUser, currentUser]);

  return (
    <Flex padding={isLoggedIn ? '100px 10px 50px 10px' : '20px 10px 20px 10px'} direction={'column'} justifyContent="center" gap={3} >
      <UserCard extUser={extUser} />
      <Flex direction={{ base: 'column', md: 'row' }} gap={{ base: 3, md: 0 }}>
        <VStack gap={3}>
          <Experience extUser={extUser} />
          <Projects extUser={extUser} />
        </VStack>
        <Flex direction={'column'} gap={3}>
          <TechSkillsCard extUser={extUser} />
          <OtherTools extUser={extUser} />
          <AchievementsCard extUser={extUser} />
          <Education extUser={extUser} />
        </Flex>
      </Flex>
    </Flex >
  )
}

export default MonochromeVintage