export const toastDefaultConfig = {
  duration: 5000,
  isClosable: true,
  variant: "subtle",
  containerStyle: {
    backgroundColor: "white",
    borderRadius: "7px",
    border: "2px solid black",
    color: '#000',
  }
}

export const userstatus = {
  'Available for Work': {
    textColor: '#143737', // Dark green
    backgroundColor: '#E0FFC7' // Light green
  },
  'Currently Working': {
    textColor: '#00529B', // Dark blue
    backgroundColor: '#BDE5F8' // Light blue
  },
  'On a Break': {
    textColor: '#7F6000', // Dark brown
    backgroundColor: '#FFF4CC' // Light yellow
  }
}

export const DEBOUNCE_DELAY = 500;


export const userDetailsPrompt = `Extract a concise JSON object with CORRECT SYNTAX from the document with the following structure:{
      "name": "User's full name as a string",
      "role": "User's job role as a string",
      "experience": "Total years of experience as a number",
      "links": ["List of URLs (e.g., GitHub, LinkedIn) as an array of strings"],
      "bio": "A short description of the user under 250 characters",
      "work": [
        {
          "title": "Job title as a string",
          "company": "Company name as a string",
          "logo": "Add url from web for logo based on name",
          "years": "Duration of employment (e.g., 'May 2022 - Present') as a string",
          "description": "<ol><li>A VERY SHORT work description in HTML format</li></ol>",
          "summary": "A concise two-line summary from the description of the work performed during that job",
          "images": "initialise with empty array []" 
        }
      ],
      "core_skills": ["List of core skills as an array of short strings"],
      "soft_skills": ["List of complementary skills which is not part of the core skills such as soft skills, etc as an array of strings"],
      "achievements": [
        {
          title: "Short summary of achievement",
          year: "year of achievement",
          images: "initialise with empty array []"
        }
      ],
      "other_projects": [
        {
          "title": "Project name as a string",
          "year": "Year of development as a string",
          "description": "<ol><li>Short description of the project in HTML format</li></ol>",
          "summary": "A brief two-line project summary",
          "images": "initialise with empty array []"
        }
      ],
      "education": [
        {
          "name": "Institution name as a string",
          "course": "Course name as a string",
          "logo": "Add url from web for logo based on name",
          "year": "Duration of education (e.g., 'May 2022 - Present') as a string",
          "description": "<ol><li>Short course description in HTML format</li></ol>",
          "images": "initialise with empty array []"
        }
      ],
      "contact": "User's phone number as a string"
    }
    
    Ensure thet all keys name and values are present and in the correct format as stated and do not include any new key else people will die and ensure that there is nothing else in the response other than the JSON object`;
