import { VStack, FormControl, FormLabel, Input, Textarea } from '@chakra-ui/react';
import React from 'react';
import EditModal from '../../../atoms/EditModal';
import ErrorBox from '../../../atoms/ErrorBox';
import ImageUploader from '../../../atoms/ImageUploader';


const ProjectEdit = (editProject) => {

  const {
    EditingProjectObjIndex,
    register,
    errors,
    handleSubmit,
    setValue,
    control,
    onSubmit,
    actionFn,
    isSubmitting,
    handleClose,
    onOpen,
    isOpen,
    SelectedProjectObj
  } = editProject;


  return (
    <EditModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      headerText={'Project'}
      saveFn={handleSubmit(onSubmit)}
      IsSubmitting={isSubmitting}
      maxWidth={"700px"}
    >
      <VStack className='modal_form app-scroll' p={"20px"} spacing={4} overflow={'auto'} height={'calc(100dvh - 140px)'}>
        {errors?.title || errors?.year || errors?.summary || errors?.description ?
          <ErrorBox errorText={errors?.title?.message || errors?.year?.message || errors?.summary?.message || errors?.description?.message} /> : null}
        <FormControl isInvalid={errors.title} display={'flex'} columnGap={10} flexDirection={'row'} alignItems={'center'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Project</FormLabel>
          <Input
            fontSize={'sm'}
            placeholder="Awesome app"
            {...register('title', { required: 'Project is required' })}
          />
        </FormControl>
        <FormControl isInvalid={errors.year} display={'flex'} columnGap={10} flexDirection={'row'} alignItems={'center'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Year</FormLabel>
          <Input
            fontSize={'sm'}
            placeholder="2023"
            {...register('year', {
              required: 'Year is required',
              pattern: {
                value: /^\d{4}$/,
                message: 'Year must be in YYYY format'
              }
            })}
          />
        </FormControl>
        <FormControl isInvalid={errors.summary} display={'flex'} rowGap={"10px"} flexDirection={'column'} alignItems={'self-start'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Summary</FormLabel>
          <Textarea
            type="textarea"
            fontSize={'sm'}
            rows={4}
            borderRadius={'10px'}
            className="app-scroll"
            placeholder="Tell us more about this position... You can format your text using HTML tags. Try using:
              <b>Bold</b>
              <i>Italic</i>
              <ul><li>Lists</li></ul>
              <a href='#'>Links</a>"
            {...register('summary', { required: 'Summary is required', maxLength: { value: 300, message: '300 characters allowed' }, minLength: { value: 60, message: 'Minimum 60 characters required' } })}
          />
        </FormControl>
        <FormControl isInvalid={errors.description} display={'flex'} rowGap={"10px"} flexDirection={'column'} alignItems={'self-start'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Description</FormLabel>
          <Textarea
            type="textarea"
            fontSize={'sm'}
            rows={8}
            placeholder="Tell us more about this position... You can format your text using HTML tags. Try using:
              <b>Bold</b>
              <i>Italic</i>
              <ul><li>Lists</li></ul>
              <a href='#'>Links</a>"
            {...register('description', { required: 'Description is required' })}
          />
        </FormControl>
        <FormControl id="images" gap={{ base: "10px", sm: "20px" }} display={"flex"} flexDir={{ base: 'column', sm: 'row' }} alignItems={{ sm: "center" }}>
          <FormLabel fontWeight={400} fontSize={'sm'} minWidth="120px" m={0}>
            Images
          </FormLabel>
          <ImageUploader EditImages={SelectedProjectObj} control={control} setValue={setValue} />
        </FormControl>
        {EditingProjectObjIndex !== null && <ErrorBox errorText={"Do you want to delete this Project from the list?"}
          actionBtn={() => actionFn()} actionBtnText={'Delete'} />}
      </VStack>
    </EditModal>
  );
};

export default ProjectEdit;
