import React, { useEffect, useState, useCallback, memo } from 'react';
import {
  Box,
  Tag,
  TagLabel,
  Wrap,
  WrapItem,
  Text,
  useDisclosure,
  useToast,
  FormControl,
  VStack,
  Input,
  IconButton,
  HStack,
  TagLeftIcon
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { PiPencilCircleDuotone, PiPlusCircleDuotone } from 'react-icons/pi';
import { useForm } from 'react-hook-form';
import EditModal from '../../../../atoms/EditModal';
import { toastDefaultConfig } from '../../../../utils/constants';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase.config';
import { login } from '../../../../redux/slices/userSlice';
import ErrorBox from '../../../../atoms/ErrorBox';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MdDragIndicator } from 'react-icons/md';
import { toggleSkills } from '../../../../redux/slices/sectionEditSlice';
import CoreSkillsEdit from '../../../EditSections/editCoreSkills/CoreSkillsEdit';
import useEditCoreSkills from '../../../EditSections/editCoreSkills/useEditCoreSkills';

const SkillTags = ({ extUser }) => {
  const editCoreSkills = useEditCoreSkills({ extUser });

  const {
    user,
    onOpen,
  } = editCoreSkills;

  return (
    <>
      {user?.core_skills?.length ? <Box
        width={{ base: '100%' }}
        p={'0px'}
        pl={{ base: 0, md: 3 }}
        position={'relative'}
        height={'fit-content'}
      >
        <HStack justify={"space-between"}>
          <Text className='font_cursive' fontSize={"4xl"} fontWeight={'500'}>Core Skills.</Text>
          {!extUser && <Box cursor={'pointer'} onClick={onOpen}>
            <PiPlusCircleDuotone size="18px" />
          </Box>}
        </HStack>
        <Wrap spacing={0} mt={'10px'}>
          {user?.core_skills?.map((item, index) => (
            <WrapItem key={index}>
              <Tag
                size="md"
                borderRadius="0"
                p={0}
                fontWeight={400}
                backgroundColor="transparent"
                className='font_playfair'
              >

                <TagLabel fontSize="3xl" fontWeight={500}>{item}</TagLabel>
                {index !== user?.core_skills.length - 1 && <Box width={"40px"} borderTop="2px solid" borderColor={"brand.black"} />}
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
      </Box> : null}
      <CoreSkillsEdit {...editCoreSkills} />
    </>
  );
};

export default memo(SkillTags); // Memoize the SkillTags component to prevent unnecessary re-renders
