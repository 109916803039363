import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    brand: {
      black: '#000000',
      white: '#FFFFFF',
      primary: '#000000',
      secondary: '#F4F4F2',
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: 'md',
        fontWeight: '400',
        borderRadius: '10px',
        _focusVisible: {
          boxShadow: 'none',
        },
      },
      variants: {
        solid: (props) => ({
          bg: props.colorMode === 'dark' ? 'brand.secondary' : 'brand.primary',
          color: props.colorMode === 'dark' ? 'brand.black' : 'brand.white',
          _hover: {
            color: props.colorMode === 'dark' ? 'brand.black' : 'brand.white',
            outline: 'none',
            boxShadow: 'none',
          },
          _active: {
            transform: 'scale(0.98)',
            boxShadow: 'none',
            outline: 'none',
          },
          _focusVisible: {
            boxShadow: 'none',
          },
        }),
        ghost: (props) => ({
          color: props.colorMode === 'dark' ? 'brand.white' : 'brand.black',
          _hover: {
            backgroundColor: 'transparent',
          },
          _active: {
            backgroundColor: 'transparent',
          },
        }),
      },
    },
    Tag: {
      baseStyle: {
        borderRadius: 'full',
        padding: '0.2rem 0.5rem',
      },
      variants: {
        solid: (props) => ({
          backgroundColor: props.colorMode === 'dark' ? 'brand.secondary' : 'brand.primary',
          color: props.colorMode === 'dark' ? 'brand.black' : 'brand.white',
        }),
        outline: (props) => ({
          border: '2px solid',
          borderColor: 'brand.black', // Ensure you use `borderColor` here
          backgroundColor: 'transparent',
          color: props.colorMode === 'dark' ? 'brand.black' : 'brand.white',
        }),
      },
    },
  },
});

export default theme;
