import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import EditModal from '../../atoms/EditModal';
import { Avatar, Box, CircularProgress, Flex, FormControl, FormLabel, Input, InputGroup, InputRightElement, Select, VStack, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db, storage } from '../../firebase.config';
import { debounce } from 'lodash';
import { DEBOUNCE_DELAY } from '../../utils/constants';
import ErrorBox from '../../atoms/ErrorBox';
import { toastDefaultConfig } from '../../utils/constants';
import { login } from '../../redux/slices/userSlice';
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { resizeImage } from '../../helper/resizeImage';
import { IoIosRemoveCircle } from 'react-icons/io';
import { AiFillCloseSquare } from 'react-icons/ai';

const UserCardEditable = ({ isOpen, onOpen, onClose }) => {
  const [IsSubmitting, setIsSubmitting] = useState(false);
  const { user } = useSelector(state => state.user);
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      availability: user?.availability,
      location: user?.location,
      role: user?.role,
      search_role: user?.role?.toLowerCase(),
      username: user?.username,
      experience: user?.experience,
    },
  });
  const inputRef = useRef(null);
  const toast = useToast()
  const dispatch = useDispatch();
  const [links, setLinks] = useState(['']);
  const [usernameValid, setUsernameValid] = useState(null); // null = not checked, true = valid, false = invalid
  const [loading, setLoading] = useState(false);
  const [TempProfilePic, setTempProfilePic] = useState(null);
  const [tempProfilePicURL, setTempProfilePicURL] = useState(null);
  const [IsDeletingDP, setIsDeletingDP] = useState(false)

  // delete user DPUrl from firebase storage and database
  const deleteUserDPUrl = async () => {
    if (user?.DPUrl) {
      setIsDeletingDP(true);
      // Create a reference to the old image
      const oldImageRef = ref(storage, user?.DPUrl);

      // Delete the old image
      await deleteObject(oldImageRef).catch((error) => {
        console.error("Error deleting old image:", error);
      });

      // remove from database as well
      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, { DPUrl: null });
      setTempProfilePicURL(null);
      setIsDeletingDP(false);
    }
  }


  useEffect(() => {
    if (user?.links?.length) {
      setLinks([...user?.links, '']);
    }
  }, [user])

  const handleAvatarClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setTempProfilePic(file);
    }
  };

  useEffect(() => {
    if (TempProfilePic) {
      const objectURL = URL.createObjectURL(TempProfilePic);
      setTempProfilePicURL(objectURL);

      // Clean up the URL when the component unmounts or TempProfilePic changes
      return () => URL.revokeObjectURL(objectURL);
    } else {
      setTempProfilePicURL(null);
    }
  }, [TempProfilePic]);

  const checkUsername = async (username) => {
    if (!username) return;

    setLoading(true);

    try {
      // First, check if the username matches the current user's username
      if (user?.username === username) {
        // If the username is the same as the current user's, it's valid
        setUsernameValid(true);
        setLoading(false);
        return;
      }
      // Reference to the users collection
      const usersRef = collection(db, 'users');

      // Query to check if any user has the same username
      const q = query(usersRef, where("username", "==", username));

      // Execute the query
      const querySnapshot = await getDocs(q);

      // If no documents match the query, the username is available
      setUsernameValid(querySnapshot.empty);
    } catch (error) {
      console.error("Error checking username:", error);
      setUsernameValid(false); // You may handle errors by marking it invalid.
    } finally {
      setLoading(false);
    }
  };

  const debouncedCheckUsername = useCallback(
    debounce(async (username) => {
      await checkUsername(username);
    }, DEBOUNCE_DELAY),
    []
  );

  const handleUsernameChange = (e) => {
    const username = e.target.value;
    setValue('username', username);
    debouncedCheckUsername(username);
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    let DPUrl = user?.DPUrl || "";
    try {
      if (!user) {
        console.error("User is not authenticated.");
        return;
      }

      // Check if there is a new profile picture uploaded
      if (TempProfilePic) {
        // If there is an existing profile picture, delete it
        if (user?.DPUrl) {
          // Create a reference to the old image
          const oldImageRef = ref(storage, user?.DPUrl);

          // Delete the old image
          await deleteObject(oldImageRef).catch((error) => {
            console.error("Error deleting old image:", error);
          });
        }

        // Optimize the image
        const optimizedImage = await resizeImage(TempProfilePic);

        // Upload the new image
        const storageRef = ref(storage, `profile_pictures/${user?.email}/${optimizedImage.name}`);
        const snapshot = await uploadBytes(storageRef, optimizedImage);

        // Get the download URL of the new image
        DPUrl = await getDownloadURL(snapshot.ref);
      }

      // Prepare the new data to update in Firestore
      let newData = {
        ...data,
        links: links.slice(0, links?.length - 1),
        DPUrl: DPUrl, // Set the new DPUrl or keep the existing one if no new image was uploaded
      };
      // Update the user's document in Firestore with the new data
      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, newData);
      // Dispatch the updated user data to the store
      dispatch(login({ ...user, ...newData }));
      onClose(); // Close the modal or form after submission
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      toast({
        title: `Something went wrong! Please try again.`,
        status: 'error',
        isClosable: false,
        ...toastDefaultConfig,
      });
    }
  };



  const handleLinkChange = (index, event) => {
    const newLinks = [...links];
    newLinks[index] = event.target.value;
    if (newLinks[index] && index === newLinks?.length - 1) {
      newLinks.push('');
    }

    setLinks(newLinks);
  }

  useEffect(() => {
    let numOfEmptyLinks = links.filter((item) => item === '')?.length;
    if (numOfEmptyLinks > 1) {
      let firstIndexOf = links.findIndex((item) => item === '');
      setLinks([...links.slice(0, firstIndexOf), ...links.slice(firstIndexOf + 1)]);
    }
  }, [links])


  return (
    <EditModal
      isOpen={isOpen} onOpen={onOpen} onClose={onClose}
      headerText={'Basic Info'}
      saveFn={handleSubmit(onSubmit)}
      IsSubmitting={IsSubmitting}
    >
      <VStack className='modal_form' p={"20px"} spacing={4} overflow={'auto'} height={'calc(100dvh - 135px)'}>
        {errors?.location?.message ||
          errors?.role?.message ||
          errors?.username?.message ||
          errors?.experience?.message ?
          <ErrorBox errorText={errors?.location?.message ||
            errors?.role?.message ||
            errors?.username?.message ||
            errors?.experience?.message} /> : null
        }
        <FormControl>
          <Flex flexDirection="row" alignItems="center" justifyContent={'center'}>
            <Box position="relative">
              <Avatar
                size="xl"
                src={tempProfilePicURL || user?.DPUrl}
                cursor="pointer"
                onClick={handleAvatarClick}
              />
              <Box position="absolute" top={0} right={"5px"} cursor={"pointer"}>
                {IsDeletingDP ?
                  <CircularProgress size={"20px"} isIndeterminate color="lightblue" /> :
                  <AiFillCloseSquare size={20} color="#E60023" onClick={deleteUserDPUrl} />
                }
              </Box>
            </Box>
            <Input
              ref={inputRef}
              type="file"
              accept="image/*"
              display="none"
              onChange={handleFileChange}
            />
          </Flex>
        </FormControl>

        <FormControl isInvalid={errors.availability} display={'flex'} columnGap={10} flexDirection={'row'} alignItems={'center'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Status</FormLabel>
          <Select border="2px solid" borderColor="brand.black" fontSize={'sm'} {...register('availability', { required: 'Status is required' })}>
            <option value='Available for Work'>Available for Work</option>
            <option value='Currently Working'>Currently Working</option>
            <option value='On a Break'>On a Break</option>
          </Select>
        </FormControl>

        <FormControl isInvalid={errors.location} display={'flex'} columnGap={10} flexDirection={'row'} alignItems={'center'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Location</FormLabel>
          <Input
            fontSize={'sm'}
            placeholder="Bangalore, India"
            {...register('location', { required: 'Location is required' })}
          />
        </FormControl>

        <FormControl isInvalid={errors.role} display={'flex'} columnGap={10} flexDirection={'row'} alignItems={'center'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Role</FormLabel>
          <Input
            fontSize={'sm'}
            placeholder="Software Engineer"
            {...register('role', { required: 'Role is required' })}
          />
        </FormControl>

        <FormControl isInvalid={errors.username} display={'flex'} columnGap={10} flexDirection={'row'} alignItems={'center'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Username</FormLabel>
          <InputGroup>
            <Input
              fontSize={'sm'}
              placeholder="awesomeuser"
              {...register('username', {
                required: 'Username is required',
                pattern: {
                  value: /^[A-Za-z0-9-]+$/,
                  message: 'Username can only contain alphabets, numbers and hyphen'
                },
                validate: {
                  isUsernameValid: value => usernameValid !== true || usernameValid !== null || 'Username is already taken'
                },
              })}
              onChange={handleUsernameChange}
            />
            <InputRightElement>
              {loading ? (
                <CircularProgress size={"20px"} isIndeterminate color="lightblue" />
              ) : (
                usernameValid === null ? null : usernameValid ? (
                  <FaCheckCircle color='green' />
                ) : (
                  <FaTimesCircle color='red' />
                )
              )}
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <FormControl isInvalid={errors.experience} display={'flex'} columnGap={10} flexDirection={'row'} alignItems={'center'}>
          <FormLabel fontWeight={400} fontSize={'sm'} width={'100px'} m={0}>Experience</FormLabel>
          <Input
            type="number"
            fontSize={'sm'}
            placeholder="total years"
            {...register('experience', {
              required: 'Experience is required',
              valueAsNumber: true,
              min: { value: 0, message: 'Experience must be at least 0 years' },
              max: { value: 50, message: 'Experience must be less than 50 years' },
            })}
          />
        </FormControl>

        {/* Links Field */}
        <FormControl display={'flex'} columnGap={10} flexDirection={'row'} alignItems={links?.length > 1 ? "flex-start" : 'center'}>
          <FormLabel fontWeight={400} fontSize={'sm'} pt={links?.length > 1 ? '10px' : 0} width={'100px'} m={0}>Links</FormLabel>
          <VStack width="100%" spacing={2}>
            {links?.map((link, index) => (
              <Input
                key={index}
                type="text"
                fontSize={'sm'}
                placeholder={`url.com`}
                value={link}
                onChange={(e) => handleLinkChange(index, e)}
              />
            ))}
          </VStack>
        </FormControl>
      </VStack>
    </EditModal>
  );
};

export default UserCardEditable;
