import { Box, Text, Button, useDisclosure, VStack, FormControl, FormLabel, Input, Textarea, useToast, Flex, HStack, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, DrawerFooter, DrawerCloseButton } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { PiPencilCircleDuotone, PiPlusCircleDuotone, PiTextColumnsDuotone } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import EditModal from '../../../../atoms/EditModal';
import ErrorBox from '../../../../atoms/ErrorBox';
import { useForm } from 'react-hook-form';
import { getTextColor, insertOrReplaceObject, titleCase } from '../../../../helper/random.helper';
import { toastDefaultConfig } from '../../../../utils/constants';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase.config';
import { login } from '../../../../redux/slices/userSlice';
import { toggleEducation } from '../../../../redux/slices/sectionEditSlice';
import Timeline from '../Timeline';
import useEditEducation from '../../../EditSections/editEducation/useEditEducation';
import EducationEdit from '../../../EditSections/editEducation/EducationEdit';


const Education = ({ extUser }) => {

  const editEducation = useEditEducation({ extUser });

  const {
    user,
    reset,
    resetFormWithEducationIdData,
    onOpen,
    setEditingEducationObjIndex,
    InitialFormValue
  } = editEducation;

  return (
    <>
      {user?.education?.length ? <Flex
        position={'relative'}
        width={{ base: '100%', md: '300px' }}
        direction={"column"}
        gap={3}
        maxWidth={{ base: "100%" }}
        pl={{ base: 0, md: 3 }}
        p={"0px"}
      >
        {!extUser && <Box
          cursor={"pointer"}
          position={"absolute"}
          top={"10px"}
          right={"10px"}
          onClick={() => {
            onOpen();
            reset(InitialFormValue);
          }}
        >
          <PiPlusCircleDuotone size="18px" />
        </Box>}
        <Text fontSize={"xl"} fontWeight={"500"}>Education</Text>
        {user?.education.map((item, index) => {
          return (
            <VStack
              gap={3}
              alignItems={"flex-start"}
              border={'2px solid'}
              borderColor={'brand.black'}
              rounded={'25px'}
              position={'relative'}
              p={4}
              key={index}>
              <Text fontWeight={"500"}
                fontSize={"sm"} background={user?.theme[1]}
                color={getTextColor(user?.theme[1]) ? 'brand.white' : 'brand.black'}
                p={"5px 10px"}
                borderRadius={"15px"} >{item.name}</Text>
              <Text className="font_cursive2" fontStyle={"italic"}
                width={'fit-content'}
                mt={"-3px"}
              >
                {item.course}
              </Text>
              <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                {item.description}
              </Markdown>
              <HStack gap={2} w="100%" alignItems={"start"}>
                <Timeline date={String(item.year)} />
                {!extUser &&
                  <Box
                    cursor={"pointer"}
                    onClick={() => {
                      setEditingEducationObjIndex(index);
                      onOpen();
                      resetFormWithEducationIdData(item)
                    }}
                  >
                    <PiPencilCircleDuotone size="18px" />
                  </Box>
                }
              </HStack>
            </VStack>
          );
        })}

      </Flex> : null}
      <EducationEdit {...editEducation} />
    </>
  );
};

export default Education;
