import { Box, Text, Button, useDisclosure, VStack, FormControl, FormLabel, Input, Textarea, useToast, Flex, HStack, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { PiPencilCircleDuotone, PiPlusCircleDuotone, PiTextColumnsDuotone } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import EditModal from '../../../../atoms/EditModal';
import ErrorBox from '../../../../atoms/ErrorBox';
import { useForm } from 'react-hook-form';
import { insertOrReplaceObject } from '../../../../helper/random.helper';
import { toastDefaultConfig } from '../../../../utils/constants';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase.config';
import { login } from '../../../../redux/slices/userSlice';
import { toggleAchievements } from '../../../../redux/slices/sectionEditSlice';
import Timeline from '../Timeline';
import useEditAchievements from '../../../EditSections/editAchievements/useEditAchievements';
import AchievementsEdit from '../../../EditSections/editAchievements/AchievementEdit';


const Achievements = ({ extUser }) => {
  const editAchievements = useEditAchievements({ extUser });
  const {
    user,
    onOpen,
    resetFormWithAchievementIdData,
    setEditingAchievementObjIndex,
  } = editAchievements;
  return (
    <>
      {user?.achievements?.length ? <Flex
        position={'relative'}
        width={{ base: '100%', md: '300px' }}
        height={"fit-content"}
        alignItems={"flex-start"}
        direction="column"
        pl={{ base: 0, md: 3 }}
        p={"0px"}
        gap={3}
      >
        <HStack w={"100%"} justify={"space-between"}>
          <Text fontSize={"xl"} fontWeight={"500"}>Achievements</Text>
          {!extUser && <Box cursor={'pointer'} onClick={onOpen}>
            <PiPlusCircleDuotone size="18px" />
          </Box>}
        </HStack>
        {user?.achievements.map((item, index) => {
          return (
            <VStack
              gap={3}
              alignItems={"flex-start"}
              border={'2px solid'}
              borderColor={'brand.black'}
              rounded={'25px'}
              width={"100%"}
              position={'relative'}
              p={4}
              key={index}>
              <Text
                width={'fit-content'}
              >
                {item.title}
              </Text>
              <HStack gap={2} w="100%">
                <Timeline date={String(item.year)} />
                {!extUser &&
                  <Box
                    cursor={"pointer"}
                    onClick={() => {
                      setEditingAchievementObjIndex(index);
                      onOpen();
                      resetFormWithAchievementIdData(item)
                    }}
                  >
                    <PiPencilCircleDuotone size="18px" />
                  </Box>
                }
              </HStack>
            </VStack>
          );
        })}
      </Flex> : null}
      <AchievementsEdit {...editAchievements} />
    </>
  );
};

export default Achievements;
