import React from 'react';
import { Flex, Tag, TagLeftIcon, IconButton } from '@chakra-ui/react';
import { LuShare2, LuMail } from 'react-icons/lu';
import { TbTools } from 'react-icons/tb';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { BsHourglassSplit } from 'react-icons/bs';
import { getLinkDetails, getTextColor, openLink } from '../../../../helper/random.helper';
import ShareableDiv from '../../../../atoms/SharableDiv';


const UserInfo = ({ user, display }) => {
  return (
    <Flex display={display} gap={3} direction={'row'} alignItems="center" wrap="wrap" justifyContent="center">
      {!user?.private_email ?
        <Tag
          size="md"
          onClick={() => {
            window.location.href = `mailto:${user?.email}`;
          }}
          borderRadius="full"
          border="2px solid"
          borderColor="brand.black"
          px={3}
          py={1}
          backgroundColor="transparent"
          color="brand.black"
        >
          <TagLeftIcon as={LuMail} />
          {user?.email}
        </Tag>
        : null}
      <Tag
        size="md"
        borderRadius="full"
        borderColor="brand.black"
        px={3}
        py={1}
        background={user?.theme[1]}
        color={getTextColor(user?.theme[1]) ? 'brand.white' : 'brand.black'}
      >
        <TagLeftIcon as={TbTools} />
        {user?.role}
      </Tag>

      {user?.location ? (
        <Tag
          size="md"
          borderRadius="full"
          border="2px solid"
          borderColor="brand.black"
          px={3}
          py={1}
          backgroundColor="transparent"
          color="brand.black"
        >
          <TagLeftIcon as={HiOutlineLocationMarker} />
          {user?.location}
        </Tag>
      ) : null}
      {user?.experience ? (
        <Tag
          size="md"
          borderRadius="full"
          border="2px solid"
          borderColor="brand.black"
          px={3}
          py={1}
          backgroundColor="transparent"
          color="brand.black"
        >
          <TagLeftIcon as={BsHourglassSplit} />
          {user?.experience} years
        </Tag>
      ) : null}
      {user?.links?.map((item, index) => {
        const { icon } = getLinkDetails(item, 14);
        return (
          <IconButton
            key={index}
            icon={icon}
            onClick={() => openLink(item)}
            aria-label={`link-${index}`}
            size="sm"
            p={0}
            borderRadius="full"
            border="2px solid"
            borderColor="brand.black"
            variant="outline"
          />
        );
      })}
    </Flex>
  )
}

export default UserInfo