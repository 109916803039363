import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Badge, Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, HStack, IconButton, Image, Tag, TagLabel, TagLeftIcon, Text, VStack, Wrap, WrapItem, useDisclosure } from '@chakra-ui/react';
import { PiPencilCircleDuotone, PiPlusCircleDuotone, PiTextColumnsDuotone } from 'react-icons/pi';
import { userstatus } from '../../../utils/constants';
import { toggleBio } from '../../../redux/slices/sectionEditSlice';
import { useDispatch } from 'react-redux';
import BioCardEditable from '../../EditSections/BioEditable';
import rehypeRaw from 'rehype-raw';
import Markdown from 'react-markdown';
import UserCardEditable from '../../EditSections/UserCardEditable';
import { getLinkDetails, getTextColor, openLink, titleCase } from '../../../helper/random.helper';
import CircleTextAnimation from '../../CircleTextAnimation/CircleTextAnimation';
import ShareableDiv from '../../../atoms/SharableDiv';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { BsHourglassSplit } from 'react-icons/bs';
import { LuMail, LuShare2 } from 'react-icons/lu';
import { TbTools } from 'react-icons/tb';
import ExperienceEdit from '../../EditSections/editExperience/ExperienceEdit';
import useEditExperience from '../../EditSections/editExperience/useEditExperience';
import Timeline from '../BasicTemplate/Timeline';
import useEditProject from '../../EditSections/editProjects/useEditProject';
import ProjectEdit from '../../EditSections/editProjects/ProjectEdit';
import CoreSkillsEdit from '../../EditSections/editCoreSkills/CoreSkillsEdit';
import useEditCoreSkills from '../../EditSections/editCoreSkills/useEditCoreSkills';
import useEditSoftSkills from '../../EditSections/editSoftSkills/useEditSoftSkills';
import SoftSkillsEdit from '../../EditSections/editSoftSkills/SoftSkillsEdit';
import EducationEdit from '../../EditSections/editEducation/EducationEdit';
import AchievementsEdit from '../../EditSections/editAchievements/AchievementEdit';
import useEditEducation from '../../EditSections/editEducation/useEditEducation';
import useEditAchievements from '../../EditSections/editAchievements/useEditAchievements';


const SolidBackground = ({ UserData: extUser }) => {
  const { isLoggedIn } = useSelector(state => state.status);

  const { isOpen: isUserCardOpen, onOpen: onUserCardOpen, onClose: onUserCardClose } = useDisclosure();
  const dispatch = useDispatch();
  const { onOpen: onOpenWorkDescription, isOpen: isOpenWorkDescription, onClose: onCloseWorkDescription } = useDisclosure();
  const editExp = useEditExperience({ extUser });
  const {
    user,
    reset: resetExp,
    SelectedWorkObj,
    setSelectedWorkObj,
    setEditingWorkObjIndex,
    resetFormWithWorkIdData,
    InitialFormValue: InitialValueEdp,
    onOpen: onOpenEditExp,
  } = editExp;

  const { onOpen: onOpenProjectDescription, isOpen: isOpenProjectDescription, onClose: onCloseProjectDescription } = useDisclosure();
  const editProject = useEditProject({ extUser });
  const {
    setEditingProjectObjIndex,
    resetFormWithProjectIdData,
    onOpen: onOpenEditProject,
    setSelectedProjectObj,
    SelectedProjectObj,
  } = editProject;

  const editCoreSkills = useEditCoreSkills({ extUser });

  const {
    onOpen: onOpenCoreSkills,
  } = editCoreSkills;

  const editSoftSkills = useEditSoftSkills({ extUser });
  const {
    onOpen: onOpenSoftSkills,
  } = editSoftSkills;

  const editAchievements = useEditAchievements({ extUser });
  const {
    onOpen: onOpenEditAchievements,
    resetFormWithAchievementIdData,
    setEditingAchievementObjIndex,
  } = editAchievements;


  const editEducation = useEditEducation({ extUser });

  const {
    reset,
    resetFormWithEducationIdData,
    onOpen: onOpenEditEducation,
    setEditingEducationObjIndex,
    InitialFormValue
  } = editEducation;



  return (
    <Flex background={user?.theme[1]} color={getTextColor(user?.theme[1]) ? '#fff !important' : '#000 !important'} padding={isLoggedIn ? '100px 10px 50px 10px' : '20px 10px 20px 10px'} direction={'column'} justifyContent="center" gap={3}>
      {user?.username && (
        <>
          <Flex direction="column" justifyContent="center" gap={3}>
            {/* start of hero image + introduction */}
            <Flex
              maxH={["auto", "auto", "65vh"]}
              direction={{ base: 'column', md: 'row' }} justifyContent="stretch" alignItems={"stretch"} gap={3}>
              <Flex
                minWidth={["100%", "calc(50% - 20px)"]}
                border="2px solid"
                borderColor={getTextColor(user?.theme[1])}
                borderRadius="none"
                minHeight={'max-content'}
                position="relative" direction="column" alignItems="center" gap={3}>
                {user?.DPUrl ? <Image
                  src={user?.DPUrl}
                  borderRadius={"none"}
                  width={"full"}
                  height={["60vh", "70vh", "full"]}
                  objectFit={"cover"}
                  objectPosition={"center top"}
                /> : <Text position="absolute" bottom={10} textAlign={"center"} fontSize="md">Click on edit to add image</Text>}
                <Flex className={!getTextColor(user?.theme[1]) ? 'shadow_text' : 'shadow_text_dark'} position={"absolute"} mt={"10px"} left={0} right={"10px"} flexDir={"row"} justifyContent={{ base: "flex-end" }} zIndex={99}>
                  <CircleTextAnimation size={"80px"} text={user?.availability + '•••••••••••'} textColor={getTextColor(user?.theme[1]) ? '#fff' : '#000'} />
                </Flex>
                {!extUser && (
                  <IconButton
                    icon={<PiPencilCircleDuotone color={getTextColor(user?.theme[1]) ? '#fff' : '#000'} size={16} />}
                    position="absolute"
                    left={"10px"}
                    top={"10px"}
                    zIndex={99}
                    rounded={"full"}
                    height={"16px"}
                    width={"16px"}
                    minWidth={"16px"}
                    background={!getTextColor(user?.theme[1]) ? '#fff' : '#000'}
                    onClick={onUserCardOpen}
                    aria-label="Edit User"
                    size="sm"
                    variant="ghost"
                  />
                )}
              </Flex>
              <Box minWidth={["100%", "calc(50% - 20px)"]} border="2px solid" borderColor={getTextColor(user?.theme[1]) ? '#fff' : '#000'} borderRadius="none" position={"relative"}
                display={"flex"} flexDirection={"column"} justifyContent={"center"} gap={"10px"}>
                {!extUser && <Box cursor={'pointer'} position={'absolute'} top={"10px"} right={"10px"} onClick={() => dispatch(toggleBio())}>
                  <PiPencilCircleDuotone size="18px" />
                </Box>}
                <Box p={'20px'} display={'flex'} flexDirection={'column'} justifyContent={"space-between"} gap={'10px'} h={"100%"}>
                  <Text mb={["20px", "20px", "0"]} lineHeight={1} fontSize={['6xl', '6xl', '6xl']} fontWeight="500" textAlign="left">
                    {titleCase(user?.fullname)}
                  </Text>
                  <Box display={'flex'} flexDirection={'column'} justifyContent={"flex-end"} gap={'10px'} h={"100%"}>
                    <Text lineHeight={0.8} fontSize={['xl', '2xl']} fontWeight="500" textAlign="left">
                      Introduction
                    </Text>
                    <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                      {user?.bio}
                    </Markdown>
                  </Box>
                </Box>
              </Box>
              <BioCardEditable extUser={extUser} />
            </Flex>
            <UserCardEditable isOpen={isUserCardOpen} onClose={onUserCardClose} onOpen={onUserCardOpen} />
            {/* End of hero image + introduction */}


            {/* start of userInfo */}
            <Flex
              color={getTextColor(user?.theme[1]) ? '#fff' : '#000'} p={3}
              borderColor={getTextColor(user?.theme[1])} border={"2px solid"} gap={3} direction={'row'} alignItems="center" wrap="wrap" justifyContent="center">
              {!user?.private_email ?
                <Tag
                  size="md"
                  onClick={() => window.location.href = `mailto:${user?.email}`}
                  border="none"
                  color={getTextColor(user?.theme[1]) ? '#fff' : '#000'}
                  backgroundColor="transparent"
                >
                  <TagLeftIcon as={LuMail} />
                  {user?.email}
                </Tag> : null}
              <Tag
                size="md"
                border="none"
                color={getTextColor(user?.theme[1]) ? '#fff' : '#000'}
                background={user?.theme[1]}

              >
                <TagLeftIcon as={TbTools} />
                {user?.role}
              </Tag>

              {user?.location ? (
                <Tag
                  size="md"
                  border="none"
                  backgroundColor="transparent"
                  color={getTextColor(user?.theme[1]) ? '#fff' : '#000'}
                >
                  <TagLeftIcon as={HiOutlineLocationMarker} />
                  {user?.location}
                </Tag>
              ) : null}
              {user?.experience ? (
                <Tag
                  size="md"
                  border="none"
                  backgroundColor="transparent"
                  color={getTextColor(user?.theme[1]) ? '#fff' : '#000'}
                >
                  <TagLeftIcon as={BsHourglassSplit} />
                  {user?.experience} years
                </Tag>
              ) : null}
              {user?.links?.map((item, index) => {
                const { icon } = getLinkDetails(item, 14);
                return (
                  <IconButton
                    key={index}
                    icon={icon}
                    onClick={() => openLink(item)}
                    aria-label={`link-${index}`}
                    size="sm"
                    p={0}
                    border="none"
                    variant="ghost"
                    color={getTextColor(user?.theme[1]) ? '#fff' : '#000'}
                  />
                );
              })}
            </Flex>
            {/* end of userInfo */}
            {/* Start of Body Section */}
            <Flex direction={{ base: 'column', md: 'row' }} gap={3} w="full">

              {/* Start of Left Body Section */}
              <Flex direction={"column"} w="full" gap={3}>
                {/* start of work section */}
                {user?.work?.length ? <Flex
                  position={'relative'}
                  width={"100%"}
                  minWidth={{ base: '100%' }}
                  direction={"column"}
                  gap={"30px"}
                  alignItems={"flex-start"}
                  border={'2px solid'}
                  borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}
                  p={4}
                >
                  <HStack>
                    <Text fontSize={"xl"} fontWeight={"500"}>Work</Text>
                    {!extUser && <Box
                      cursor={"pointer"}
                      onClick={() => {
                        onOpenEditExp();
                        resetExp(InitialValueEdp);
                      }}
                    >
                      <PiPlusCircleDuotone size="18px" />
                    </Box>}
                  </HStack>
                  {user?.work.map((item, index) => {
                    return (
                      <VStack
                        w="100%"
                        gap={3}
                        alignItems={"flex-start"}
                        position={'relative'}
                        key={index}>
                        <Text fontWeight={"500"}
                          fontSize={"sm"}>{item.company}</Text>
                        <Text
                          width={'fit-content'}
                          mt={"-3px"}
                        >
                          {titleCase(item.title)}
                        </Text>
                        <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                          {item.summary}
                        </Markdown>
                        <HStack wrap={"wrap"} gap={2}>
                          {item?.images.length > 0 && item.images.map((item, index) => {
                            return (
                              <Image src={item} key={index} maxHeight={"150px"} objectFit={"contain"} />
                            )
                          })}
                        </HStack>
                        <HStack gap={2} w="100%" alignItems={"start"}>
                          <Timeline date={item.years} />
                          <Box
                            cursor={"pointer"}
                            onClick={() => {
                              onOpenWorkDescription();
                              setSelectedWorkObj(item);
                            }}
                          >
                            <PiTextColumnsDuotone size="16px" />
                          </Box>
                          {!extUser &&
                            <Box
                              cursor={"pointer"}
                              onClick={() => {
                                setEditingWorkObjIndex(index);
                                onOpenEditExp();
                                setSelectedWorkObj(item);
                                resetFormWithWorkIdData(item)
                              }}
                            >
                              <PiPencilCircleDuotone size="18px" />
                            </Box>
                          }
                        </HStack>
                      </VStack>
                    );
                  })}

                </Flex> : null}
                <ExperienceEdit {...editExp} />
                <Drawer
                  blockScrollOnMount={false}
                  isOpen={isOpenWorkDescription}
                  onOpen={onOpenWorkDescription}
                  onClose={() => {
                    onCloseWorkDescription();
                    setSelectedWorkObj(null);
                  }}
                >
                  <DrawerOverlay />
                  <DrawerContent
                    border={"2px solid"}
                    borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}
                    background={user?.theme[1]} zIndex={9999} maxWidth={{ base: "90%", md: "50%" }}>
                    <DrawerCloseButton color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} />
                    <DrawerHeader color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} textAlign={"center"} fontSize={'sm'} fontWeight={'500'} borderBottom={"2px solid"}
                      borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}>
                      {SelectedWorkObj?.company}
                    </DrawerHeader>
                    <DrawerBody color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} className="app-scroll" overflow={'auto'} p={"20px 40px"} width={"100%"}>
                      {SelectedWorkObj?.images.length > 0 && <HStack className="app-scroll" mt={2} mb={4} overflowX={"auto"} gap={2}>
                        {SelectedWorkObj.images.map((item, index) => {
                          return (
                            <Image src={item} key={index} maxHeight={"350px"} objectFit={"contain"} />
                          )
                        })}
                      </HStack>
                      }
                      <Text mb={3} >{titleCase(SelectedWorkObj?.title)}</Text>
                      <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                        {SelectedWorkObj?.description}
                      </Markdown>
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
                {/* end of work section */}

                {/* start of projects section */}
                {user?.other_projects?.length ? <Flex
                  position={'relative'}
                  width={"100%"}
                  minWidth={{ base: '100%' }}
                  alignItems={"flex-start"}
                  direction="column"
                  border={'2px solid'}
                  borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}
                  p={4}
                  gap={"30px"}
                >
                  <HStack>
                    <Text fontSize={"xl"} fontWeight={"500"}>Projects</Text>
                    {!extUser && <Box cursor={'pointer'} onClick={onOpenEditProject}>
                      <PiPlusCircleDuotone size="18px" />
                    </Box>}
                  </HStack>
                  {user?.other_projects.map((item, index) => {
                    return (
                      <VStack
                        w={"100%"}
                        gap={3}
                        alignItems={"flex-start"}
                        position={'relative'}
                        key={index}>
                        <Text fontWeight={"500"}
                          fontSize={"sm"}
                          width={'fit-content'}
                        >
                          {item.title}
                        </Text>

                        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                          <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                            {item.summary}
                          </Markdown>
                        </Box>
                        <HStack wrap={"wrap"} gap={2}>
                          {item?.images.length > 0 && item.images.map((item, index) => {
                            return (
                              <Image src={item} key={index} maxHeight={"100px"} objectFit={"contain"} />
                            )
                          })}
                        </HStack>
                        <HStack gap={2} w="100%">
                          <Timeline date={String(item.year)} />
                          <Box
                            cursor={"pointer"}
                            onClick={() => {
                              onOpenProjectDescription();
                              setSelectedProjectObj(item);
                            }}
                          >
                            <PiTextColumnsDuotone size="16px" />
                          </Box>
                          {!extUser &&
                            <Box
                              cursor={"pointer"}
                              onClick={() => {
                                setEditingProjectObjIndex(index);
                                onOpenEditProject();
                                setSelectedProjectObj(item);
                                resetFormWithProjectIdData(item)
                              }}
                            >
                              <PiPencilCircleDuotone size="18px" />
                            </Box>
                          }
                        </HStack>
                      </VStack>
                    );
                  })}
                </Flex> : null}
                <ProjectEdit {...editProject} />
                <Drawer
                  blockScrollOnMount={false}
                  isOpen={isOpenProjectDescription}
                  onOpen={onOpenProjectDescription}
                  onClose={() => {
                    onCloseProjectDescription();
                    setSelectedProjectObj(null);
                  }}
                >
                  <DrawerOverlay />
                  <DrawerContent
                    border={"2px solid"}
                    borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}
                    background={user?.theme[1]} zIndex={9999} maxWidth={{ base: "90%", md: "50%" }}>
                    <DrawerCloseButton color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} />
                    <DrawerHeader color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} textAlign={"center"} fontSize={'sm'} fontWeight={'500'} borderBottom={"2px solid"} borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}>
                      {SelectedProjectObj?.title}
                    </DrawerHeader>
                    <DrawerBody color={getTextColor(user?.theme[1]) ? "#fff" : "#000"} background={user?.theme[1]} className="app-scroll" overflow={'auto'} p={"20px 40px"} width={"100%"}>
                      {SelectedProjectObj?.images.length > 0 && <HStack className="app-scroll" mt={2} mb={4} overflowX={"auto"} gap={2}>
                        {SelectedProjectObj.images.map((item, index) => {
                          return (
                            <Image src={item} key={index} maxHeight={"350px"} objectFit={"contain"} />
                          )
                        })}
                      </HStack>
                      }
                      <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                        {SelectedProjectObj?.description}
                      </Markdown>
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
                {/* end of projects section */}
              </Flex>
              {/* End of Left Body Section */}


              {/* Start of Right Body Section */}
              <Flex direction={"column"} w={{ base: "full", md: "300px" }} gap={3}>
                {user?.core_skills?.length ? <Box
                  width={{ base: '100%', md: '300px' }}
                  position={'relative'}
                  height={'fit-content'}
                  border={'2px solid'}
                  borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}
                  px={1}
                  py={2}
                >
                  <HStack >
                    <Text fontSize={"xl"} fontWeight={'500'} px={"7px"}>Core Skills</Text>
                    {!extUser && <Box cursor={'pointer'} onClick={onOpenCoreSkills}>
                      <PiPlusCircleDuotone size="18px" />
                    </Box>}
                  </HStack>
                  <Wrap spacing={0} mt={'10px'}>
                    {user?.core_skills?.map((item, index) => (
                      <WrapItem key={index}>
                        <Tag
                          borderRadius="full"
                          color={getTextColor(user?.theme[1]) ? "#fff" : "#000"}
                          fontWeight={400}
                          backgroundColor="transparent"
                        >
                          <TagLabel fontSize="sm" fontWeight={500}>{item}</TagLabel>
                        </Tag>
                      </WrapItem>
                    ))}
                  </Wrap>
                </Box> : null}
                <CoreSkillsEdit {...editCoreSkills} />
                {/* End of Right Body Section */}
                {user?.soft_skills?.length ? <Box
                  width={{ base: '100%', md: '300px' }}
                  position={'relative'}
                  height={'fit-content'}
                  border={'2px solid'}
                  borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}
                  px={1}
                  py={2}
                >
                  <HStack>
                    <Text fontSize={"xl"} fontWeight={'500'} px={"7px"}>Soft Skills</Text>
                    {!extUser && <Box cursor={'pointer'} onClick={onOpenSoftSkills}>
                      <PiPlusCircleDuotone size="18px" />
                    </Box>}
                  </HStack>
                  <Wrap spacing={0} mt={'10px'}>
                    {user?.soft_skills?.map((item, index) => (
                      <WrapItem key={index}>
                        <Tag
                          borderRadius="full"
                          fontWeight={400}
                          backgroundColor="transparent"
                          color={getTextColor(user?.theme[1]) ? "#fff" : "#000"}
                        >
                          <TagLabel fontSize="sm" fontWeight={500}>{item}</TagLabel>
                        </Tag>
                      </WrapItem>
                    ))}
                  </Wrap>
                </Box> : null}
                <SoftSkillsEdit {...editSoftSkills} />

                {user?.achievements?.length ? <Flex
                  position={'relative'}
                  width={{ base: '100%', md: '300px' }}
                  height={"fit-content"}
                  alignItems={"flex-start"}
                  direction="column"
                  border={'2px solid'}
                  borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}
                  p={3}
                  gap="20px"
                >
                  <HStack w={"100%"}>
                    <Text fontSize={"xl"} fontWeight={"500"}>Achievements</Text>
                    {!extUser && <Box cursor={'pointer'} onClick={onOpenEditAchievements}>
                      <PiPlusCircleDuotone size="18px" />
                    </Box>}
                  </HStack>
                  {user?.achievements.map((item, index) => {
                    return (
                      <VStack
                        gap={3}
                        alignItems={"flex-start"}
                        width={"100%"}
                        position={'relative'}
                        key={index}>
                        <Text
                          width={'fit-content'}
                        >
                          {item.title}
                        </Text>
                        <HStack gap={2} w="100%">
                          <Timeline date={String(item.year)} />
                          {!extUser &&
                            <Box
                              cursor={"pointer"}
                              onClick={() => {
                                setEditingAchievementObjIndex(index);
                                onOpenEditAchievements();
                                resetFormWithAchievementIdData(item)
                              }}
                            >
                              <PiPencilCircleDuotone size="18px" />
                            </Box>
                          }
                        </HStack>
                      </VStack>
                    );
                  })}
                </Flex> : null}
                <AchievementsEdit {...editAchievements} />

                {user?.education?.length ? <Flex
                  position={'relative'}
                  width={{ base: '100%', md: '300px' }}
                  direction={"column"}
                  gap="20px"
                  maxWidth={{ base: "100%" }}
                  border={'2px solid'}
                  borderColor={getTextColor(user?.theme[1]) ? "#fff" : "#000"}
                  p={3}
                >
                  <HStack>
                    <Text fontSize={"xl"} fontWeight={"500"}>Education</Text>
                    {!extUser && <Box
                      cursor={"pointer"}
                      onClick={() => {
                        onOpenEditEducation();
                        reset(InitialFormValue);
                      }}
                    >
                      <PiPlusCircleDuotone size="18px" />
                    </Box>}
                  </HStack>
                  {user?.education.map((item, index) => {
                    return (
                      <VStack
                        gap={3}
                        alignItems={"flex-start"}
                        position={'relative'}
                        key={index}>
                        <Text fontWeight={"500"}
                          fontSize={"sm"} >{item.name}</Text>
                        <Text className="font_cursive2" fontStyle={"italic"}
                          width={'fit-content'}
                          mt={"-3px"}
                        >
                          {item.course}
                        </Text>
                        <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>
                          {item.description}
                        </Markdown>
                        <HStack gap={2} w="100%" alignItems={"start"}>
                          <Timeline date={String(item.year)} />
                          {!extUser &&
                            <Box
                              cursor={"pointer"}
                              onClick={() => {
                                setEditingEducationObjIndex(index);
                                onOpenEditEducation();
                                resetFormWithEducationIdData(item)
                              }}
                            >
                              <PiPencilCircleDuotone size="18px" />
                            </Box>
                          }
                        </HStack>
                      </VStack>
                    );
                  })}

                </Flex> : null}
                <EducationEdit {...editEducation} />
              </Flex>
            </Flex>
            {/* end of Body Section */}

          </Flex >
        </>
      )}
    </Flex>
  )
}

export default SolidBackground;