import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import React from 'react'

const EditModal = ({ isOpen, onOpen, onClose, children, headerText, saveFn, IsSubmitting, submitText,
  maxWidth = "28rem" }) => {
  return (
    <Modal autoFocus={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent background="#fff" m={2} zIndex={9999} maxWidth={maxWidth}>
        <ModalHeader textAlign={"center"} fontSize={'sm'} fontWeight={'500'} borderBottom={"2px solid #E8E8E8"}>{headerText}</ModalHeader>
        <ModalBody p="0px">
          {children}
        </ModalBody>
        <ModalFooter borderTop={"2px solid #E8E8E8"}>
          <Button variant='ghost' size={'sm'} mr={3} onClick={onClose}>close</Button>
          <Button colorScheme='brand' size={'sm'} onClick={saveFn} isLoading={IsSubmitting}>
            {submitText ? submitText : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EditModal;