import { Box, Text, VStack, Flex, HStack } from '@chakra-ui/react';
import React from 'react';
import { PiPencilCircleDuotone, PiPlusCircleDuotone } from 'react-icons/pi';
import Timeline from '../Timeline';
import useEditAchievements from '../../../EditSections/editAchievements/useEditAchievements';
import AchievementsEdit from '../../../EditSections/editAchievements/AchievementEdit';


const Achievements = ({ extUser }) => {
  const editAchievements = useEditAchievements({ extUser });
  const {
    user,
    onOpen,
    resetFormWithAchievementIdData,
    setEditingAchievementObjIndex,
  } = editAchievements;
  return (
    <>
      {user?.achievements?.length ? <Flex
        position={'relative'}
        width={{ base: '100%', md: '300px' }}
        height={"fit-content"}
        alignItems={"flex-start"}
        direction="column"
        pl={{ base: 0, md: 3 }}
        p={"0px"}
        gap={3}
      >
        <HStack w={"100%"} justify={"space-between"}>
          <Text color={user?.theme[1]} className='font_cursive' fontSize={"4xl"} fontWeight={"500"}>Achievements.</Text>
          {!extUser && <Box cursor={'pointer'} onClick={onOpen}>
            <PiPlusCircleDuotone size="18px" />
          </Box>}
        </HStack>
        {user?.achievements.map((item, index) => {
          return (
            <VStack
              gap={3}
              alignItems={"flex-start"}
              width={"100%"}
              position={'relative'}
              pb={4}
              key={index}>
              <Text
                width={'fit-content'}
                color={user?.theme[1]}
              >
                {item.title}
              </Text>
              <HStack gap={2} w="100%">
                <Timeline date={String(item.year)} color={user?.theme[1]} />
                {!extUser &&
                  <Box
                    cursor={"pointer"}
                    onClick={() => {
                      setEditingAchievementObjIndex(index);
                      onOpen();
                      resetFormWithAchievementIdData(item)
                    }}
                  >
                    <PiPencilCircleDuotone size="18px" />
                  </Box>
                }
              </HStack>
            </VStack>
          );
        })}
      </Flex> : null}
      <AchievementsEdit {...editAchievements} />
    </>
  );
};

export default Achievements;
