import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";


const PublicRoute = ({ children }) => {

  const { user } = useSelector(state => state.user);
  const { isLoggedIn } = useSelector(state => state.status);

  if (isLoggedIn) {
    return <Navigate to={`/`} />
  }

  return (
    <>{children}</>
  )
}

export default PublicRoute;