import React from 'react';
import {
  FormControl,
  VStack,
  Input,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import EditModal from '../../../atoms/EditModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MdDragIndicator } from 'react-icons/md';
import ErrorBox from '../../../atoms/ErrorBox';

const CoreSkillsEdit = (editCoreSkills) => {

  const {
    isOpen,
    onOpen,
    isSubmitting,
    skills,
    onDragEnd,
    handleInputChange,
    onSubmit,
    handleClose,
    errors,
    handleSubmit,
  } = editCoreSkills;


  return (
    <EditModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      headerText={'Skills'}
      saveFn={handleSubmit(onSubmit)}
      IsSubmitting={isSubmitting}
    >
      <VStack className='modal_form' p={"20px"} spacing={4} overflow={'auto'} height={'auto'} maxHeight={'calc(100dvh - 135px)'}>
        {errors?.core_skills && <ErrorBox errorText={errors?.core_skills?.message} />}
        <FormControl>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="skills">
              {(provided) => (
                <VStack {...provided.droppableProps} ref={provided.innerRef} spacing={2}>
                  {skills?.map((skill, index) => (
                    <Draggable key={index} draggableId={`skill-${index}`} index={index}>
                      {(provided) => (
                        <HStack
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          spacing={3}
                          width="100%"
                        >
                          <IconButton
                            icon={<MdDragIndicator />}
                            variant="ghost"
                            {...provided.dragHandleProps} // Handle for dragging
                            aria-label="Drag skill"
                          />
                          <Input
                            background="#fff"
                            type="text"
                            fontSize={'sm'}
                            placeholder={`Cool stuff`}
                            value={skill}
                            onChange={handleInputChange(index)} // Now using memoized handler
                          />
                        </HStack>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </VStack>
              )}
            </Droppable>
          </DragDropContext>
        </FormControl>
      </VStack>
    </EditModal>
  );
};

export default CoreSkillsEdit;
