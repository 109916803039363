import { Box, Text } from '@chakra-ui/react';
import React from 'react'

const ErrorBox = ({ errorText, actionBtn, actionBtnText, }) => {
  return (
    <Box borderRadius={"5px"} p={'10px'} width={"100%"} backgroundColor={'rgba(206, 17, 38, 0.1)'}>
      <Text color="red">{errorText}&nbsp;
        {actionBtn &&
          <span style={{ textDecoration: 'underline', cursor:'pointer' }} onClick={actionBtn}>{actionBtnText}</span>
        }</Text>
    </Box>
  )
}

export default ErrorBox;