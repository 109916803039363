import { useDisclosure, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { insertOrReplaceObject } from '../../../helper/random.helper';
import { toastDefaultConfig } from '../../../utils/constants';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase.config';
import { login } from '../../../redux/slices/userSlice';
import { toggleEducation } from '../../../redux/slices/sectionEditSlice';


const useEditEducation = ({ extUser }) => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector(state => state.user);
  const [user, setUser] = useState();
  useEffect(() => {
    if (extUser) {
      setUser(extUser);
    } else {
      setUser(currentUser);
    }
  }, [extUser, currentUser])
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [EditingEducationObjIndex, setEditingEducationObjIndex] = useState(null);

  const InitialFormValue = {
    name: '',
    course: '',
    start_date: '',
    end_date: '',
    description: '',
  }
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: InitialFormValue,
  });

  const { education: educationState } = useSelector((state) => state.sectionEdit);

  useEffect(() => {
    if (educationState) {
      onOpen();
    } else {
      handleClose();
    }
  }, [educationState])

  const handleClose = () => {
    onClose();
    setEditingEducationObjIndex(null);
    if (educationState) {
      dispatch(toggleEducation());
    }
  }

  const resetFormWithEducationIdData = (educationItem) => {
    let startDate = educationItem.year.split('-')[0].trim();
    let endDate = educationItem.year.split('-')[1].trim();
    reset({
      name: educationItem.name,
      course: educationItem.course,
      start_date: startDate,
      end_date: endDate,
      description: educationItem.description,
    })
  }

  const onSubmit = (data) => {
    const payload = insertOrReplaceObject(user?.education, {
      name: data.name,
      year: `${data.start_date} - ${data.end_date}`,
      description: data.description,
      course: data.course
    }, EditingEducationObjIndex);
    dbWriteEducationFn(payload);
  }

  const actionFn = () => {
    const firstHalf = user?.education.slice(0, EditingEducationObjIndex);
    const secondHalf = user?.education.slice(EditingEducationObjIndex + 1);
    let payload = [...firstHalf, ...secondHalf]
    dbWriteEducationFn(payload);
  }

  const dbWriteEducationFn = async (payload) => {
    setIsSubmitting(true);

    try {
      if (!user) {
        console.error("User is not authenticated.");
        return;
      }

      const userDocRef = doc(db, "users", user?.uid);
      await updateDoc(userDocRef, { education: payload });
      dispatch(login({ ...user, education: payload }));
      handleClose();
    } catch (error) {
      toast({
        title: `Something went wrong! Please try again.`,
        status: 'error',
        isClosable: false,
        ...toastDefaultConfig,
      });
    } finally {
      setIsSubmitting(false);
      setEditingEducationObjIndex(null);
    }
  }

  return {
    user,
    register,
    errors,
    handleSubmit,
    reset,
    isSubmitting,
    onSubmit,
    actionFn,
    resetFormWithEducationIdData,
    handleClose,
    isOpen,
    onOpen,
    setEditingEducationObjIndex,
    EditingEducationObjIndex,
    InitialFormValue
  }
};

export default useEditEducation;
