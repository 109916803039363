import React, { memo } from 'react';
import {
  Box,
  Tag,
  TagLabel,
  Wrap,
  WrapItem,
  Text,
  HStack,
} from '@chakra-ui/react';
import { PiPencilCircleDuotone, PiPlusCircleDuotone } from 'react-icons/pi';
import SoftSkillsEdit from '../../../EditSections/editSoftSkills/SoftSkillsEdit';
import useEditSoftSkills from '../../../EditSections/editSoftSkills/useEditSoftSkills';

const Tools = ({ extUser }) => {
  const editSoftSkills = useEditSoftSkills({ extUser });

  const {
    user,
    onOpen,
  } = editSoftSkills;


  return (
    <>
      {user?.soft_skills?.length ? <Box
        w={"100%"}
        p={'0px'}
        pl={{ base: 0, md: 3 }}
        position={'relative'}
        height={'fit-content'}
      >
        <HStack justify={"space-between"}>
          <Text className='font_playfair' fontSize={"4xl"} fontWeight={'500'}>Soft Skills.</Text>
          {!extUser && <Box cursor={'pointer'} onClick={onOpen}>
            <PiPlusCircleDuotone size="18px" />
          </Box>}
        </HStack>
        <Wrap spacing={0} mt={'10px'}>
          {user?.soft_skills?.map((item, index) => (
            <WrapItem key={index}>
              <Tag
                size="md"
                borderRadius="0"
                p={0}
                fontWeight={400}
                backgroundColor="transparent"
                className='font_playfair'
              >

                <TagLabel fontSize="3xl" fontWeight={500}>{item}</TagLabel>
                {index !== user?.soft_skills.length - 1 && <Box width={"40px"} borderTop="2px solid" borderColor={"brand.black"} />}
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
      </Box> : null}
      <SoftSkillsEdit {...editSoftSkills} />
    </>
  );
};

export default memo(Tools); // Memoize the SkillTags component to prevent unnecessary re-renders
