import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  manage_section_toggle: false,
  bio: false,
  core_skills: false,
  upload_resume: false,
  soft_skills: false,
  work: false,
  projects: false,
  education: false,
  achievements: false,
}

export const sectionEditSlice = createSlice({
  name: 'sectionEdit',
  initialState,
  reducers: {
    toggleManageSection: (state) => {
      state.manage_section_toggle = !state.manage_section_toggle
    },
    toggleBio: (state) => {
      state.bio = !state.bio
      state.manage_section_toggle = state.bio
    },
    toggleSkills: (state) => {
      state.core_skills = !state.core_skills
      state.manage_section_toggle = state.core_skills
    },
    toggleResume: (state) => {
      state.upload_resume = !state.upload_resume
      state.manage_section_toggle = state.upload_resume
    },
    toggleTools: (state) => {
      state.manage_section_toggle = state.soft_skills
      state.soft_skills = !state.soft_skills
    },
    toggleWork: (state) => {
      state.manage_section_toggle = state.work
      state.work = !state.work
    },
    toggleProjects: (state) => {
      state.manage_section_toggle = state.projects
      state.projects = !state.projects
    },
    toggleEducation: (state) => {
      state.manage_section_toggle = state.education
      state.education = !state.education
    },
    toggleAchievements: (state) => {
      state.manage_section_toggle = state.achievements
      state.achievements = !state.achievements
    },
  },
})

// Action creators are generated for each case reducer function
export const { toggleManageSection, toggleBio, toggleAchievements, toggleEducation, toggleProjects, toggleWork, toggleTools, toggleResume, toggleSkills } = sectionEditSlice.actions

export default sectionEditSlice.reducer;