import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase.config";
import { formatErrorForFirebase } from "../helper/auth.helper";
import { capitalizeAndTrim } from "../helper/random.helper";

export const createUser = async ({ email, password }) => {
  return createUserWithEmailAndPassword(auth, capitalizeAndTrim(email), password)
    .then(async (userCredential) => {
      const user = userCredential.user;
      const emailPrefix = email.split("@")[0];
      const uniqueCode = Date.now().toString(36); // Create a unique code using the current timestamp
      const username = `${emailPrefix}-${uniqueCode}`;

      // Create a new document in Firestore with the user's email as the document name
      await setDoc(doc(db, "users", user.uid), {
        username: username,
        email: email,
        availability: 'Available for Work',
        uid: user.uid,
        theme: ["Basic", "#4fa985"]
      });

      return {
        isLogged: true,
        user: user,
        username: username, // return the generated username
        uid: user.uid,
        theme: ['Basic', '#4fa985']
      };
    })
    .catch((error) => {
      return {
        isLogged: false,
        message: formatErrorForFirebase(error.code),
      };
    });
};
