import React, { useEffect, useState } from 'react';
import { Box, Flex, HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import TechSkillsCard from './TechSkillsCard/TechSkillsCard';
import Experience from './Experience/Experience';
import Projects from './Projects/Projects';
import OtherTools from './OtherTools/OtherTools';
import AchievementsCard from './Achievements/Achievements';
import Education from './Education/Education';
import UserCard from './UserCard/UserCard';
import { useSelector } from 'react-redux';
import { getLinkDetails, openLink } from '../../../helper/random.helper';
import Blob from '../../Blob/Blob';
import ShareableDiv from '../../../atoms/SharableDiv';
import { LuShare2 } from 'react-icons/lu';
import { FaShareAlt } from 'react-icons/fa';


const Gallery = ({ UserData: extUser }) => {
  const { user: currentUser } = useSelector(state => state.user);
  const { isLoggedIn } = useSelector(state => state.status);
  const [user, setUser] = useState();
  useEffect(() => {
    if (extUser) {
      setUser(extUser);
    } else {
      setUser(currentUser);
    }
  }, [extUser, currentUser]);


  return (
    <VStack padding={isLoggedIn ? '100px 10px 50px 10px' : '20px 10px 20px 10px'}>
      <Blob color={user?.theme[1]} />
      <VStack maxWidth={{ base: "100%", md: "90%" }} spacing={"100px"}>
        <UserCard extUser={extUser} />
        <Experience extUser={extUser} />
        <Projects extUser={extUser} />
        <TechSkillsCard extUser={extUser} />
        <OtherTools extUser={extUser} />
        <AchievementsCard extUser={extUser} />
        <Education extUser={extUser} />
        <HStack wrap={"wrap"} width={"max-content"}>
          {user?.links.length > 0 && user?.links?.map((item, index) => {
            const { icon } = getLinkDetails(item, 20);
            return (
              <IconButton
                key={index}
                icon={icon}
                onClick={() => openLink(item)}
                aria-label={`link-${index}`}
                border="none"
                _hover={{ background: 'transparent' }}
                p={0}
                variant="outline"
              />
            );
          })}
        </HStack>
      </VStack>
    </VStack>
  )
}

export default Gallery;