import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoggedIn: false,
}

export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    toggleLogin: (state, action) => {
      state.isLoggedIn = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { toggleLogin } = statusSlice.actions

export default statusSlice.reducer;