import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase.config";
import { formatErrorForFirebase } from "../helper/auth.helper";

export const loginUser = async ({ email, password }) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      return {
        isLogged: true,
        user: userCredential.user
      }
    })
    .catch((error) => {
      console.log(error)
      return {
        isLogged: false,
        message: formatErrorForFirebase(error.code)
      }
    });
}